import React from 'react';
import { Handle, Position } from 'reactflow';

import { Props as HidableListProps } from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { Heading5, Variant } from '~/components/atom/Typography';

import CardTemplate, {
  handleStyle,
  Props as CardProps,
} from '../../../CardTemplate';
import {
  ClientFlowActionContactAddTag,
  ClientFlowActionContactDeleteTag,
} from '~/graphql/types.client';
import IssuesForAction from '../../../IssuesForAction';

export type Props = CardProps<
  ClientFlowActionContactAddTag | ClientFlowActionContactDeleteTag
> & {
  dataTestId?: string;
} & Omit<HidableListProps, 'dropdownListOpen' | 'onClickOutside'>;

const TagActionCard: React.FCC<Props> = ({
  selected,
  disabled,
  data,
  ...props
}) => (
  <>
    <Handle
      type="target"
      position={Position.Top}
      style={handleStyle}
      isConnectable={false}
    />
    <CardTemplate
      {...props}
      selected={selected}
      disabled={disabled}
      data={data}
    >
      {
        <>
          <Heading5 inline variant={Variant.primary}>
            Tag:&nbsp;
          </Heading5>
          {data.action.name}
          <IssuesForAction actionId={data.action.id} />
        </>
      }
    </CardTemplate>
    <Handle
      type="source"
      position={Position.Bottom}
      style={handleStyle}
      isConnectable={false}
    />
  </>
);

export default TagActionCard;
