import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Label, Variant } from '~/components/atom/Typography';
import ProgressiveImage from '~/components/atom/ProgressiveImage';
import Input from '~/components/molecule/Input';
import type {
  BrandSettings,
  BrandSettingsField,
} from '~/hooks/useBrandSettings';
import ColorPair, {
  Props as ColorPairProps,
} from '~/components/molecule/ColorPair';
import hasValue from '~/util/hasValue';

const text = {
  logoLabel: 'Logo',
  websiteUrlLabel: 'Adres van je website',
  colors: {
    primary: {
      color: 'Primaire tekstkleur',
      background: 'Primaire kleur',
    },
    secondary: {
      color: 'Secondaire tekstkleur',
      background: 'Secondaire kleur',
    },
  },
  linkLabel: 'Ga naar de huisstijl',
};

type Props = {
  subject: BrandSettingsField;
  brandSettings: BrandSettings;
};

const undefinedSubjectTextMap: {
  [key in BrandSettingsField]: string;
} = {
  logo: 'Het logo is niet ingesteld.',
  colors: 'De kleuren zijn niet ingesteld.',
  url: 'De website url is niet ingesteld.',
};

const UndefinedBrandSetting = ({ subject }) => (
  <>
    <Body margin={[null]}>
      {undefinedSubjectTextMap[subject]}{' '}
      <a href="/-/settings/brand" target="_blank" rel="noreferrer noopener">
        {text.linkLabel}
      </a>
    </Body>
  </>
);

const SubjectBasedComponent: React.FC<Props> = ({ subject, brandSettings }) => {
  switch (subject) {
    case 'logo': {
      return (
        <>
          {!hasValue(brandSettings.logo) ? (
            <UndefinedBrandSetting subject={subject} />
          ) : (
            <JustificationContainer direction="column">
              <Label size="base" variant={Variant.primary}>
                {text.logoLabel}
              </Label>
              <Image
                highQualitySrc={brandSettings.logo.url}
                lowQualitySrc={brandSettings.logo.url}
                width={200}
              />
            </JustificationContainer>
          )}
        </>
      );
    }
    case 'colors': {
      return (
        <>
          {!hasValue(brandSettings.colors) ? (
            <UndefinedBrandSetting subject={subject} />
          ) : (
            <JustificationContainer wrap="wrap" gap="s">
              {Object.keys(brandSettings.colors).map(groupKey => {
                const colorGroup = brandSettings.colors![groupKey];
                const pair = Object.keys(colorGroup).map(colorKey => ({
                  label: text.colors[groupKey][colorKey],
                  value: colorGroup[colorKey],
                })) as ColorPairProps['pair'];

                return <ColorPair key={groupKey} pair={pair} />;
              })}
            </JustificationContainer>
          )}
        </>
      );
    }
    case 'url': {
      return (
        <>
          {!hasValue(brandSettings.url) ? (
            <UndefinedBrandSetting subject={subject} />
          ) : (
            <Input
              label={{ text: text.websiteUrlLabel }}
              width="100%"
              value={brandSettings.url}
              readOnly
            />
          )}
        </>
      );
    }
    default: {
      return null;
    }
  }
};

const Image = styled(ProgressiveImage)(
  ({ theme }) => css`
    max-height: 140px;
    height: 100%;

    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default SubjectBasedComponent;
