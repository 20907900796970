import React, { useEffect, useMemo } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import qs from 'query-string';
import { Helmet as MetaTags } from 'react-helmet';
import {
  FlowAction,
  useImportFlowQuery,
  type ImportFlowQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderWithContext from '../BuilderWithContext';
import { useSetRecoilState } from 'recoil';
import { flowActions } from '../../state/actions';
import getFlowActionsToClientActions from '../../util/getFlowActionsToClientActions';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';
import interactions from '../../state/interactions';
import { EMPTY_TEMPLATE_ID } from '../FlowTemplates';
import { isNonEmptyString } from '~/util/Validation/String';
import createPageTitle from '~/util/createPageTitle';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Catalog from '~/Catalog';
import { isString } from 'lodash';
import { initialFlowState } from '../../state';
import type { ClientFlowAction } from '~/graphql/types.client';

export type Props = RouteComponentProps<{ token: string }>;

const CreateFlowBuilder: React.FCC<Props> = ({ token }) => {
  const account = useCurrentAccount();

  const { name, description } = useMemo(
    () => qs.parse(global.window?.location?.search || ''),
    [],
  );

  const initialDescription = isString(description) ? description : null;
  const initialName = isNonEmptyString(name) ? name : null;

  const { data, loading, error } = useImportFlowQuery({
    variables: {
      accountId: account.id,
      token: token as string,
    },
    fetchPolicy: 'network-only',
  });
  const setActions = useSetRecoilState(flowActions);
  const setActiveInteraction = useSetRecoilState(interactions);
  const setInitialFlow = useSetRecoilState(initialFlowState);

  useEffect(() => {
    if (!data?.copyFlow) return;

    const templateActions = data.copyFlow.Actions;
    const isEmptyTemplate =
      token === EMPTY_TEMPLATE_ID && templateActions.length === 1;

    const clientActions = isEmptyTemplate
      ? handleEmptyTemplate(templateActions)
      : getFlowActionsToClientActions(templateActions || []);

    setActions(clientActions);
    setInitialFlow(
      getInitialFlowState({
        actions: clientActions,
        initialName,
        initialDescription,
        data,
      }),
    );

    if (isEmptyTemplate) {
      setActiveInteraction({
        type: 'updateAction',
        action: clientActions[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActions, initialName, initialDescription, token, data]);

  if (error) {
    return (
      <JustificationContainer
        width="100%"
        padding={['xl']}
        justification="center"
        align="center"
      >
        <AppErrorScreen
          setBackgroundColor={false}
          message={Catalog.genericUnknownErrorMessage}
        />
      </JustificationContainer>
    );
  }

  if (loading || !data?.copyFlow)
    return (
      <>
        <MetaTags>
          <title>{createPageTitle('Laden...')}</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  return <BuilderWithContext flowId={data?.copyFlow.id} />;
};

const getInitialFlowState = ({
  actions,
  initialName,
  initialDescription,
  data,
}: {
  actions: Array<ClientFlowAction>;
  initialName: string | null;
  initialDescription: string | null;
  data: ImportFlowQuery;
}) => ({
  flowName: initialName ?? data.copyFlow.name,
  flowDescription: initialDescription ?? data.copyFlow.description,
  enabled: data.copyFlow.enabled,
  maximumFlowRun: data.copyFlow.maximumFlowRun,
  actions,
});

const handleEmptyTemplate = (
  templateActions: ImportFlowQuery['copyFlow']['Actions'],
) => {
  const startAction = templateActions.find(
    action => action.actionType === FlowAction.Start,
  );

  const actions = startAction
    ? [
        {
          ...startAction,
          conditionList: {
            __typename: 'FlowV2_ConditionList' as const,
            exp: '([0])',
            conditions: [],
          },
        },
      ]
    : [];

  return getFlowActionsToClientActions(actions);
};

export default withRouterTransitionContext(CreateFlowBuilder);
