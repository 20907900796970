import React from 'react';
import APIBlock from '~/components/organism/APIBlock';
import AddAPIBlock from '~/components/organism/AddAPIBlock';
import apiStatusToDescription from '~/components/page/Apps/Realworks/utils/apiStatusToDescription';
import apiStatusToVariant from '~/components/page/Apps/Realworks/utils/apiStatusToVariant';
import type { RealworksTokenWithStatus } from '~/components/page/Apps/Realworks/utils/getRealworksAPIConfigs';

const text = {
  addLabel: 'Toevoegen',
  cancelLabel: 'API toegang verwijderen',
};

type Props = {
  dataTestId?: string;
  heading: string;
  description: React.ReactNode;
  value?: RealworksTokenWithStatus | null;
  loading?: boolean;
  insertDisabled?: boolean;
  settings?: React.ReactNode;
  warnings?: Array<string | React.ReactNode> | null;
  onDelete: () => void;
  onAdd: () => void;
};

const APIBlockFacade: React.FC<Props> = ({
  heading,
  value,
  description,
  dataTestId,
  onAdd,
  onDelete,
  loading,
  insertDisabled,
  settings,
  warnings,
}) => {
  if (value) {
    return (
      <APIBlock
        heading={heading}
        cancelText={`${heading} ${text.cancelLabel}`}
        disabled={loading}
        description={apiStatusToDescription(value.status, value.error)}
        variant={warnings ? 'warning' : apiStatusToVariant(value.status)}
        token={value.token ?? undefined}
        onDelete={onDelete}
        settings={settings}
        warnings={warnings}
      />
    );
  }

  return (
    <AddAPIBlock
      heading={heading}
      label={text.addLabel}
      dataTestId={dataTestId}
      disabled={loading || insertDisabled}
      description={description}
      onAdd={onAdd}
    />
  );
};

export default APIBlockFacade;
