import { atom } from 'recoil';
import type {
  DeepLinkElement,
  DhImageElement,
  GenericHtmlElement,
  ImageElement,
  LinkElement,
  VariableElement,
} from '../../types';

export type ELEMENTS_WITH_TOOLBAR =
  | DeepLinkElement
  | ImageElement
  | LinkElement
  | GenericHtmlElement
  | DhImageElement
  | VariableElement;

export type ToolbarState = {
  element: ELEMENTS_WITH_TOOLBAR;
  elementRef: HTMLSpanElement;
};

export const hoveringToolbarState = atom<ToolbarState | null>({
  key: 'hoveringToolbar',
  default: null,
});
