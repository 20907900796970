import type {
  FormBuilder_EventNode,
  FormBuilder_NodeFragment,
} from '~/graphql/types';
import type { FormBuilder_Event_Field } from '~/graphql/types';
import getBlockKeyFromPointer from '../../getBlockKeyFromPointer';

/**
 * Returns existing event field for a block. Used in basic form builder.
 *
 * @param {string} blockKey - The key of the block
 * @param {Array<FormBuilder_NodeFragment>} nodes - The nodes of the form
 * @param {Array<FormBuilder_Event_Field>} eventFields - The event fields of the form
 * @returns {FormBuilder_Event_Field | null} The existing event field for the block
 * keywords: matchingeventfield
 */
const getEventFieldForBlock = ({
  blockKey,
  nodes,
  eventFields,
}: {
  blockKey: string;
  nodes: Array<FormBuilder_NodeFragment>;
  eventFields: Array<FormBuilder_Event_Field>;
}): FormBuilder_Event_Field | null => {
  const eventNode = nodes.find(
    node => node.__typename === 'FormBuilder_EventNode',
  ) as FormBuilder_EventNode | undefined;

  if (!eventNode) return null;

  /**
   * Returns: { eventFieldKey: blockKey }
   */
  const existingEventFieldsLookup = eventNode.mapping.reduce(
    (lookup, { key, pointer }) => {
      lookup[key] = getBlockKeyFromPointer(pointer as [string, string]);
      return lookup;
    },
    {} as { [eventFieldKey: string]: string },
  );
  const eventFieldForBlock = eventFields.find(
    ({ key }) => existingEventFieldsLookup?.[key] === blockKey,
  );

  return eventFieldForBlock || null;
};

export default getEventFieldForBlock;
