import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import { useTheme } from 'styled-components';
import React, { useEffect, useState } from 'react';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  AppStatusRealworksFieldsFragment,
  Exact,
  GetAppStatusRealworksQuery,
  RealworksRelatieSyncType,
  UpdateRealworksAppStatusMutationVariables,
  useGetAppStatusRealworks_RealworksKenmerkenQuery,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import { Heading4, Body, Variant } from '~/components/atom/Typography';
import RealworksSelectableAccordion, {
  Kenmerk,
  KenmerkLookup,
} from '../RealworksSelectableAccordion';
import { transformBackendKenmerken, transformFrontendKenmerken } from './util';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { equals, isEmpty, isNil } from 'ramda';
import NoResultSection from '~/components/page/NoResultSection';
import TextButton from '~/components/atom/TextButton';
import { animated, useSpring } from 'react-spring';
import { OperationVariables, WatchQueryOptions } from '@apollo/client';
import CaretDropdownButton from '~/components/molecule/CaretDropdownButton';
import { isValid } from '~/util/Validation/Tag';
import RadioButton from '~/components/molecule/RadioButton';
import { ANIMATION_CONFIG } from '~/styles/constants';

const text = {
  header: 'Synchronisatie',
  emptyState: {
    header: (
      <>
        Je hebt nog geen actieve koppelingen
        <br /> met Realworks.
      </>
    ),
    button: 'Toevoegen',
  },
  noResults:
    'Geen Realworks kenmerken gevonden voor de actieve relatie token. Voeg een andere token toe of probeer het later opnieuw.',
  description:
    'Kies welke contacten je wilt synchroniseren met DatHuis. Je kunt ook kenmerken uit Realworks automatisch omzetten naar een tag in DatHuis.',
  syncOptions: {
    title: 'Welke relaties wil je synchroniseren?',
    full: 'Alle relaties',
    limited: 'Alleen relaties met geselecteerde kenmerken',
  },
  buttons: {
    deactivate: 'Deactiveer synchronisatie',
    save: 'Opslaan',
    saveAndActivate: 'Opslaan en activeren',
    saveAndDeactivate: 'Opslaan en deactiveren',
  },
  toasts: {
    error: {
      deactivate:
        'Er is iets fout gegaan bij het deactiveren van de synchronisatie, probeer het later opnieuw',
      sync: 'Er is iets fout gegaan bij het synchroniseren, probeer het later opnieuw',
      saveDeactivate: (isRelatieSyncEnabled: boolean) =>
        `Er is iets fout gegaan bij het ${isRelatieSyncEnabled ? 'opslaan en deactiveren van de synchronisatie' : 'opslaan van de wijzigingen'}, probeer het later opnieuw`,
    },
    success: {
      deactivate: 'Synchronisatie gedeactiveerd',
      activate: 'Synchronisatie geactiveerd',
      save: 'Wijzigingen opgeslagen',
    },
  },
};

type Props = {
  appStatus: AppStatusRealworksFieldsFragment;
  updateQuery: <
    TVars extends OperationVariables = Exact<{ accountId: string }>,
  >(
    mapFn: (
      previousQueryResult: GetAppStatusRealworksQuery,
      options: Pick<
        WatchQueryOptions<TVars, GetAppStatusRealworksQuery>,
        'variables'
      >,
    ) => GetAppStatusRealworksQuery,
  ) => void;
} & RouteComponentProps;

const Synchronization: React.FCC<Props> = ({ appStatus, updateQuery }) => {
  const theme = useTheme();
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();

  const [hasValidationError, setHasValidationError] = useState(false);

  const [syncType, setSyncType] = useState<RealworksRelatieSyncType>(
    appStatus.relatieSync.type,
  );

  const isRelatieSyncEnabled = appStatus.relatieSync.enabled;

  const styles = useSpring({
    from: {
      transform: isRelatieSyncEnabled ? 'translateX(-40px)' : 'translateX(0px)',
      opacity: isRelatieSyncEnabled ? 0 : 1,
    },
    to: {
      transform: isRelatieSyncEnabled ? 'translateX(0px)' : 'translateX(-40px)',
      opacity: isRelatieSyncEnabled ? 1 : 0,
    },
    config: ANIMATION_CONFIG.config,
  });

  const [initialKenmerkLookup, setInitialKenmerkLookup] =
    useState<KenmerkLookup | null>(null);
  const [updatedKenmerkLookup, setUpdatedKenmerkLookup] =
    useState<KenmerkLookup | null>(null);

  const { data, loading } = useGetAppStatusRealworks_RealworksKenmerkenQuery({
    variables: { accountId },
  });

  const realworksKenmerken = data?.getAppStatusRealworks_RealworksKenmerken;
  const kenmerkMapping = appStatus?.relatieSync?.kenmerkMapping;

  useEffect(() => {
    // We set the lookup tables in a useEffect because it has to rerun after every change to kenmerkMapping.
    // This way it estimates the differenceCount correctly.
    if (realworksKenmerken) {
      const initialLookup = transformBackendKenmerken(
        {
          kenmerkMapping,
          realworksKenmerken,
        },
        { shouldUseDefaultTags: false },
      );
      const updatedLookup = transformBackendKenmerken(
        {
          kenmerkMapping,
          realworksKenmerken,
        },
        { shouldUseDefaultTags: true },
      );

      setUpdatedKenmerkLookup(updatedLookup);
      setInitialKenmerkLookup(initialLookup);
    }
  }, [realworksKenmerken, kenmerkMapping]);

  const [updateRealworksAppStatus, { loading: updating }] =
    useUpdateRealworksAppStatusMutation();

  const original = {
    ...initialKenmerkLookup,
    syncType: appStatus.relatieSync.type,
  };
  const updated = { ...updatedKenmerkLookup, syncType };

  const hasChanges = !equals(original, updated);

  const onUpdate = (
    variables: UpdateRealworksAppStatusMutationVariables,
    { errorToast, successToast }: { errorToast: string; successToast: string },
  ) => {
    if (updatedKenmerkLookup) {
      return updateRealworksAppStatus({
        variables,
      }).then(({ errors, data }) => {
        if (errors && errors.length !== 0) {
          addToast([formatToastMessage(errorToast, 'danger')]);
          return;
        }

        if (!data) return;

        updateQuery(prevRes => ({
          ...prevRes,
          getAppStatusRealworks: data.updateRealworksAppStatus,
        }));

        addToast([formatToastMessage(successToast, 'success')]);
      });
    }

    return;
  };

  const onChange = ({
    groupName,
    kenmerkList,
  }: {
    groupName: string;
    kenmerkList: Array<Kenmerk>;
  }) => {
    setHasValidationError(
      kenmerkList.some(
        // Only validate when tag is not null and empty
        ({ tag }) => !isNil(tag) && !isEmpty(tag) && !isValid(tag),
      ),
    );
    setUpdatedKenmerkLookup(prevState => ({
      ...prevState,
      [groupName]: kenmerkList,
    }));
  };

  if (loading) return null;
  if (!data || appStatus?.enabled === false)
    return (
      <AppDetailsContainer
        header={text.header}
        icon="reload"
        loading={updating}
        dataTestId={TEST_ID.CONTAINER}
      >
        <EmptyStateComponent
          header={text.emptyState.header}
          illustration="box"
          buttonLabel={text.emptyState.button}
          onButtonClick={() =>
            navigate('/-/apps/realworks/wizard/', { replace: true })
          }
          blobColor={theme.color('tertiary', 'light')}
        />
      </AppDetailsContainer>
    );

  if (
    !realworksKenmerken ||
    isNil(updatedKenmerkLookup) ||
    isEmpty(updatedKenmerkLookup)
  ) {
    return (
      <AppDetailsContainer
        header={text.header}
        loading={false}
        dataTestId={TEST_ID.CONTAINER}
      >
        <NoResultSection>{text.noResults}</NoResultSection>
      </AppDetailsContainer>
    );
  }

  return (
    <AppDetailsContainer
      header={text.header}
      loading={false}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body margin={['xl', null]}>
        {text.description}
        <br />
      </Body>
      <Heading4 variant={Variant.primary}>{text.syncOptions.title}</Heading4>
      <RadioButton
        checked={syncType === RealworksRelatieSyncType.Full}
        onChange={() => setSyncType(RealworksRelatieSyncType.Full)}
        label={text.syncOptions.full}
        dataTestId={TEST_ID.FULL_OPTION}
        margin={['m', null, null, null]}
      />
      <RadioButton
        checked={syncType === RealworksRelatieSyncType.LimitToKenmerken}
        onChange={() => setSyncType(RealworksRelatieSyncType.LimitToKenmerken)}
        label={text.syncOptions.limited}
        dataTestId={TEST_ID.LIMITED_TO_KENMERKEN_OPTION}
        margin={['m', null, null, null]}
      />
      <RealworksSelectableAccordion
        kenmerkLookup={updatedKenmerkLookup}
        onChange={onChange}
        selectable={syncType === RealworksRelatieSyncType.LimitToKenmerken}
      />

      <JustificationContainer
        justification={styles.opacity ? 'space-between' : 'end'}
        margin={['l', null, null]}
      >
        <TextButton
          label={text.buttons.deactivate}
          appearance="danger"
          disabled={updating}
          onClick={() =>
            onUpdate(
              {
                accountId,
                updateRelatieSync: {
                  enabled: false,
                  type: appStatus.relatieSync.type,
                  kenmerkMapping:
                    transformFrontendKenmerken(initialKenmerkLookup),
                },
              },
              {
                errorToast: text.toasts.error.deactivate,
                successToast: text.toasts.success.deactivate,
              },
            )
          }
          dataTestId={TEST_ID.DEACTIVATE_BUTTON}
          as={animated.button}
          style={{ alignSelf: 'flex-end', ...(styles as any) }}
        />

        <CaretDropdownButton
          mainButtonOption={{
            label: isRelatieSyncEnabled
              ? text.buttons.save
              : text.buttons.saveAndActivate,
            onClickAction: () =>
              onUpdate(
                {
                  accountId,
                  updateRelatieSync: {
                    enabled: true,
                    type: syncType,
                    kenmerkMapping:
                      transformFrontendKenmerken(updatedKenmerkLookup),
                  },
                },
                {
                  errorToast: text.toasts.error.sync,
                  successToast: !isRelatieSyncEnabled
                    ? text.toasts.success.activate
                    : text.toasts.success.save,
                },
              ),
          }}
          dropdownOptions={[
            {
              label: isRelatieSyncEnabled
                ? text.buttons.saveAndDeactivate
                : text.buttons.save,
              onClickAction: () =>
                onUpdate(
                  {
                    accountId,
                    updateRelatieSync: {
                      enabled: false,
                      type: syncType,
                      kenmerkMapping:
                        transformFrontendKenmerken(updatedKenmerkLookup),
                    },
                  },
                  {
                    errorToast:
                      text.toasts.error.saveDeactivate(isRelatieSyncEnabled),
                    successToast: isRelatieSyncEnabled
                      ? text.toasts.success.deactivate
                      : text.toasts.success.save,
                  },
                ),
              type: !hasChanges ? 'DISABLED' : undefined,
            },
          ]}
          dataTestId={TEST_ID.SAVE_BUTTON}
          loading={updating}
          disabled={(!hasChanges && isRelatieSyncEnabled) || hasValidationError}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default Synchronization;
