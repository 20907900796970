import { ApolloQueryResult } from '@apollo/client';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { navigate } from '@gatsbyjs/reach-router';
import React, { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import DHRouter from '~/components/atom/DHRouter';
import Button from '~/components/atom/Button';
import Divider from '~/components/atom/Divider';
import { Body } from '~/components/atom/Typography';
import {
  AppStatusRealworksFieldsFragment,
  Exact,
  GetAppStatusRealworksQuery,
} from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AddTokenContainerWizard from './components/AddTokenContainerWizard';
import TokenContainer from './components/TokenContainer';

import TEST_ID from './index.testid';
import RealworksHelpBlock from './components/AddTokenContainerWizard/components/RealworksHelpBlock';
import useOffices from '~/hooks/useOffices';
import { DEFAULT_TOKEN_NAME } from '../../constants';

const text = {
  addTokenContainerLabel: 'Nog een koppeling toevoegen',
  header: 'Koppelingen overzicht',
  description:
    'Gebruik de Relatie API om relaties uit te wisselen met Realworks. Met de Agenda API ontvang je afspraken in DatHuis. Stel marketing automation in op basis van deze informatie.',
};

type Props = {
  appStatus: AppStatusRealworksFieldsFragment;
  refreshAppStatus: (
    variables?:
      | Partial<
          Exact<{
            accountId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetAppStatusRealworksQuery>>;
} & RouteComponentProps;

const Connections: React.FCC<Props> = ({ appStatus, refreshAppStatus }) => {
  useEffect(() => {
    if (appStatus.tokens.length === 0) {
      void navigate(`/-/apps/realworks/wizard/`, { replace: true });
    }
    // We only want to check this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenTransitions = useTransition(appStatus.tokens, {
    from: {
      opacity: 0,
      transform: 'translate(0, 40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translate(0, 0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translate(0, 40px)',
      height: 0,
    },
    trail: 125,
    keys: tokenContainer => tokenContainer.id,
  });

  const [usedOffices, setUsedOffices] = useState<{
    [tokenId: string]: string;
  }>({});

  useEffect(() => {
    setUsedOffices(generateUsedOfficesMap(appStatus.tokens));
  }, [appStatus.tokens]);

  const onRefreshAppStatus = () => {
    void refreshAppStatus().then(({ data }) => {
      setUsedOffices(generateUsedOfficesMap(data.getAppStatusRealworks.tokens));
    });
  };

  const offices = useOffices({});
  const allOfficesUsed =
    Object.values(usedOffices).every(officeId => officeId !== null) &&
    Object.keys(usedOffices).length === offices.length;

  const tokenContainersWithNoActiveTokens = appStatus.tokens.filter(
    token => token.name === DEFAULT_TOKEN_NAME,
  );

  return (
    <AppDetailsContainer
      header={text.header}
      icon="link"
      loading={false}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>{text.description}</Body>

      <RealworksHelpBlock tipId="realworks-connections-tip" />

      {tokenTransitions((style, tokenContainer, _) => (
        <animated.div style={style}>
          <Divider margin={['xxxl', null, 'xl']} />
          <TokenContainer
            key={tokenContainer.id}
            {...tokenContainer}
            refreshAppStatus={onRefreshAppStatus}
            onOfficeChange={officeId => {
              setUsedOffices(prev => ({
                ...prev,
                [tokenContainer.id]: officeId,
              }));
            }}
            usedOffices={usedOffices}
            name={
              tokenContainer.name === DEFAULT_TOKEN_NAME
                ? `Nieuwe koppeling ${
                    tokenContainersWithNoActiveTokens.findIndex(
                      t => t.id === tokenContainer.id,
                    ) + 1
                  }`
                : tokenContainer.name
            }
          />
        </animated.div>
      ))}

      <Divider />
      <Button
        ghost
        appearance="secondary"
        label={text.addTokenContainerLabel}
        icon="plus"
        size="medium"
        style={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => navigate(`/-/apps/realworks/wizard/`)}
        dataTestId={TEST_ID.ADD_TOKEN_CONTAINER}
        disabled={allOfficesUsed}
      />
      <DHRouter>
        <AddTokenContainerWizard
          path="/wizard/*"
          onClose={() => {
            void refreshAppStatus().then(() => {
              void navigate('/-/apps/realworks');
            });
          }}
          refreshAppStatus={onRefreshAppStatus}
          usedOffices={usedOffices}
        />
      </DHRouter>
    </AppDetailsContainer>
  );
};

const generateUsedOfficesMap = (
  tokens: AppStatusRealworksFieldsFragment['tokens'],
) =>
  tokens.reduce(
    (acc, token) => {
      if (token.officeId) {
        acc[token.id] = token.officeId;
      }
      return acc;
    },
    {} as Record<string, string>,
  );

export default Connections;
