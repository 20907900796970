import React, { useEffect, useState } from 'react';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import useWizardStep from '~/hooks/useWizardStep';
import ImportContactsStepBody from '~/components/page/Apps/Realworks/components/Connections/components/AddTokenContainerWizard/components/ImportContactsStep/components/ImportContactsStepBody';
import validateRealworksToken from '~/components/page/Apps/Realworks/utils/validateRealworksToken';

export const importContactsScreenshotLink =
  'https://dathuis-stash.imgix.net/realworks_api_manager.png';

export const relatieHeader = 'Relaties';

export const id = 'RealworksImportContacts';
export const title = relatieHeader;

export type OutputType = {
  type: typeof id;
  relatieToken?: OutputFieldInvisible<
    UpdateRealworksAppStatus_AddTokenContainer__Input['relatie']
  >;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const [, api] = useWizardStep(step.id);

  const previousToken =
    outputMap[id] && 'relatieToken' in outputMap[id]
      ? outputMap[id]?.relatieToken?.value ?? null
      : null;

  const [relatie, setRelatie] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['relatie']>(
      previousToken,
    );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const agendaToken =
    'agendaToken' in outputMap.RealworksAgenda
      ? outputMap.RealworksAgenda?.agendaToken?.value ?? null
      : null;

  useEffect(() => {
    if (
      relatie &&
      validateRealworksToken({
        mainToken: relatie,
        tokensToCompare: [agendaToken],
      }) !== true
    ) {
      api.lock({
        type: id,
      });
    } else {
      api.free({
        type: id,
        relatieToken: {
          type: 'invisible',
          value: relatie,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatie]);

  return (
    <ImportContactsStepBody
      tokens={[agendaToken]}
      value={relatie}
      onChange={value => setRelatie(value)}
      onSubmit={onSubmit}
      skipStepBlock={{
        usage: 'wizard',
      }}
    />
  );
};

export default {
  id,
  title,
};
