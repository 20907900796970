import type { DeepLinkElement } from '~/components/organism/PluginsEditor/types';
import updateAttrsAndConvertToString from '~/components/organism/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

/**
 * serializes the DeepLink element into html element
 * @param {DeepLinkElement} node - DeepLink element
 * @param {string} children - Children
 */
const serialize = (node: DeepLinkElement): string => {
  if (!node || !node.mappingId) return '';

  const attrsAsString = updateAttrsAndConvertToString({
    style: {
      ...(node.children[0].bold ? { 'font-weight': 'bold' } : {}),
      ...(node.children[0].italic ? { 'font-style': 'italic' } : {}),
    },
  });
  // 'data-deeplink' attribute will help to differentiate between old and new version of deep link elements
  return `<span${attrsAsString ? ` ${attrsAsString}` : ''} data-deeplink="true">_%${node.mappingId}%_</span>`;
};

export default serialize;
