import { createPortal } from 'react-dom';
import useCleanUpTooltipLayerState from '~/hooks/useCleanUpTooltipLayerState';
import usePortal from '~/hooks/usePortal';

export type RootId =
  | 'wizard'
  | 'app-setup'
  | 'global-wizard-portal'
  | 'fullscreen-modal'
  | 'confirm-start-flow-modal'
  | 'import-contacts'
  | 'confirm-modal'
  | 'task-modal'
  | 'insert-html'
  | 'confirm-action-modal'
  | 'hovering-toolbar'
  | 'dropdown-portal'
  | 'filter-selector'
  | 'image-editor-modal'
  | 'condition-editor';

type Props = {
  root?: RootId;
  key?: string;
  children?: React.ReactNode;
};

const Portal: React.FCC<Props> = ({ children, root = 'modal-portal' }) => {
  const target = usePortal(root);

  // Clean up tooltip state on scroll
  useCleanUpTooltipLayerState();

  // We provide root as a key for createPortal
  return createPortal(children, target, root);
};

export default Portal;
