/**
 * Finds the closest active realworks token container (closest to the contact)
 * keywords: realworks
 */
import {
  AppStatus_Realworks_RealworksTokenContainer,
  RealworksTokenStatus,
} from '~/graphql/types';

export type Entity = {
  accountId: string;
  officeId?: string | null;
  userId?: string | null;
};

const findClosestActiveRealworksTokenContainer = (
  tokens: Array<AppStatus_Realworks_RealworksTokenContainer>,
  entity: Entity,
): AppStatus_Realworks_RealworksTokenContainer | undefined => {
  const activeTokens = tokens.filter(
    token => token.relatie?.status === RealworksTokenStatus.Active,
  );
  // No active token found
  if (activeTokens.length === 0) return;

  if (entity.userId) {
    const token = activeTokens.find(
      token =>
        token.userId === entity.userId && token.officeId === entity.officeId,
    );

    if (token != null) {
      return token;
    }
  }
  if (entity.officeId) {
    const token = activeTokens.find(
      token => token.officeId === entity.officeId && token.userId == null,
    );
    if (token != null) {
      return token;
    }
  }

  // Default to account level active token (if exists), if not return the first active token
  return (
    activeTokens.find(
      activeToken => activeToken.officeId == null && activeToken.userId == null,
    ) ?? activeTokens[0]
  );
};

export default findClosestActiveRealworksTokenContainer;
