import React from 'react';
import { convertToFullTimeString } from '~/util/time';
import JustificationContainer from '~/components/atom/JustificationContainer';
import InputLabel from '~/components/atom/InputLabel';
import DaysOfWeekContainer from '../DaysOfWeekContainer';
import TimePicker from '~/components/organism/TimePicker';
import { Body } from '~/components/atom/Typography';
import { ClientFlowActionTrickle } from '~/graphql/types.client';
import { asValidHourOrNull, asValidMinuteOrNull } from '~/util/time';

export type Props = {
  dataTestId?: string;

  /** The rule to update */
  rule: ClientFlowActionTrickle['rules'][number];

  /** The function to update the rule in the action */
  onUpdateRule: (rule: ClientFlowActionTrickle['rules'][number]) => void;
};

const text = {
  description:
    'Stel in op welke dagen van de week en welke tijdsperiode contacten verwerkt mogen worden.',
  from: 'Van',
  to: 'Tot',
  activeDays: 'Actieve dagen',
  endTimeIsBeforeStartTime: 'Eindtijd moet na de starttijd zijn',
  daysOfWeekMissing: 'Minstens één dag van de week moet geselecteerd zijn',
};

const RulesContainer: React.FCC<Props> = ({ rule, onUpdateRule }) => {
  /** Currently we only support one time range */
  const timeRange = rule?.timeRanges[0];

  const startTime = timeRange?.start
    ? `${convertToFullTimeString(timeRange?.start.hour, timeRange?.start.minute)}`
    : '09:00';
  const endTime = timeRange?.end
    ? `${convertToFullTimeString(timeRange?.end.hour, timeRange?.end.minute)}`
    : '17:30';

  const onUpdateTimeRange = (
    updatedFields: Partial<
      ClientFlowActionTrickle['rules'][number]['timeRanges'][number]
    >,
  ) => {
    onUpdateRule({
      ...rule,
      timeRanges: [
        {
          ...timeRange,
          ...updatedFields,
        },
      ],
    });
  };

  const onTimeChange = (time: string | null, isStart: boolean) => {
    if (time == null) return;
    const hour = asValidHourOrNull(time.split(':')[0]);
    const minute = asValidMinuteOrNull(time.split(':')[1]);

    if (hour === null || minute === null) return;

    onUpdateTimeRange({
      [isStart ? 'start' : 'end']: {
        __typename: 'FlowV2_Action_Trickle_TimeRange_Time',
        hour,
        minute,
      },
    });
  };

  const externalErrors =
    endTime <= startTime ? [text.endTimeIsBeforeStartTime] : undefined;

  return (
    <>
      <Body margin={[null, null, 'm']}>{text.description}</Body>

      <JustificationContainer
        justification="space-between"
        gap="base"
        wrap="wrap"
        align="end"
      >
        <JustificationContainer direction="column" gap="xxs">
          <InputLabel
            label={text.activeDays}
            required
            error={
              rule.daysOfWeek.length === 0 ? text.daysOfWeekMissing : undefined
            }
          />
          <DaysOfWeekContainer rule={rule} onUpdateRule={onUpdateRule} />
        </JustificationContainer>

        <JustificationContainer gap="s" align="end">
          <TimePicker
            onChange={time => onTimeChange(time, true)}
            label={{ text: text.from }}
            value={startTime}
            width="100px"
          />
          <TimePicker
            onChange={time => onTimeChange(time, false)}
            label={{ text: text.to }}
            value={endTime}
            externalErrors={externalErrors}
            width="100px"
          />
        </JustificationContainer>
      </JustificationContainer>
    </>
  );
};

export default RulesContainer;
