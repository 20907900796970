import type { RefObject } from 'react';
import { useEffect, useCallback } from 'react';
import type { RootId } from '~/components/molecule/Portal';
import isCursorInBoundary from '~/util/isCursorInBoundary';

/**
 * Checks whether a click registered outside the element
 *
 * keywords: click,outside,element
 */
const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (e) => any,
  /**
   * If provided, the click will be allowed to pass through the portal
   */
  allowClickIn?: RootId,
) => {
  const onClick = useCallback(
    (e: MouseEvent) => {
      const portalRoot = document.getElementById(`${allowClickIn}-root`);

      if (
        allowClickIn &&
        portalRoot &&
        portalRoot.contains(e.target as Node) &&
        e.target instanceof Node
      ) {
        // Create a new synthetic click event to re-dispatch
        // This allows the click to "pass through" the portal
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        // Dispatch the new click event on the target element
        // This allows components inside portals to receive click events
        // even when useOutsideClick would normally intercept them
        e.target.dispatchEvent(clickEvent);
        return;
      }

      // If click was not inside the allowed portal, execute the callback
      callback(e);
    },
    [callback, allowClickIn],
  );

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        if (
          isCursorInBoundary({
            boundary: ref.current.getBoundingClientRect(),
            event: e,
          })
        )
          return;

        onClick(e);
      }
    },
    [onClick, ref],
  );

  useEffect(() => {
    // Passing true catches event in capture phase so that useOutsideClick works when clicking inside the modals as well.
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick]);

  return handleClick;
};

export default useOutsideClick;
