import {
  GetAppStatusRealworksQuery,
  RealworksTokenStatus,
} from '~/graphql/types';

const getActiveTokenOptions = (
  realworksStatus: NonNullable<
    GetAppStatusRealworksQuery['getAppStatusRealworks']
  >,
) => {
  if (!realworksStatus?.enabled) return [];
  const activeTokens = realworksStatus.tokens.filter(
    token => token.relatie?.status === RealworksTokenStatus.Active,
  );

  return [
    ...activeTokens.map(activeToken => ({
      key: activeToken.id,
      label: activeToken.name,
      payload: activeToken.id,
    })),
  ];
};

export default getActiveTokenOptions;
