import React, { useCallback } from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { actionsSelector } from '~/components/page/Automation/v2/state/actions';
import AddBlockButton from '../AddBlockButton';
import AnimatedPath from '../AnimatedPath';
import DroppableArea from '../DroppableArea';
import { getActionTemplate } from '../../../../constants/actionTemplates';
import useErrorReporter from '~/hooks/useErrorReporter';
import parseEventData from '../../utils/parseEventData';
import useBuilderContext from '../../../../hooks/useBuilderContext';

import onUpdateActions from '../../utils/onUpdateActions';
import { ClientFlowAction } from '~/graphql/types.client';
import { EdgeData } from '../DropEdge';
import { shouldDisable } from '~/components/page/Automation/v2/state/interactions';
import { EDGE_BORDER_RADIUS } from '../BaseEdge';
import { MARKER_END_DISABLED } from '../../../MarkerDefinitions';
import { HandlerId } from '../../../nodeTypes/components/IfElseCard/types';
import InsertCopiedActionContainer from '../InsertCopiedActionContainer';
import usePasteAction from '~/components/page/Automation/v2/components/Builder/hooks/usePasteAction';

export type Props = EdgeProps<EdgeData> & {};

const IfElseEdge: React.FCC<Props> = React.memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
    source,
    sourceHandleId,
    target,
  }) => {
    const edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: EDGE_BORDER_RADIUS,
    });
    const reporter = useErrorReporter();
    const isTruePath = sourceHandleId === HandlerId.trueChild;
    const { accountId, flowBlueprintId } = useBuilderContext();
    const setActions = useSetRecoilState(
      actionsSelector({
        accountId,
        flowBlueprintId,
      }),
    );

    const onDrop = useCallback(
      (event: React.DragEvent<any>) => {
        // We could split the `onActionDrop` into 2 methods 1 for new actions and the other one for existing actions
        // by using a different dataTransfer ID.
        const dropData = parseEventData(
          event.dataTransfer.getData('application/reactflow'),
          reporter,
        );

        if (dropData !== null && data != null) {
          setActions(actions =>
            onUpdateActions(actions, {
              source,
              target,
              subject: dropData,
              isDropTruePath: isTruePath,
            }),
          );
        }
      },
      [data, isTruePath, reporter, setActions, source, target],
    );

    const onAddAction = useCallback(
      (actionType: ClientFlowAction['actionType']) => {
        const actionTemplate = getActionTemplate({
          flowBlueprintId,
          accountId,
          actionType,
        });

        if (actionTemplate !== null && data != null) {
          setActions(actions =>
            onUpdateActions(actions, {
              source,
              target,
              subject: actionTemplate,
              isDropTruePath: isTruePath,
            }),
          );
        }
      },
      [
        accountId,
        data,
        flowBlueprintId,
        isTruePath,
        target,
        source,
        setActions,
      ],
    );

    const { onInsertAction } = usePasteAction({ source, target });

    const disabled = useRecoilValue(shouldDisable);

    return (
      <>
        <AnimatedPath
          id={id}
          style={{
            ...style,
            strokeWidth: 2,
            pointerEvents: 'none',
            opacity: disabled ? 0.5 : 1,
            transition: 'opacity 0.5s ease-out',
          }}
          className="react-flow__edge-path"
          d={edgePath[0]}
          markerEnd={disabled ? MARKER_END_DISABLED : markerEnd}
        />
        <DroppableArea
          sourceX={sourceX}
          sourceY={sourceY}
          id={id}
          onDrop={onDrop}
        />

        <InsertCopiedActionContainer
          onInsertAction={onInsertAction}
          sourceX={sourceX}
          sourceY={sourceY}
          targetY={targetY}
          dataTestId={`insertActionContainer-${source}`}
        />

        {!disabled && (
          <AddBlockButton
            id={id}
            targetX={targetX}
            targetY={targetY}
            onAddAction={onAddAction}
            parentActionType={data?.parentNode?.actionType}
          />
        )}
      </>
    );
  },
);

export default IfElseEdge;
