import React, { useEffect, useState } from 'react';
import AssignBlock from '~/components/organism/AssignBlock';
import { Body } from '~/components/atom/Typography';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import useWizardStep from '~/hooks/useWizardStep';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import RealworksHelpBlock from '../components/RealworksHelpBlock';
import { DEFAULT_TOKEN_NAME } from '~/components/page/Apps/Realworks/constants';

const text = {
  header: 'Instellingen',
  description:
    'Maak een koppeling aan en koppel de vestiging in Realworks aan een vestiging en gebruiker in DatHuis.',
  entryHeader: 'Wijs contacten toe aan een vestiging in DatHuis',
  bodyText:
    'Wijs relaties die vanuit Realworks worden geimporteerd toe aan een vestiging en gebruiker in DatHuis.',
  nextButton: 'Volgende',
};

export const id = 'RealworksSetup';
export const title = text.header;

export type OutputType = {
  type: typeof id;
  addTokenContainerInput: OutputFieldInvisible<UpdateRealworksAppStatus_AddTokenContainer__Input>;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const [, api] = useWizardStep(step.id);

  const previousValues =
    outputMap[id] && 'addTokenContainerInput' in outputMap[id]
      ? outputMap[id].addTokenContainerInput.value
      : {
          name: DEFAULT_TOKEN_NAME,
          officeId: null,
          userId: null,
        };

  const [values, setValues] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input>(previousValues);

  useEffect(() => {
    const output: OutputType = {
      type: 'RealworksSetup',
      addTokenContainerInput: {
        type: 'invisible',
        value: values,
      },
    };

    if (values?.officeId) {
      api.free(output);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Body>{text.description}</Body>

      <RealworksHelpBlock tipId="setup-step-id" />

      <AppEntryItemHeader>{text.entryHeader}</AppEntryItemHeader>
      <Body>{text.bodyText}</Body>
      <AssignBlock
        userId={values.userId}
        officeId={values.officeId}
        onChange={({ userId, officeId }) =>
          setValues(prev => ({ ...prev, userId, officeId }))
        }
      />
    </>
  );
};

export default {
  id,
  title,
};
