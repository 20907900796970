import React, { useState } from 'react';

import { ReactEditor } from 'slate-react';
import {
  DHEditor,
  type DeepLinkElement,
} from '~/components/organism/PluginsEditor/types';
import { Path, Transforms } from 'slate';
import { deleteNodeAtPath } from '~/components/organism/PluginsEditor/commands';
import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';
import Dropdown from '~/components/molecule/Dropdown';
import useDeepLinkConfigs from '~/hooks/useDeepLinkConfigs';
import Input from '~/components/molecule/Input';
import { updateDeepLink } from '~/components/organism/PluginsEditor/commands/modify/deeplink';
import JustificationContainer from '~/components/atom/JustificationContainer';
import styled, { css } from 'styled-components';
import isConfigEqual from '~/components/organism/PluginsEditor/utils/deepLinks/isConfigEqual';
import convertDeepLinkConfigMappingToMapping from '~/components/organism/PluginsEditor/utils/deepLinks/convertDeepLinkConfigMappingToMapping';
import { Heading3 } from '~/components/atom/Typography';
import Catalog from '~/Catalog';
import { isNonEmptyString } from '~/util/Validation/String';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { hoveringToolbarState } from '~/components/organism/PluginsEditor/state/HoveringToolbarState';
import { useSetRecoilState } from 'recoil';

const textObj = {
  header: 'App link bijwerken',
  dropdownLabel: 'App link',
};

export type Props = {
  element: DeepLinkElement;
  editor: DHEditor;
  path: Path;
};

const DeepLinkToolbar: React.FCC<Props> = ({ element, path, editor }) => {
  const { configOptions } = useDeepLinkConfigs();

  const setHoveringToolbar = useSetRecoilState(hoveringToolbarState);
  const initialSelected = configOptions.find(option =>
    isConfigEqual({
      configItem: option.payload,
      item: {
        type: element.deeplinkType,
        typeId: element.deeplinkTypeId,
      },
    }),
  );

  const [selectedDeepLinkConfig, setSelectedDeepLinkConfig] = useState(
    initialSelected?.payload,
  );

  const [text, setText] = useState<string>(element.deeplinkText);

  const disabled = !selectedDeepLinkConfig || !text;

  const onConfirm = () => {
    if (disabled) return;

    updateDeepLink({
      editor,
      updatedFields: {
        children: element.children,
        mappingId: element.mappingId,
        mapping: convertDeepLinkConfigMappingToMapping(
          selectedDeepLinkConfig.mapping,
        ),
        deeplinkType: selectedDeepLinkConfig.type,
        deeplinkTypeId: selectedDeepLinkConfig.typeId,
        deeplinkConfigName: selectedDeepLinkConfig.name,
        deeplinkText: text,
      },
    });

    setHoveringToolbar(null);
    ReactEditor.focus(editor);
    Transforms.move(editor, {
      distance: 1,
      unit: 'offset',
    });
  };

  useGlobalKeyBinding({
    keys: 'enter',
    callback: () => onConfirm(),
    enabled: !disabled,
  });

  return (
    <Container direction="column" gap={'s'} width="100%">
      <Heading3 size="base">{textObj.header}</Heading3>
      <Dropdown
        options={configOptions}
        onChange={({ option }) => setSelectedDeepLinkConfig(option.payload)}
        selectedOptionIdx={configOptions.findIndex(option =>
          isConfigEqual({
            configItem: option.payload,
            item: {
              type: selectedDeepLinkConfig?.type,
              typeId: selectedDeepLinkConfig?.typeId,
            },
          }),
        )}
        label={textObj.dropdownLabel}
      />
      <Input
        label={{ text: 'Linktekst' }}
        value={text}
        onChange={e => setText(e.target.value)}
        width="100%"
        validation={[
          (value: string) =>
            isNonEmptyString(value) ? true : Catalog.requiredField,
        ]}
      />

      <JustificationContainer gap="s" justification="end" width="100%">
        <Button
          ghost
          icon="trashcan"
          appearance="danger"
          onClick={() => {
            deleteNodeAtPath(editor, path);
            setHoveringToolbar(null);
          }}
          dataTestId={TEST_ID.DELETE_BUTTON}
        />

        <Button
          label={Catalog.confirm}
          onClick={onConfirm}
          disabled={text === ''}
        />
      </JustificationContainer>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>(
  () => css`
    max-width: 500px;
  `,
);

export default DeepLinkToolbar;
