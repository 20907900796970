import React, { useEffect, useState } from 'react';
import type {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import type { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import useWizardStep from '~/hooks/useWizardStep';
import { isNonEmptyString } from '~/util/Validation/String';
import ImportAgendaStepBody from '~/components/page/Apps/Realworks/components/Connections/components/AddTokenContainerWizard/components/ImportAgendaStep/components/ImportAgendaStepBody';

export const agendaHeader = 'Agenda';
export const id = 'RealworksAgenda';
export const title = agendaHeader;

export type OutputType = {
  type: typeof id;
  agendaToken?: OutputFieldInvisible<
    UpdateRealworksAppStatus_AddTokenContainer__Input['agenda']
  >;
};

export const Component: React.FCC<WizardStepProps> = ({ outputMap, step }) => {
  const [, api] = useWizardStep(step.id);

  const previousToken =
    outputMap[id] && 'agendaToken' in outputMap[id]
      ? outputMap[id]?.agendaToken?.value ?? null
      : null;

  const [agenda, setAgenda] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['agenda']>(
      previousToken,
    );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (isNonEmptyString(agenda)) {
      api.free({
        type: id,
        agendaToken: {
          type: 'invisible',
          value: agenda,
        },
      });
    } else {
      api.free({
        type: id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda]);

  return (
    <>
      <ImportAgendaStepBody
        // This is the first step, no tokens to validate against
        tokens={[]}
        onSubmit={onSubmit}
        value={agenda}
        onChange={value => setAgenda(value)}
        skipStepBlock={{
          usage: 'wizard',
        }}
      />
    </>
  );
};

export default {
  id,
  title,
};
