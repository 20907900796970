import React, { useEffect, useState } from 'react';
import Input, { Props as InputProps } from '../Input';
import useDebounce from '~/hooks/useDebounce';

export type Props = InputProps & {
  /** Use this prop instead of onChange prop to use the debounced value */
  onDebouncedChange: (value: InputProps['value']) => void;

  /** Debounce time in milliseconds */
  debounceTime?: number;
};

const DebouncedInput = React.forwardRef<HTMLInputElement, Props>(
  ({ value, onDebouncedChange, debounceTime = 300, ...rest }, ref) => {
    const [inputValue, setInputValue] = useState(value);
    const debouncedValue = useDebounce(inputValue, debounceTime);

    useEffect(() => {
      if (debouncedValue !== value) onDebouncedChange(debouncedValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    return (
      <Input
        {...rest}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        ref={ref}
      />
    );
  },
);

export default DebouncedInput;
