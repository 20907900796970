import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import icons from '~/components/atom/Icon/components';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant, Body } from '~/components/atom/Typography';

export type Props = {
  heading: string;
  description: React.ReactNode;
  label: string;
  onAdd: () => void;
  icon?: keyof typeof icons;
  disabled?: boolean;
};

const AddAPIBlock: React.FCC<Props> = ({
  dataTestId,
  heading,
  onAdd,
  label,
  icon = 'plus',
  description,
  disabled = false,
  ...rest
}) => (
  <Container data-testid={dataTestId} $disabled={disabled} {...rest}>
    <Heading4
      wordBreak="break-all"
      color={{ group: 'primary' }}
      margin={[null]}
      variant={Variant.primary}
    >
      {heading}
    </Heading4>
    <Description size="base" margin={['xxs', null, 'm', null]}>
      {description}
    </Description>
    <StyledButton
      onClick={onAdd}
      size="medium"
      appearance="primary"
      label={label}
      icon={icon}
      disabled={disabled}
      dataTestId={TEST_ID.ADD_BUTTON}
    />
  </Container>
);

const Container = styled(JustificationContainer)<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    flex-direction: column;
    border-radius: ${theme.getTokens().border.radius.m};
    padding: ${theme.space('m')};

    opacity: ${$disabled ? 0.5 : 1};
  `,
);

const Description = styled(Body)<{}>`
  display: flex;
  height: 100%;
`;

const StyledButton = styled(Button)<{}>`
  width: 100%;
  justify-content: flex-start;
`;

export default AddAPIBlock;
