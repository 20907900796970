import type { GeneralIssue, IssueMap } from '../../../../state/flowIssues';
import type { ClientFlowAction } from '~/graphql/types.client';

const text = {
  minimumActionError: 'Flow moet minstens twee acties bevatten',
};

/**
 * Returns the global issues in flow that does not belong in settings or actions
 * @param {Array<ClientFlowAction>} actions - The actions in the flow
 */
const generateGeneralIssues = ({
  actions,
}: {
  actions: Array<ClientFlowAction>;
}): IssueMap => {
  const allIssues = new Map<string, Array<GeneralIssue>>();

  if (actions.length === 1) {
    allIssues.set('flow', [
      {
        type: 'GeneralIssue',
        level: 'warning',
        message: text.minimumActionError,
      },
    ]);
  }

  return allIssues;
};
export default generateGeneralIssues;
