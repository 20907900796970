import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import Divider from '~/components/atom/Divider';
import { FlowAction } from '~/graphql/types';

import { ClientFlowActionWait } from '~/graphql/types.client';
import getCardHeading from '../../../../utils/getCardHeading';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import ConditionList from '../ConditionList';
import useRelativeMaps from '../../../../hooks/useRelativeMaps';
import Statistics from '../Statistics';

export type Props = Omit<
  CardProps<ClientFlowActionWait>,
  'heading' | 'actionType'
> & {
  conditions: Array<any>;
  activePeriod?: number;
  leads: number;
  linkTo: string;
};

export const text = {
  confirmModalHeader: 'Wat moeten we doen met de contacten in deze stap?',
  confirmModalDescription:
    'Er lijken contacten in deze stap van de flow te zitten. Wil je deze contacten doorsturen naar de volgende stap in de flow of de flow stopzetten voor deze contacten?',
  continue: 'Doorsturen naar de volgende stap in de flow',
  drop: 'De flow stoppen voor deze contacten',
  confirm: 'Bevestigen',
};

const WaitForCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, ...props }) => {
    const maps = useRelativeMaps({ actionId: props.data.action.id });

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.Wait}
          heading={getCardHeading(FlowAction.Wait)}
          selected={selected}
          disabled={disabled}
        >
          <ConditionsContainer>
            <ConditionList
              actionType={FlowAction.Start}
              condition={props.data.action.condition}
              maps={maps}
              actionId={props.data.action.id}
            />
          </ConditionsContainer>
          {props.data.action.Statistics && (
            <>
              <Divider />
              <Statistics
                leads={
                  props.data.action.Statistics.waitingFlowInstancesTotal ?? 0
                }
                linkTo={'/-/contacts'}
              />
            </>
          )}
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const ConditionsContainer = styled.div<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default WaitForCard;
