import React from 'react';
import RealworksTokenBodyTemplate from '../../../RealworksTokenBodyTemplate';
import { agendaHeader } from '~/components/organism/WizardSteps/Apps/Realworks/RealworksAgenda';
import {
  realworksCRMLink,
  type TokenStepBodyProps,
} from '~/components/page/Apps/Realworks/constants';
import StepNavigationFooter from '../../../StepNavigationFooter';
import { isNonEmptyString } from '~/util/Validation/String';
import { Body } from '~/components/atom/Typography';
import validateRealworksToken from '~/components/page/Apps/Realworks/utils/validateRealworksToken';

export const importContactsScreenshotLink =
  'https://dathuis-stash.imgix.net/realworks_api_manager.png';

const text = {
  goToRealworks: 'Ga naar marketplace',
  description:
    'Om relaties uit te wisselen met Realworks, gebruiken we de Relatie API. Vul de API Manager in zoals op de onderstaande screenshot. Het is belangrijk dat de instellingen exact overeenkomen.',
};

export const realworksRelatieAPIPath = [
  'Servicedesk',
  'Marketplace',
  "API's",
  'Relatie API',
  'Bestellen',
];

const ImportContactsStepBody: React.FC<TokenStepBodyProps> = ({
  value,
  onChange,
  skipStepBlock,
  tokens,
  onSubmit,
  navigationFooter,
}) => (
  <RealworksTokenBodyTemplate
    helpBlock={{ tipId: 'import-contacts-step-tip' }}
    description={<Body>{text.description}</Body>}
    formConfig={{
      header: agendaHeader,
      pathToCRM: {
        path: realworksRelatieAPIPath,
        link: realworksCRMLink,
      },
      screenshotLink: importContactsScreenshotLink,
      value,
      validate: value =>
        validateRealworksToken({ mainToken: value, tokensToCompare: tokens }),
      onChange,
      onSubmit,
      footer: navigationFooter ? (
        <StepNavigationFooter
          {...navigationFooter}
          disabled={
            !isNonEmptyString(value) ||
            validateRealworksToken({
              mainToken: value,
              tokensToCompare: tokens,
            }) !== true
          }
          onSkip={skipStepBlock?.onSkip}
        />
      ) : null,
    }}
  />
);

export default ImportContactsStepBody;
