import type { HandledFlowAction } from '~/graphql/types.client';
import { ConditionIssue } from '~/components/page/Automation/v2/state/flowIssues';
import { getCondition } from '../../UpdateAction/components/Selector/utils/getConditions';
import getPathForPointer from '../../UpdateAction/components/Selector/utils/getPathForPointer';
import getSubject from '../../UpdateAction/components/Selector/utils/getSubject';
import getSubjectIdForArgument from '../../UpdateAction/components/Selector/utils/getSubjectIdForArgument';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import extendRelativeMaps from '../utils/getUpToDateRelativeMaps/utils/extendRelativeMaps';
import generateConditionIssue from './utils/generateConditionIssue';
import type { DeepLinksMap } from '../../../state/deepLinks';
import type {
  ConditionExpression,
  Flow___ConditionPartial,
  Flow___SubjectFieldConditionPartial,
  Flow___InstanceConditionPartial,
} from '../../UpdateAction/components/ConditionEditorV2/types';
import { ID_PREFIX } from '~/util/generateIdForEntity';
import type { ExpandedUsers } from '~/hooks/useUsers';
import type { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';

export const messages = {
  emptyCondition: 'Condities mogen niet leeg zijn',
  missingTrigger: 'Condities moeten een startpunt hebben',
  missingArg: 'Conditie mist één of meerdere argumenten',
  missingInvalidOrMissingArg: 'Conditie mist een geldige waarde',
  missingPointer: 'Conditie wijst naar een waarde die niet (meer) bestaat',
  multipleTriggersNotAllowed: 'Meerdere triggers zijn niet toegestaan',
  missingDeepLinkConfig: 'Een app link bestaat niet meer',
};

type Args = {
  /** The id of the action this condition resides in, for pointer validation */
  actionId: string;
  /** The type of action we are validating */
  actionType: HandledFlowAction;
  /** The condition expression to validate */
  condition: ConditionExpression;
};

type Opts = {
  /** This condition expression has triggers */
  hasTriggers: boolean;
  /** The amount of triggers is limited to a certain amount, default is no limit */
  maxTriggers?: number;
  maps: RelativeMaps;
  users: ExpandedUsers;
  offices: Array<SessionHydrationOfficeFieldsFragment>;
};

/**
 * Returns an array of all issues for given condition.
 *
 * keywords: issues,flow,condition
 */
const getIssuesForConditionExpression = ({
  args: { actionId, actionType, condition },
  opts: { hasTriggers, maxTriggers, maps, users, offices },
  deepLinksMap,
}: {
  args: Args;
  opts: Opts;
  deepLinksMap: DeepLinksMap;
}): Array<ConditionIssue> => {
  const issues: Array<ConditionIssue> = [];

  if (condition.conditionSubExpression[0]?.conditionGroups[0] == null) {
    /** Missing trigger condition */
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx: condition.conditionSubExpression.length - 1,
        conditionGroupIdx: 0,
        message: messages.emptyCondition,
      }),
    ];
  }

  if (maxTriggers != null) {
    if (condition.conditionSubExpression.length > maxTriggers) {
      return [
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx:
            condition.conditionSubExpression.length - 1,
          conditionGroupIdx: 0,
          message: messages.multipleTriggersNotAllowed,
        }),
      ];
    }
  }

  condition.conditionSubExpression.forEach(
    (subExpression, conditionSubExpressionIdx) => {
      const trigger: Flow___ConditionPartial | null =
        subExpression.conditionGroups[0].conditions[0] ?? null;

      subExpression.conditionGroups.forEach(
        (conditionGroup, conditionGroupIdx) => {
          conditionGroup.conditions.forEach((condition, conditionIdx) => {
            if (condition == null) {
              /** Not filled out condition */
              issues.push(
                generateConditionIssue({
                  actionType,
                  actionId,
                  level: 'error',
                  conditionSubExpressionIdx,
                  conditionGroupIdx,
                  conditionIdx,
                  argumentIdx: 0,
                  message: messages.emptyCondition,
                }),
              );
              return;
            }

            const isTrigger =
              hasTriggers && conditionGroupIdx === 0 && conditionIdx === 0;

            const relativeMaps = (() => {
              if (!hasTriggers) return maps;
              if (isTrigger) return maps;
              if (!trigger) return maps;

              return extendRelativeMaps({
                actionId,
                condition: trigger,
                maps,
              });
            })();

            switch (condition.__typename) {
              case 'Flow___InstanceCondition':
                issues.push(
                  ...validateCondition(
                    {
                      actionId,
                      actionType,
                      conditionSubExpressionIdx,
                      conditionGroupIdx,
                      conditionIdx,
                      condition,
                    },
                    relativeMaps,
                    deepLinksMap,
                    users,
                    offices,
                  ),
                );

                return;
              case 'Flow___SubjectFieldCondition':
                issues.push(
                  ...validateSubjectFieldCondition(
                    {
                      actionId,
                      actionType,
                      conditionSubExpressionIdx,
                      conditionGroupIdx,
                      conditionIdx,
                      condition,
                    },
                    relativeMaps,
                  ),
                );
                return;
            }
          });
        },
      );
    },
  );

  return issues;
};

const validateSubjectFieldCondition = (
  {
    conditionSubExpressionIdx,
    conditionGroupIdx,
    conditionIdx,
    actionId,
    condition,
    actionType,
  }: {
    conditionSubExpressionIdx: number;
    conditionGroupIdx: number;
    conditionIdx: number;
    condition: Flow___SubjectFieldConditionPartial;
    actionId: string;
    actionType: HandledFlowAction;
  },
  maps: RelativeMaps,
): Array<ConditionIssue> => {
  const subject = getSubject(
    {
      type: condition.type,
      typeId: condition.typeId,
    },
    maps.subjectMap,
  );

  if (!subject) {
    /** Did not find the flow subject - This probably means an app was disabled */
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
        argumentIdx: 0,
        message: messages.missingPointer,
      }),
    ];
  }

  /** Flow Subject exists, make sure we have a field with the given key */
  const subjectFieldCondition = subject.fields.find(
    field => field.key === condition.field,
  );

  if (!subjectFieldCondition) {
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
        argumentIdx: 0,
        message: messages.missingPointer,
      }),
    ];
  }

  if (subjectFieldCondition.args.length !== condition.args.length) {
    /** Invalid length of arguments */
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
        message: messages.missingArg,
      }),
    ];
  }

  const issues: Array<ConditionIssue> = [];

  subjectFieldCondition.args.forEach((expectedArgument, argIndex) => {
    const givenArgument = condition.args[argIndex];
    if (givenArgument == null) {
      issues.push(
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx: argIndex + 1,
          message: messages.missingArg,
        }),
      );

      return;
    }

    const subjectIdResult = getSubjectIdForArgument(givenArgument, maps);
    if (subjectIdResult.error != null) {
      issues.push(
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx: argIndex + 1,
          message: subjectIdResult.error,
        }),
      );

      return;
    }

    /**
     * We only validate the correctness of pointers all other input
     * has to be assumed valid.
     *
     * It cannot be validated since direct input only results in primitives
     * String, Number, etc.
     *
     * Which would collide with more specific values like "Tag".
     */
    if (givenArgument.__typename !== 'Flow___Argument_Pointer') {
      return;
    }

    if (
      expectedArgument.type != subjectIdResult.subjectId.type ||
      expectedArgument.typeId != subjectIdResult.subjectId.typeId
    ) {
      /** Types are mismatching */
      issues.push(
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx: argIndex + 1,
          message: messages.missingInvalidOrMissingArg,
        }),
      );

      return;
    }
  });

  return issues;
};

const validateCondition = (
  {
    conditionSubExpressionIdx,
    conditionGroupIdx,
    conditionIdx,
    actionId,
    condition,
    actionType,
  }: {
    conditionSubExpressionIdx: number;
    conditionGroupIdx: number;
    conditionIdx: number;
    condition: Flow___InstanceConditionPartial;
    actionId: string;
    actionType: HandledFlowAction;
  },
  maps: RelativeMaps,
  deepLinksMap: DeepLinksMap,
  users: ExpandedUsers,
  offices: Array<SessionHydrationOfficeFieldsFragment>,
): Array<ConditionIssue> => {
  const expectedCondition = getCondition(condition.type, maps.conditionMap);

  if (!expectedCondition) {
    /** Did not find the flow subject - This probably means an app was disabled */
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
        argumentIdx: 0,
        message: messages.missingTrigger,
      }),
    ];
  }

  if (expectedCondition.args.length !== condition.args.length) {
    /** Invalid length of arguments */
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
        message: messages.missingInvalidOrMissingArg,
      }),
    ];
  }

  const res = getPathForPointer(condition.input, maps);
  if (res.error != null) {
    return [
      generateConditionIssue({
        actionType,
        actionId,
        level: 'error',
        conditionSubExpressionIdx,
        conditionGroupIdx,
        conditionIdx,
        argumentIdx: 0,
        message: messages.missingPointer,
      }),
    ];
  }

  const issues: Array<ConditionIssue> = [];

  expectedCondition.args.forEach((expectedArgument, argIndex) => {
    const givenArgument = condition.args[argIndex];

    if (givenArgument == null) {
      issues.push(
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx: argIndex + 1,
          message: messages.missingInvalidOrMissingArg,
        }),
      );

      return;
    }

    if (
      expectedCondition.type === 'Condition_User_Eq' &&
      givenArgument.__typename === 'Flow___Argument_String'
    ) {
      const userExists = users.some(
        user => user.id === givenArgument.value_string,
      );
      if (!userExists) {
        issues.push(
          generateConditionIssue({
            actionType,
            actionId,
            level: 'error',
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
            argumentIdx: argIndex + 1,
            message: messages.missingInvalidOrMissingArg,
          }),
        );
        return;
      }
    }

    if (
      expectedCondition.type === 'Condition_Office_Eq' &&
      givenArgument.__typename === 'Flow___Argument_String'
    ) {
      const officeExists = offices.some(
        office => office.id === givenArgument.value_string,
      );
      if (!officeExists) {
        issues.push(
          generateConditionIssue({
            actionType,
            actionId,
            level: 'error',
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
            argumentIdx: argIndex + 1,
            message: messages.missingInvalidOrMissingArg,
          }),
        );
        return;
      }
    }

    if (
      givenArgument.__typename === 'Flow___Argument_String' &&
      givenArgument.value_string?.startsWith(ID_PREFIX.DEEP_LINK)
    ) {
      if (!deepLinksMap[givenArgument.value_string])
        issues.push(
          generateConditionIssue({
            actionType,
            actionId,
            level: 'error',
            conditionSubExpressionIdx,
            conditionGroupIdx,
            conditionIdx,
            argumentIdx: argIndex + 1,
            message: messages.missingDeepLinkConfig,
          }),
        );

      return;
    }

    const subjectIdResult = getSubjectIdForArgument(givenArgument, maps);

    if (subjectIdResult.error != null) {
      issues.push(
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx: argIndex + 1,
          message: subjectIdResult.error,
        }),
      );

      return;
    }

    /**
     * We only validate the correctness of pointers all other input
     * has to be assumed valid.
     *
     * It cannot be validated since direct input only results in primitives
     * String, Number, etc.
     *
     * Which would collide with more specific values like "Tag".
     */
    if (givenArgument.__typename !== 'Flow___Argument_Pointer') {
      return issues;
    }

    if (
      expectedArgument.type != subjectIdResult.subjectId.type ||
      expectedArgument.typeId != subjectIdResult.subjectId.typeId
    ) {
      /** Types are mismatching */
      issues.push(
        generateConditionIssue({
          actionType,
          actionId,
          level: 'error',
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx: argIndex + 1,
          message: messages.missingInvalidOrMissingArg,
        }),
      );
    }

    return;
  });

  return issues;
};

export default getIssuesForConditionExpression;
