import { useLocation, navigate } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import { LINK_PREFIX, StepProps } from '../..';
import TEST_ID from './index.testid';
import { wonenHeader } from '~/components/organism/WizardSteps/Apps/Realworks/RealworksExportContacts';
import ExportContactsStepBody from './components/ExportContactsStepBody';

export const exportContactsScreenshotLink =
  'https://dathuis-stash.imgix.net/realworks_export_contacts.png';

/**
 * This is temporarily removed from the Wizard but will be back.
 */
const ExportContactsStep: React.FCC<StepProps> = ({ onCancel }) => {
  const [wonen, setWonen] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['wonen']>(null);

  // @ts-ignore WindowLocation<S = unknown>. S is the type of state but we cannot type it ourselves because the types come from types/reach__router
  const location: WindowLocation<{
    values: UpdateRealworksAppStatus_AddTokenContainer__Input;
  }> = useLocation();
  const locationValues = location?.state?.values || {};

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    void navigate(`${LINK_PREFIX}/activation-pending`, {
      replace: true,
      state: { values: { ...location?.state.values, wonen } },
    });
  };

  const skipCurrentStep = () =>
    navigate(`${LINK_PREFIX}/activation-pending`, {
      replace: true,
      state: { values: { ...locationValues, wonen: null } },
    });

  const relatieToken = locationValues.relatie;
  const agendaToken = locationValues.agenda;

  return (
    <AppDetailsContainer
      header={wonenHeader}
      icon="outgoingUser"
      dataTestId={TEST_ID.CONTAINER}
    >
      <ExportContactsStepBody
        tokens={[relatieToken, agendaToken]}
        onSubmit={onSubmit}
        navigationFooter={{
          onCancel,
        }}
        skipStepBlock={{
          usage: 'app',
          onSkip: skipCurrentStep,
        }}
        value={wonen}
        onChange={value => setWonen(value)}
      />
    </AppDetailsContainer>
  );
};

export default ExportContactsStep;
