import { ComponentType } from 'react';
import { WizardStepProps } from '../Wizard/context/WizardContext';
// Zapier
import {
  id as ConnectZapierId,
  Component as ConnectZapierComponent,
  OutputType as ConnectZapierOutput,
} from './Apps/Zapier/ConnectZapier';
import {
  id as AddZapierEventId,
  Component as AddZapierEventComponent,
  OutputType as AddZapierEventOutput,
} from './Apps/Zapier/AddZapierEvent';
import {
  id as AddZapierTriggerId,
  Component as AddZapierTriggerComponent,
  OutputType as AddZapierTriggerOutput,
} from './Apps/Zapier/AddZapierTrigger';
import {
  id as ZapierNextStepsId,
  Component as ZapierNextStepsComponent,
  OutputType as ZapierNextStepsOutput,
} from './Apps/Zapier/ZapierNextSteps';

// Trustoo
import {
  id as AddTrustooKoppeling,
  Component as AddTrustooKoppelingComponent,
  OutputType as AddTrustooKoppelingOutput,
} from './Apps/Trustoo/AddTrustooKoppeling';

// CONTAQT
import {
  id as AddContaqtKoppelingId,
  Component as AddContaqtKoppelingComponent,
  OutputType as AddContaqtKoppelingOutput,
} from './Apps/Contaqt/AddContaqtKoppeling';

// Realworks
import {
  id as RealworksSetupId,
  Component as RealworksSetupComponent,
  OutputType as RealworksSetupOutput,
} from './Apps/Realworks/RealworksSetup';
import {
  id as RealworksImportContactsId,
  Component as RealworksImportContactsComponent,
  OutputType as RealworksImportContactsOutput,
} from './Apps/Realworks/RealworksImportContacts';
// import {
//   id as RealworksExportContactsId,
//   Component as RealworksExportContactsComponent,
//   OutputType as RealworksExportContactsOutput,
// } from './Apps/Realworks/RealworksExportContacts';
import {
  id as RealworksAgendaId,
  Component as RealworksAgendaComponent,
  OutputType as RealworksAgendaOutput,
} from './Apps/Realworks/RealworksAgenda';
import {
  id as RealworksActivationPendingId,
  Component as RealworksActivationPendingComponent,
  OutputType as RealworksActivationPendingOutput,
} from './Apps/Realworks/RealworksActivationPending';

// Waardecheck
import {
  id as WaardecheckGeneralId,
  Component as WaardecheckGeneralComponent,
  OutputType as WaardecheckGeneralOutput,
} from './Apps/Waardecheck/WaardecheckGeneral';
import {
  id as WaardecheckDesignId,
  Component as WaardecheckDesignComponent,
  OutputType as WaardecheckDesignOutput,
} from './Apps/Waardecheck/WaardecheckDesign';
// Hypotheekbond
import {
  id as HypotheekbondColorSettingsId,
  Component as HypotheekbondColorSettingsComponent,
  OutputType as HypotheekbondColorSettingsOutput,
} from './Apps/Hypotheekbond/HypotheekColorSettings';

// ValueReport
import {
  id as ValueReportGeneralImageSettingsId,
  Component as ValueReportGeneralImageSettingsComponent,
  OutputType as ValueReportGeneralImageSettingsOutput,
} from './Apps/ValueReport/ValueReportGeneralImageSettings';
import {
  id as ValueReportColorSettingsId,
  Component as ValueReportColorSettingsComponent,
  OutputType as ValueReportColorSettingsOutput,
} from './Apps/ValueReport/ValueReportColorSettings';

// Stellax AI
import {
  id as StellaxAiId,
  Component as StellaxAiComponent,
  OutputType as StellaxAiOutput,
} from './Apps/StellaxAi/AppDetails';

// Enable flows
import {
  id as EnableFlowsForAppStatusId,
  Component as EnableFlowsForAppStatusComponent,
  OutputType as EnableFlowsForAppStatusOutput,
} from './Apps/components/EnableFlowsForAppStatus/index';

// Overview
import {
  id as AppStatusOverviewId,
  Component as AppStatusOverviewComponent,
  OutputType as AppStatusOverviewOutput,
} from './Apps/components/AppStatusOverview';
import {
  id as GenericId,
  Component as GenericStep,
  OutputType as GenericOutputType,
} from './GenericSteps/GenericStep';
import {
  id as OnboardingOverviewId,
  Component as OnboardingOverviewStep,
  OutputType as OnboardingOverviewStepOutput,
} from './OnboardingWizardSteps/OnboardingOverviewStep';
import {
  id as GenericWidgetConfigurationId,
  Component as GenericWidgetConfigurationStep,
  OutputType as GenericWidgetConfigurationOutputType,
} from './GenericSteps/GenericWidgetConfiguration';

// Onboarding
import {
  id as WelcomeScreenId,
  Component as WelcomeScreenStep,
  OutputType as WelcomeScreenOutput,
} from './OnboardingWizardSteps/WelcomeScreen';
import {
  id as AddWidgetScriptId,
  Component as AddWidgetScriptStep,
  OutputType as AddWidgetScriptOutput,
} from './OnboardingWizardSteps/AddWidgetScript';

import {
  id as SelectAppsForWidgetId,
  Component as SelectAppsForWidgetStep,
  OutputType as SelectAppsForWidgetOutput,
} from './OnboardingWizardSteps/SelectAppsForWidget';

import {
  id as SynchronizeEmailId,
  Component as SynchronizeEmailComponent,
  OutputType as SynchronizeEmailOutputType,
} from './OnboardingWizardSteps/SynchronizeEmail';
import {
  id as UpdateSignatureId,
  Component as UpdateSignatureComponent,
  OutputType as UpdateSignatureOutput,
} from './OnboardingWizardSteps/UpdateSignature';

import {
  id as WidgetStyleConfigurationId,
  Component as WidgetStyleConfigurationComponent,
  OutputType as WidgetStyleConfigurationOutput,
} from './OnboardingWizardSteps/WidgetStyleConfiguration';

import {
  id as ConfirmAppsEnablementId,
  Component as ConfirmAppsEnablementStep,
  OutputType as ConfirmAppsEnablementOutput,
} from './OnboardingWizardSteps/ConfirmAppsEnablement';

import {
  id as WalletSettingsId,
  Component as WalletSettingsStep,
  OutputType as WalletSettingsOutput,
} from './OnboardingWizardSteps/WalletSettings';

import {
  id as LoadingScreenId,
  Component as LoadingScreenStep,
  OutputType as LoadingScreenOutput,
} from './OnboardingWizardSteps/LoadingScreen';

import {
  id as EnabledAppsOverviewId,
  Component as EnabledAppsOverviewComponent,
  OutputType as EnabledAppsOverviewOutput,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/EnabledAppsOverview';
import {
  id as OnboardingStellaxAiSelectProductId,
  Component as OnboardingStellaxAiSelectProductComponent,
  OutputType as OnboardingStellaxAiSelectProductOutput,
} from './OnboardingWizardSteps/OnboardingStellaxAiSelectProduct';

export type StepId =
  // Onboarding
  | typeof WelcomeScreenId
  | typeof SynchronizeEmailId
  | typeof UpdateSignatureId
  | typeof WidgetStyleConfigurationId
  | typeof AddWidgetScriptId
  | typeof SelectAppsForWidgetId
  | typeof ConfirmAppsEnablementId
  | typeof WalletSettingsId
  | typeof LoadingScreenId
  | typeof EnabledAppsOverviewId

  // Generic step ids
  | typeof GenericId
  | typeof GenericWidgetConfigurationId
  | typeof OnboardingOverviewId
  // Zapier
  | typeof ConnectZapierId
  | typeof AddZapierEventId
  | typeof AddZapierTriggerId
  | typeof ZapierNextStepsId

  // Trustoo
  | typeof AddTrustooKoppeling

  // Contaqt
  | typeof AddContaqtKoppelingId

  // Realworks
  | typeof RealworksSetupId
  | typeof RealworksImportContactsId
  // | typeof RealworksExportContactsId
  | typeof RealworksAgendaId
  | typeof RealworksActivationPendingId
  // Waardecheck
  | typeof WaardecheckGeneralId
  | typeof WaardecheckDesignId

  // Hypotheekbond
  | typeof HypotheekbondColorSettingsId

  // ValueReport
  | typeof ValueReportGeneralImageSettingsId
  | typeof ValueReportColorSettingsId

  // StellaxAI
  | typeof OnboardingStellaxAiSelectProductId
  | typeof StellaxAiId

  // Enable flow
  | typeof EnableFlowsForAppStatusId
  // Overview
  | typeof AppStatusOverviewId;

export type StepComponentMap = Record<StepId, ComponentType<WizardStepProps>>;

/**
 * Each new type of step we create should be added to this map
 * This map is used to look up the StepComponent by ID because we cannot serialise/persist the component in the db
 */

const stepComponentMap: StepComponentMap = {
  // Onboarding
  [SynchronizeEmailId]: SynchronizeEmailComponent,
  [WelcomeScreenId]: WelcomeScreenStep,
  [WidgetStyleConfigurationId]: WidgetStyleConfigurationComponent,
  [AddWidgetScriptId]: AddWidgetScriptStep,
  [UpdateSignatureId]: UpdateSignatureComponent,
  [SelectAppsForWidgetId]: SelectAppsForWidgetStep,
  [ConfirmAppsEnablementId]: ConfirmAppsEnablementStep,
  [WalletSettingsId]: WalletSettingsStep,
  [LoadingScreenId]: LoadingScreenStep,
  [EnabledAppsOverviewId]: EnabledAppsOverviewComponent,

  // Generic steps
  [GenericId]: GenericStep,
  [GenericWidgetConfigurationId]: GenericWidgetConfigurationStep,
  [OnboardingOverviewId]: OnboardingOverviewStep,
  // Zapier
  [ConnectZapierId]: ConnectZapierComponent,
  [AddZapierEventId]: AddZapierEventComponent,
  [AddZapierTriggerId]: AddZapierTriggerComponent,
  [ZapierNextStepsId]: ZapierNextStepsComponent,

  // Trustoo
  [AddTrustooKoppeling]: AddTrustooKoppelingComponent,

  // Contaqt
  [AddContaqtKoppelingId]: AddContaqtKoppelingComponent,

  // Realworks
  [RealworksSetupId]: RealworksSetupComponent,
  [RealworksImportContactsId]: RealworksImportContactsComponent,
  // [RealworksExportContactsId]: RealworksExportContactsComponent,
  [RealworksAgendaId]: RealworksAgendaComponent,
  [RealworksActivationPendingId]: RealworksActivationPendingComponent,

  // Waardecheck
  [WaardecheckGeneralId]: WaardecheckGeneralComponent,
  [WaardecheckDesignId]: WaardecheckDesignComponent,
  // Hypotheekbond
  [HypotheekbondColorSettingsId]: HypotheekbondColorSettingsComponent,

  // ValueReport
  [ValueReportGeneralImageSettingsId]: ValueReportGeneralImageSettingsComponent,
  [ValueReportColorSettingsId]: ValueReportColorSettingsComponent,

  // StellaxAI
  [OnboardingStellaxAiSelectProductId]:
    OnboardingStellaxAiSelectProductComponent,
  [StellaxAiId]: StellaxAiComponent,

  // Enable flows
  [EnableFlowsForAppStatusId]: EnableFlowsForAppStatusComponent,
  // Overview
  [AppStatusOverviewId]: AppStatusOverviewComponent,
};

export type StepOutput =
  // Onboarding
  | SynchronizeEmailOutputType
  | WelcomeScreenOutput
  | WidgetStyleConfigurationOutput
  | AddWidgetScriptOutput
  | UpdateSignatureOutput
  | SelectAppsForWidgetOutput
  | ConfirmAppsEnablementOutput
  | WalletSettingsOutput
  | LoadingScreenOutput
  | EnabledAppsOverviewOutput
  // Zapier
  | ConnectZapierOutput
  | AddZapierEventOutput
  | AddZapierTriggerOutput
  | ZapierNextStepsOutput
  // Trustoo
  | AddTrustooKoppelingOutput
  // Contaqt
  | AddContaqtKoppelingOutput
  // Realworks
  | RealworksSetupOutput
  | RealworksImportContactsOutput
  // | RealworksExportContactsOutput
  | RealworksAgendaOutput
  | RealworksActivationPendingOutput
  // Waardecheck
  | WaardecheckGeneralOutput
  | WaardecheckDesignOutput
  // Hypotheekbond
  | HypotheekbondColorSettingsOutput
  // ValueReport
  | ValueReportGeneralImageSettingsOutput
  | ValueReportColorSettingsOutput
  // StellaxAI
  | OnboardingStellaxAiSelectProductOutput
  | StellaxAiOutput
  // Generic step outputs
  | GenericOutputType
  | GenericWidgetConfigurationOutputType
  | OnboardingOverviewStepOutput
  // Enable flows
  | EnableFlowsForAppStatusOutput
  | AppStatusOverviewOutput;

export type OutputMap = Record<StepId, StepOutput>;

export default stepComponentMap;
