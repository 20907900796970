import React from 'react';
import { FlowAction } from '~/graphql/types';
import ConditionEditor from '../ConditionEditorV2';
import { Props as FormProps } from '../ActionForm';
import { ClientFlowActionStart } from '~/graphql/types.client';
import { Body } from '~/components/atom/Typography';

export type Props = FormProps & {
  dataTestId?: string;
  action: ClientFlowActionStart;
};

const StartAction: React.FCC<Props> = ({
  action,
  maps,
  inputListPrimitives,
  inputPrimitives,
  onChange,
}) => (
  <div>
    <Body margin={[null, null, 'm', null]}>
      Voeg één trigger toe, waarmee de flow wordt gestart. De trigger kan worden
      verfijnd met condities.
    </Body>
    <ConditionEditor
      actionId={action.id}
      action={FlowAction.Start}
      hasTriggers
      limitTriggers={1}
      conditionExpression={action.condition}
      maps={maps}
      inputListPrimitives={inputListPrimitives}
      inputPrimitives={inputPrimitives}
      textObject={{
        newCondition: 'Conditie verfijnen',
        emptyCondition: 'Selecteer een conditie...',
        newTrigger: '',
        emptyTrigger: 'Wanneer start deze flow? Voeg een trigger toe.',
        addValue: '+ Waarde toevoegen',
      }}
      onChange={nextConditionExpression =>
        onChange({
          ...action,
          condition: nextConditionExpression,
        })
      }
    />
  </div>
);

export default StartAction;
