import type { DeepLinkElement, VariableElement } from '../../types';

const getInlineStylesFromSlateMarks = (
  element: DeepLinkElement | VariableElement,
): React.CSSProperties => {
  const style: React.CSSProperties = {};

  if (element.children[0].bold) {
    style.fontWeight = 'bold';
  }
  if (element.children[0].italic) {
    style.fontStyle = 'italic';
  }

  return style;
};

export default getInlineStylesFromSlateMarks;
