import React, { useCallback } from 'react';
import { Path } from 'slate';
import Button from '~/components/atom/Button';
import { deleteNodeAtPath } from '~/components/organism/PluginsEditor/commands';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import { hoveringToolbarState } from '../../../state/HoveringToolbarState';
import { useSetRecoilState } from 'recoil';

export type Props = {
  editor: DHEditor;
  path: Path;
  mode?: 'lowest' | 'highest' | undefined;
};

const DeleteButton: React.FCC<Props> = ({ editor, path, mode, ...rest }) => {
  const setHoveringToolbar = useSetRecoilState(hoveringToolbarState);

  const onClick = useCallback(() => {
    setHoveringToolbar(null);
    deleteNodeAtPath(editor, path, mode);
  }, [editor, path, mode, setHoveringToolbar]);

  return (
    <Button
      appearance="danger"
      icon="trashcan"
      ghost
      onClick={onClick}
      {...rest}
    />
  );
};

export default DeleteButton;
