import convertToFrontend from '../convertToFrontend';
import type { QueriedActivity, ActivityV2 } from '../../types';

/**
 * Generates the shape that backend will return from getActivitiesForContact query after the new fields are added
 *
 * @param {QueriedActivity} activity - activity returned from query
 * keywords: newEvent, activityv2
 */
const generateActivityV2 = (activity: QueriedActivity): ActivityV2 => {
  switch (activity.__typename) {
    case 'Task_Contact':
    case 'Event_Contact_Email':
      return {
        ...activity,
        body: [],
      };

    case 'Event_Contact_Generic':
      return {
        ...activity,
        header: activity.header ?? {
          text: '',
        },
        icon: activity.icon ?? {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Plus.svg',
        },
        body: convertToFrontend(activity.body),
      };

    default:
      const _activity = activity as ActivityV2;
      return {
        ..._activity,
        header: {
          __typename: 'Event_Contact_GenericHeader',
          text: `# Not implemented \`${_activity.__typename}\``,
        },
        icon: {
          __typename: 'Image',
          url: 'https://dathuis-stash.imgix.net/event_icons/Plus.svg',
        },
        body: [],
      };
  }
};

export default generateActivityV2;
