import { memoize } from 'underscore';

import { type GetFormsQuery } from '~/graphql/types';
import { ascend, descend, isNil, path } from 'ramda';
import { SortSettings } from '~/hooks/useSortSettings';

export type FormListData = {
  id: string;
  name: string;
  description?: string | null;
  createdDate: Date;
  lastModified: Date | null;
  shared: {
    enabled: boolean;
    id: string;
  };
};

const composeListData = (
  items: GetFormsQuery['getForms'] | null | undefined,
  version: string,
  sortSettings: SortSettings<Array<string>>,
): Array<FormListData> => {
  if (items == null) return [];

  // @ts-ignore I couldn't explain TS what we're doing here, so ignore for now.
  const sorter = (sortSettings.desc ? descend : ascend)(path(sortSettings.id));

  return items
    .map(
      ({
        name,
        description,
        id,
        enabled,
        share,
        createdDate,
        lastModified,
      }) => ({
        id,
        name,
        formDescription: description,
        enabled,
        createdDate: new Date(createdDate),
        lastModified: lastModified?.date ? new Date(lastModified.date) : null,
        shared: {
          enabled: !isNil(share),
          id,
        },
      }),
    )
    .sort((a, b) => sorter(a, b));
};

const memoizedComposeFormListData = memoize(
  composeListData,
  (
    items: Array<any>,
    version: string,
    sortSettings: SortSettings<Array<string>>,
  ) => {
    const sortStr = JSON.stringify(sortSettings);
    const result = `${version}*@@*${items.length}*@@*${sortStr}*@@*`;

    return result;
  },
);

export default memoizedComposeFormListData;
