import { ClientFlowActionTrickle } from '~/graphql/types.client';
import { Issue } from '~/components/page/Automation/v2/state/flowIssues';
import { convertToFullTimeString } from '~/util/time';
import { VALIDATION_FOR_LIMIT_FIELDS } from '../../../../components/Trickle/constants';
import { Trickle_PeriodType } from '~/graphql/types';

const text = {
  daysOfWeek: 'Minstens één dag van de week moet geselecteerd zijn',
  timeRange: 'Eindtijd moet na de starttijd zijn',
  amount: 'Bedrag moet groter of gelijk aan 0 zijn',
  periodMinimum: 'Periode moet groter zijn dan 0',
  noLimitsOrRules:
    'Geen limieten of regels gevonden, je moet minstens één toevoegen',
};

const collectIssuesForTrickle = (
  action: ClientFlowActionTrickle,
): Array<Issue> => {
  const issues: Array<Issue> = [];

  action.rules.forEach(rule => {
    if (rule.daysOfWeek.length === 0) {
      issues.push({
        message: text.daysOfWeek,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'daysOfWeek',
      });
    }

    const fullTimeStart = convertToFullTimeString(
      rule.timeRanges[0].start.hour,
      rule.timeRanges[0].start.minute,
    );
    const fullTimeEnd = convertToFullTimeString(
      rule.timeRanges[0].end.hour,
      rule.timeRanges[0].end.minute,
    );
    if (fullTimeEnd < fullTimeStart) {
      issues.push({
        message: text.timeRange,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'timeRange',
      });
    }
  });

  action.limits.forEach(limit => {
    if (Number(limit.amount) < 0) {
      issues.push({
        message: text.amount,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'amount',
      });
    }

    if (Number(limit.amount) > VALIDATION_FOR_LIMIT_FIELDS.contacts.maxAmount) {
      issues.push({
        message: VALIDATION_FOR_LIMIT_FIELDS.contacts.message,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'maxContactsAmount',
      });
    }

    if (Number(limit.period.value) <= 0) {
      issues.push({
        message: text.periodMinimum,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'periodMinimum',
      });
    }

    if (
      limit.period.type === Trickle_PeriodType.Days &&
      Number(limit.period.value) > VALIDATION_FOR_LIMIT_FIELDS.days.maxAmount
    ) {
      issues.push({
        message: VALIDATION_FOR_LIMIT_FIELDS.days.message,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'dayPeriodMaximum',
      });
    }

    if (
      limit.period.type === Trickle_PeriodType.Hours &&
      Number(limit.period.value) > VALIDATION_FOR_LIMIT_FIELDS.hours.maxAmount
    ) {
      issues.push({
        message: VALIDATION_FOR_LIMIT_FIELDS.hours.message,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: 'hourPeriodMaximum',
      });
    }
  });

  if (action.limits.length === 0 && action.rules.length === 0) {
    issues.push({
      message: text.noLimitsOrRules,
      level: 'error',
      type: 'ValidationIssue',
      actionId: action.id,
      actionType: action.actionType,
      key: 'noLimitsOrRules',
    });
  }

  return issues;
};

export default collectIssuesForTrickle;
