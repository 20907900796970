import { v4 as uuid } from 'uuid';
import { clone, isNil } from 'ramda';
import isSelectTypeBlock from '~/components/page/Forms/components/Builder/utils/typeguards/isSelectTypeBlock';
import type {
  FormBuilder_OptionList,
  FormBuilder_OptionListFragment,
  FormBuilder_ScreenNode,
} from '~/graphql/types';
import generateIdForEntity from '~/util/generateIdForEntity';

export type OptionListForBlockLookup = {
  [optionListId: string]: {
    blockKey: string;
    newOptionListId: string;
  };
};

export type Params = {
  copiedNode: FormBuilder_ScreenNode;
  isBasicForm: boolean;
  optionLists: Array<FormBuilder_OptionListFragment>;
};

/**
 * Duplicate the option lists and return a lookup table to match the select blocks with correspongding option lists
 */
const getDuplicatedOptionLists = ({
  copiedNode,
  isBasicForm,
  optionLists,
}: Params): {
  optionListForBlockLookup: OptionListForBlockLookup | null;
  updatedOptionLists: Array<FormBuilder_OptionListFragment>;
} => {
  const selectBlocks = copiedNode?.blocks.filter(isSelectTypeBlock);
  const hasSelectBlocks = selectBlocks.length > 0;

  // Allow to reuse the option lists in the advanced form
  if (!hasSelectBlocks || !isBasicForm)
    return {
      optionListForBlockLookup: null,
      updatedOptionLists: optionLists,
    };

  // Keep track of previous and new option list id so that we can assign the new id to the correct block
  const optionListForBlockLookup: OptionListForBlockLookup = {};

  const duplicatedLists = [...optionLists]
    .map(optionList => {
      const newOptionListId = generateIdForEntity('FORMBUILDER_OPTION');
      const listForBlock = selectBlocks.find(
        ({ optionListId }) => optionListId === optionList.id,
      );

      if (!listForBlock) return null;

      optionListForBlockLookup[optionList.id] = {
        blockKey: listForBlock?.key,
        newOptionListId,
      };

      const clonedOptionList = clone(optionList);

      return {
        ...clonedOptionList,
        id: newOptionListId,
        options: clone(clonedOptionList.options).map(option => ({
          ...option,
          key: uuid(),
        })),
      };
    })
    .filter((x): x is FormBuilder_OptionList => !isNil(x));

  return {
    optionListForBlockLookup,
    updatedOptionLists: [...optionLists, ...duplicatedLists],
  };
};

export default getDuplicatedOptionLists;
