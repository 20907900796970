type Args = {
  mainToken: string | null;
  tokensToCompare: Array<string | null>;
};

const validateRealworksToken = ({
  mainToken,
  tokensToCompare,
}: Args): string | true => {
  // Check if main token is empty or null
  if (!mainToken || mainToken.trim() === '') {
    return 'Vul een waarde in';
  }

  // Filter out null values and empty strings from comparison tokens
  const validTokens = tokensToCompare.filter(
    (token): token is string => token !== null && token.trim() !== '',
  );

  // Check for duplicates with valid tokens
  const isDuplicate = validTokens.some(token => token === mainToken);
  if (isDuplicate) {
    return 'Kan niet hetzelfde zijn als een bestaande token';
  }

  return true;
};

export default validateRealworksToken;
