import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import { FlowAction } from '~/graphql/types';
import { Heading5, Variant } from '~/components/atom/Typography';
import getCardHeading from '../../../../utils/getCardHeading';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import { iconForTypeInHeader } from '~/util/taskTypeOptions';
import Divider from '~/components/atom/Divider';
import Icon from '~/components/atom/Icon';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TemplateString from '../TemplateString';
import { ClientFlowActionTaskCreate } from '~/graphql/types.client';
import IssuesForAction from '../IssuesForAction';

export type Props = Omit<
  CardProps<ClientFlowActionTaskCreate>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const text = {
  title: 'Titel',
  type: 'Type taak',
  description: 'Omschrijving',
  assignedToContactOwner: ' Aan de eigenaar van het contact toewijzen',
  office: 'Vestiging',
  user: 'Gebruiker',
  allUsers: 'Alle gebruikers',
};

const TaskActionCard: React.FCC<Props> = React.memo(
  ({ data, selected, disabled, ...props }) => {
    const { title, type, assignToContactOwner, assignedToOffice, user } =
      data.action;

    const officeName = useOffices({}).find(
      office => office.id === assignedToOffice,
    )?.name;

    const userName = useUsers().find(u => u.id === user)?.name ?? text.allUsers;

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.TaskCreate}
          heading={getCardHeading(FlowAction.TaskCreate)}
          data={data}
          selected={selected}
          disabled={disabled}
        >
          <JustificationContainer align="center">
            <StyledIcon>{iconForTypeInHeader(type)}</StyledIcon>
            <TemplateString templateString={title} actionId={data.action.id} />
          </JustificationContainer>
          <IssuesForAction
            actionId={data.action.id}
            margin={['s', null, null]}
          />
          <Divider margin={['base', null]} />
          {assignToContactOwner && (
            <AssignedToOwnerContainer>
              <Icon name="user" />
              {text.assignedToContactOwner}
            </AssignedToOwnerContainer>
          )}
          <JustificationContainer justification="space-between">
            <OfficeContainer>
              <Heading5 inline variant={Variant.primary}>
                {text.office}:&nbsp;
              </Heading5>
              {officeName}
            </OfficeContainer>
            <UserContainer>
              <Heading5 inline variant={Variant.primary}>
                {text.user}:&nbsp;
              </Heading5>
              {userName}
            </UserContainer>
          </JustificationContainer>
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const UserContainer = styled.div<{}>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const OfficeContainer = styled(UserContainer)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `,
);

const AssignedToOwnerContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

const StyledIcon = styled.div<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};

    svg {
      margin-top: ${theme.space('xxxs')};
      color: ${theme.color('success')};
      font-size: ${theme.fs('m')};
    }
  `,
);

export default TaskActionCard;
