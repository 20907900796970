import { DHEditor } from '../types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

export const inlineElements = [
  ELEMENTS.IMAGE,
  ELEMENTS.DH_IMAGE,
  ELEMENTS.VARIABLE,
  ELEMENTS.SPAN,
  ELEMENTS.LINK,
  ELEMENTS.DEEP_LINK,
];

const voidElements = [
  ELEMENTS.IMAGE,
  ELEMENTS.DH_IMAGE,
  ELEMENTS.VARIABLE,
  ELEMENTS.DEEP_LINK,
  ELEMENTS.IMMUTABLE_HTML_ELEMENT,
];
/** Self closing tags should be the same as in GenericHtmlElement/index */
const selfClosingTags = [
  'area',
  'hr',
  'col',
  'embed',
  'hr',
  'input',
  'param',
  'source',
  'track',
];

/**
 * A Slate editor plugin that enhances the editor to support inline, void, and markable void elements.
 * See: https://docs.slatejs.org/concepts/02-nodes#blocks-vs.-inlines

 * This plugin modifies the editor to treat certain elements as inline or void, and allows specific void elements
 * to be "markable", meaning text formatting marks (like bold or italic) can be applied to their text children.
 * 
 * If an element is void its content is not editable. Deleting one character at the end removes the whole element.
 * See: https://docs.slatejs.org/concepts/02-nodes#voids
 *
 * The plugin overrides the `isInline`, `isVoid`, and `markableVoid` methods of the editor:
 * - `isInline`: Determines if an element is an inline element.
 * - `isVoid`: Determines if an element is a void element.
 * - `markableVoid`: Determines if a void element can have marks applied to its text children.
 *
 * Inline elements are typically non-block elements that can be nested within block elements.
 * Void elements do not have editable content but can be selected and manipulated as a single unit.
 * Markable void elements are a special category of void elements that can have text formatting applied.
 * 
 * Check this list to decide if an element should be inline or not:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Inline_elements#list_of_inline_elements
 * 
 * @param {DHEditor} editor - The Slate editor instance to enhance.
 * @returns {DHEditor} The enhanced editor instance.
 */
const withInlineVoidElements = (editor: DHEditor): DHEditor => {
  const { isVoid, isInline, markableVoid } = editor;

  editor.isInline = el =>
    inlineElements.includes(el.type) ||
    (el.type === ELEMENTS.GENERIC_HTML_ELEMENT && inlines.includes(el.name)) ||
    isInline(el);

  editor.isVoid = el =>
    voidElements.includes(el.type) ||
    (el.type === ELEMENTS.GENERIC_HTML_ELEMENT &&
      selfClosingTags.includes(el.name)) ||
    isVoid(el);

  editor.markableVoid = el =>
    el.type === ELEMENTS.DEEP_LINK ||
    el.type === ELEMENTS.VARIABLE ||
    markableVoid(el);

  return editor;
};

export default withInlineVoidElements;

/** List made from https://developer.mozilla.org/en-US/docs/Web/HTML/Inline_elements */
export const inlines = [
  'a',
  'abbr',
  'acronym',
  'audio',
  'b',
  'bdi',
  'bdo',
  'big',
  'br',
  'button',
  'canvas',
  'cite',
  'code',
  'data',
  'datalist',
  'del',
  'dfn',
  'em',
  'embed',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'label',
  'map',
  'mark',
  'meter',
  'noscript',
  'object',
  'output',
  'picture',
  'progress',
  'q',
  'ruby',
  's',
  'samp',
  'script',
  'select',
  'slot',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'svg',
  'template',
  'textarea',
  'time',
  'u',
  'tt',
  'var',
  'video',
  'wbr',
];
