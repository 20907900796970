import type { ClientFlowAction } from '~/graphql/types.client';
import getIssuesForAction, {
  type IssueOptions,
} from '../../../UpdateAction/utils/getIssuesForAction';
import getUpToDateRelativeMaps from '../getUpToDateRelativeMaps';
import type { RelativeMaps } from '../../../../types';
import type { FlowData___ActionFragment } from '~/graphql/types';
import type { DeepLinksMap } from '../../../../state/deepLinks';
import type { Issue } from '../../../../state/flowIssues';

/**
 * Generates an issues map by iterating over each action and validating it
 */
const generateIssuesForActions = ({
  actions,
  maps,
  opts,
  availableActions,
  deepLinksMap,
}: {
  actions: Array<ClientFlowAction>;
  maps: RelativeMaps;
  opts: IssueOptions;
  availableActions: Array<FlowData___ActionFragment>;
  deepLinksMap: DeepLinksMap;
}) => {
  const allIssues = new Map<string, Array<Issue>>();

  actions.forEach(action => {
    const updatedRelativeMaps = getUpToDateRelativeMaps({
      action,
      actions,
      maps,
    });

    const issuesForAction = getIssuesForAction({
      action,
      relativeMaps: updatedRelativeMaps,
      availableActions,
      opts,
      deepLinksMap,
    });

    allIssues.set(action.id, issuesForAction);
  });

  return allIssues;
};

export default generateIssuesForActions;
