import React from 'react';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import TEST_ID from './index.testid';

const text = {
  skipLabel: 'Stap overslaan',
  nextLabel: 'API instellen',
};

type Props = {
  disabled?: boolean;
  onCancel?: () => void;
  onSkip?: () => void;
};

const StepNavigationFooter: React.FCC<Props> = ({
  onCancel,
  disabled,
  onSkip,
}) => (
  <JustificationContainer
    justification="space-between"
    align="center"
    margin={['l', null, null]}
  >
    <TextButton
      label="Afbreken"
      onClick={onCancel}
      padding={[null]}
      appearance="danger"
      type="button"
      dataTestId="exit-wizard-button"
    />

    <JustificationContainer justification="start" align="center">
      {onSkip && (
        <TextButton
          appearance="primary"
          label={text.skipLabel}
          onClick={onSkip}
          dataTestId={TEST_ID.SKIP_BUTTON}
        />
      )}
      <Button
        icon="check"
        label={text.nextLabel}
        type="submit"
        size="medium"
        disabled={disabled}
        dataTestId={TEST_ID.NEXT_BUTTON}
      />
    </JustificationContainer>
  </JustificationContainer>
);
export default StepNavigationFooter;
