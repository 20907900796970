import React from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'ramda';
import { navigate, useLocation, WindowLocation } from '@gatsbyjs/reach-router';
import {
  RealworksTokenStatus,
  RealworksTokenType,
  UpdateRealworksAppStatus_AddTokenContainer__Input,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Body } from '~/components/atom/Typography';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import { StepProps } from '../..';
import TEST_ID from './index.testid';

import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { BlockContainer } from '../../../TokenContainer';
import APIBlock from '~/components/organism/APIBlock';
import useOffice from '~/hooks/useOffice';
import useUser from '~/hooks/useUser';
import { isNonEmptyString } from '~/util/Validation/String';
import isDuplicatedToken from '~/components/page/Apps/Realworks/utils/isDuplicatedToken';
import getRealworksAPIConfigs from '~/components/page/Apps/Realworks/utils/getRealworksAPIConfigs';
import apiStatusToDescription from '~/components/page/Apps/Realworks/utils/apiStatusToDescription';
import apiStatusToVariant from '~/components/page/Apps/Realworks/utils/apiStatusToVariant';

export type Props = StepProps & {
  refreshAppStatus: () => void;
};

export const text = {
  toastErrorMessage:
    'Er is iets fout gegaan bij het toevoegen van de tokens, sluit de wizard en begin opnieuw.',
  toastSuccessMessage: 'Koppelingen succesvol toegevoegd.',
  body: 'Gefeliciteerd! De koppeling is ingesteld en wordt door ons verwerkt. Dit kan enkele uren tot een dag duren. Zodra de koppeling is geactiveerd ontvang je een e-mail.',
  warning:
    'Let op: Gebruik je de Relaties API om contacten te importeren? Vergeet dan niet in te stellen welke relatiekenmerken worden gesynchroniseerd.',
  subheader: 'Gekoppelde APIs',
  duplicateErrorMessage:
    'Deze token is al in gebruik. Probeer een andere token.',
};

const ActivationPendingStep: React.FCC<Props> = ({
  onCancel,
  refreshAppStatus,
}) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();

  const [updateRealworksAppStatus, { loading }] =
    useUpdateRealworksAppStatusMutation({});

  // @ts-ignore WindowLocation<S = unknown>. S is the type of state but we cannot type it ourselves because the types come from types/reach__router
  const location: WindowLocation<{
    values: UpdateRealworksAppStatus_AddTokenContainer__Input;
  }> = useLocation();

  const locationValues = location?.state?.values || {};
  const onComplete = () => {
    if (isEmpty(locationValues)) return;

    return updateRealworksAppStatus({
      variables: { accountId, addTokenContainer: locationValues },
    }).then(({ errors }) => {
      if (errors && errors.length !== 0) {
        if (isDuplicatedToken(errors)) {
          addToast([formatToastMessage(text.duplicateErrorMessage, 'danger')]);
          return;
        } else {
          addToast([formatToastMessage(text.toastErrorMessage, 'danger')]);
          return;
        }
      }

      void navigate(`/-/apps/realworks`, {
        replace: true,
      })
        .then(() => {
          addToast([formatToastMessage(text.toastSuccessMessage, 'success')]);
        })
        .then(() => refreshAppStatus());
    });
  };

  const relatieTokenStatus = isNonEmptyString(locationValues.relatie)
    ? RealworksTokenStatus.Pending
    : 'skipped';
  // const wonenTokenStatus = isNonEmptyString(locationValues.wonen)
  //   ? RealworksTokenStatus.Pending
  //   : 'skipped';
  const agendaTokenStatus = isNonEmptyString(locationValues.agenda)
    ? RealworksTokenStatus.Pending
    : 'skipped';

  const office = useOffice(locationValues.officeId);
  const user = useUser(locationValues.userId);

  const officeText = office ? `Vestiging ${office.name}` : '';
  const userText =
    user && user.__typename === 'User' ? `/ ${user.name}` : '/ Alle gebruikers';

  return (
    <AppDetailsContainer
      header="Activeren"
      icon="star"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>{text.body}</Body>
      <StyledAppEntryItemHeader>{text.warning}</StyledAppEntryItemHeader>

      <br />
      <AppEntryItemHeader dataTestId={TEST_ID.OFFICE_USER_CONTAINER}>
        {officeText} {userText}
      </AppEntryItemHeader>

      <SubHeader>{text.subheader}</SubHeader>

      <BlockContainer>
        {getRealworksAPIConfigs({
          agenda: { token: locationValues.agenda, status: agendaTokenStatus },
          relatie: {
            token: locationValues.relatie,
            status: relatieTokenStatus,
          },
          // wonen: { token: locationValues.wonen, status: wonenTokenStatus },
        })
          .filter(item => item.tokenType !== RealworksTokenType.Wonen)
          .map(config => (
            <APIBlock
              key={config.tokenType}
              heading={config.heading}
              dataTestId={config.dataTestId}
              description={apiStatusToDescription(config.value?.status)}
              variant={apiStatusToVariant(config.value?.status)}
              token={config.value?.token ?? undefined}
            />
          ))}
      </BlockContainer>

      <JustificationContainer
        justification="space-between"
        align="center"
        margin={['l', null, null]}
      >
        <TextButton
          label="Afbreken"
          onClick={onCancel}
          padding={[null]}
          appearance="danger"
          type="button"
          dataTestId="exit-wizard-button"
        />
        <Button
          icon="check"
          label="Voltooien"
          onClick={onComplete}
          size="medium"
          type="button"
          loading={loading}
          dataTestId={TEST_ID.COMPLETE_BUTTON}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

const SubHeader = styled.div<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    margin-bottom: ${theme.space('base')};
  `,
);

const StyledAppEntryItemHeader = styled(AppEntryItemHeader)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: 0;
  `,
);

export default ActivationPendingStep;
