import React from 'react';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import { Handle, Position } from 'reactflow';
import { Heading5, Variant } from '~/components/atom/Typography';
import CardStatistics, { Units } from '../CardStatistics';
import Divider from '~/components/atom/Divider';
import { Props as HidableListProps } from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { FlowAction } from '~/graphql/types';
import getCardHeading from '../../../../utils/getCardHeading';
import TemplateString from '../TemplateString';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { useTheme } from 'styled-components';
import { ClientFlowActionSendEmailPlain } from '~/graphql/types.client';
import IssuesForAction from '../IssuesForAction';

export type Props = Omit<
  CardProps<ClientFlowActionSendEmailPlain>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
} & Omit<HidableListProps, 'dropdownListOpen' | 'onClickOutside'>;

const text = {
  subject: 'Onderwerp',
  opened: 'Geopend',
  sent: 'Verzonden',
  clicked: 'Geklikt',
};

const EmailActionCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const theme = useTheme();
    const chartColors = theme.getTokens().colors.chart;

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.SendEmailPlain}
          heading={getCardHeading(FlowAction.SendEmailPlain)}
          selected={selected}
          disabled={disabled}
          data={data}
          dataTestId="EmailActionCard"
        >
          <div>
            <JustificationContainer justification="start" align="center">
              <Heading5 inline variant={Variant.primary} margin={[null]}>
                {text.subject}:&nbsp;
              </Heading5>
              <TemplateString
                templateString={data.action.subject}
                actionId={data.action.id}
              />
            </JustificationContainer>

            <IssuesForAction actionId={data.action.id} />
          </div>
          {data.action.Statistics && (
            <>
              <Divider />
              <CardStatistics
                statistics={[
                  {
                    color: chartColors[0],
                    heading: text.sent,
                    total: data.action.Statistics.sentTotal,
                    value: 0,
                    unit: Units.Times,
                  },
                  {
                    color: chartColors[1],
                    heading: text.opened,
                    total: data.action.Statistics.sentTotal,
                    value: data.action.Statistics.openedTotal,
                    unit: Units.Percent,
                  },
                  {
                    color: chartColors[2],
                    heading: text.clicked,
                    total: data.action.Statistics.sentTotal,
                    value: data.action.Statistics.clickedTotal,
                    unit: Units.Percent,
                  },
                ]}
              />
            </>
          )}
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

export default EmailActionCard;
