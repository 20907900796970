import React, { useState } from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import {
  useUpdateRealworksAppStatusMutation,
  type AppStatus_Realworks_RealworksTokenAgenda_AgendaSync,
  type UpdateRealworksAppStatus_UpdateTokenContainerAgendaSync__Input,
} from '~/graphql/types';
import { Body, Variant, Label } from '~/components/atom/Typography';
import JustificationContainer, {
  type Props as JustificationContainerProps,
} from '~/components/atom/JustificationContainer';
import Divider from '~/components/atom/Divider';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Button from '~/components/atom/Button';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import removeTypenames from '~/util/removeTypenames';
import type { RealworksTokenWithStatus } from '../../../../utils/getRealworksAPIConfigs';
import styled from 'styled-components';
import Tabs from '~/components/molecule/Tabs';
import TabBody from '~/components/molecule/Tabs/components/TabBody';
import { equals } from 'ramda';
import Catalog from '~/Catalog';
import SlideComponent from '~/components/atom/SlideComponent';

const text = {
  header: 'Agenda instellingen',
  buttons: {
    save: 'Opslaan',
    cancel: 'Annuleren',
  },
  toasts: {
    error: 'Er is iets mis gegaan bij het opslaan, probeer het later opnieuw',
    success: Catalog.genericSuccessMessage,
  },
  agendaStatusHeader: 'Agenda statuses',
  agendaTypesHeader: 'Agenda types',
  explanation:
    'Stel hier in welke agenda-statussen en -types als geannuleerde afspraken moeten worden gemarkeerd. Deze informatie kan in flows worden toegepast om acties te starten of stoppen.',
  markAsCancelled: 'Markeer als geannuleerd',
  enabled: 'Synchronisatie actief',
  disabled: 'Synchronisatie niet actief',
};

type Props = {
  /** Token id to update */
  tokenContainerId: string;

  /** Agenda token */
  agenda: RealworksTokenWithStatus;

  /** Agenda sync settings */
  agendaSync: AppStatus_Realworks_RealworksTokenAgenda_AgendaSync;

  /** Closes the modal */
  onClose: () => void;
};

const AgendaSync: React.FCC<Props> = ({
  agenda,
  agendaSync,
  tokenContainerId,
  onClose,
}) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();

  const initialValues = {
    enabled: agendaSync?.enabled ?? false,
    typesList: agendaSync?.typesList ?? [],
    statusList: agendaSync?.statusList ?? [],
  };

  const [agendaSyncState, setAgendaSyncState] =
    useState<UpdateRealworksAppStatus_UpdateTokenContainerAgendaSync__Input>(
      initialValues,
    );

  const [updateRealworksAppStatus, { loading }] =
    useUpdateRealworksAppStatusMutation({});

  const onUpdateToken = () => {
    void updateRealworksAppStatus({
      variables: {
        accountId,
        updateTokenContainer: {
          tokenContainerId: tokenContainerId,
          agendaSync: removeTypenames(agendaSyncState),
        },
      },
    }).then(({ data, errors }) => {
      if (errors) {
        return addToast([formatToastMessage(text.toasts.error, 'danger')]);
      }
      if (data) {
        addToast([formatToastMessage(text.toasts.success, 'success')]);
        onClose();
      }
    });
  };

  const hasChanges = !equals(agendaSyncState, initialValues);
  const showTabs =
    agendaSyncState.enabled &&
    (agendaSyncState.typesList.length > 0 ||
      agendaSyncState.statusList.length > 0);

  const rowProps: JustificationContainerProps = {
    width: '100%',
    justification: 'space-between',
    align: 'center',
    padding: ['xxs'],
  };

  return (
    <AppDetailsContainer icon="calendar" header={text.header} loading={false}>
      <JustificationContainer
        justification="space-between"
        align="center"
        width="100%"
      >
        <JustificationContainer direction="column" margin={['base', null]}>
          <Label variant={Variant.primary} size="base" margin={[null]}>
            Token
          </Label>
          <Body size="base">{agenda.token}</Body>
        </JustificationContainer>
        <ToggleCheckbox
          value={agendaSyncState.enabled}
          onChange={() => {
            setAgendaSyncState(prev => ({
              ...prev,
              enabled: !prev.enabled,
            }));
          }}
          label={agendaSyncState.enabled ? text.enabled : text.disabled}
          containerProps={{
            gap: 'xxs',
            justification: 'end',
            align: 'center',
            width: '50%',
          }}
        />
      </JustificationContainer>

      <SlideComponent isVisible={showTabs} direction="top">
        <Body>{text.explanation}</Body>
        <Tabs>
          {[
            <TabBody label={text.agendaStatusHeader} key="agendaSync-statuses">
              {agendaSyncState.statusList.length > 0 && (
                <>
                  <MarkAsCancelledLabel />
                  {agendaSyncState.statusList.map((status, index) => (
                    <>
                      <RowContainer {...rowProps} key={status.status}>
                        <span>{status.status}</span>
                        <ToggleCheckbox
                          value={status.markAsCancelled}
                          size="small"
                          onChange={() => {
                            setAgendaSyncState(prev => ({
                              ...prev,
                              statusList: prev.statusList.map(s =>
                                s.status === status.status
                                  ? {
                                      ...s,
                                      markAsCancelled: !s.markAsCancelled,
                                    }
                                  : s,
                              ),
                            }));
                          }}
                        />
                      </RowContainer>
                      {index !== agendaSyncState.statusList.length - 1 && (
                        <Divider margin={[null]} />
                      )}
                    </>
                  ))}
                </>
              )}
            </TabBody>,
            <TabBody label={text.agendaTypesHeader} key="agendaSync-types">
              {agendaSyncState.typesList.length > 0 && (
                <>
                  <MarkAsCancelledLabel />
                  {agendaSyncState.typesList.map((type, index) => (
                    <>
                      <RowContainer {...rowProps} key={type.type}>
                        <span>{type.type}</span>
                        <ToggleCheckbox
                          value={type.markAsCancelled}
                          size="small"
                          onChange={() => {
                            setAgendaSyncState(prev => ({
                              ...prev,
                              typesList: prev.typesList.map(t =>
                                t.type === type.type
                                  ? {
                                      ...t,
                                      markAsCancelled: !t.markAsCancelled,
                                    }
                                  : t,
                              ),
                            }));
                          }}
                        />
                      </RowContainer>
                      {index !== agendaSyncState.typesList.length - 1 && (
                        <Divider margin={[null]} />
                      )}
                    </>
                  ))}
                </>
              )}
            </TabBody>,
          ]}
        </Tabs>
      </SlideComponent>

      <JustificationContainer
        width="100%"
        justification="end"
        align="center"
        gap="base"
        margin={['l', null, null]}
      >
        <Button label={text.buttons.cancel} onClick={onClose} ghost />
        <Button
          appearance="secondary"
          label={text.buttons.save}
          loading={loading}
          disabled={!hasChanges}
          onClick={onUpdateToken}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

const MarkAsCancelledLabel: React.FCC = () => (
  <JustificationContainer width="100%" justification="end">
    <Label margin={[null]}>{text.markAsCancelled}</Label>
  </JustificationContainer>
);

const RowContainer = styled(JustificationContainer)`
  &:hover {
    background-color: ${({ theme }) => theme.color('tertiary', 'translucent')};
  }
`;

export default AgendaSync;
