import { useCallback, useMemo } from 'react';
import { Resetter, useResetRecoilState } from 'recoil';
import addBlockMenu from '~/components/page/Automation/v2/state/addBlockMenu';
import flowSettings from '~/components/page/Automation/v2/state/flowSettings';
import interactions from '~/components/page/Automation/v2/state/interactions';
import {
  lastAddedActionId,
  flowActions,
} from '~/components/page/Automation/v2/state/actions';
import lastDeletedActionNextChildId from '~/components/page/Automation/v2/state/lastDeletedActionNextChildId';
import metadata from '~/components/page/Automation/v2/state/metadata';
import dialogs from '../../../../state/dialogs';
import activeSelectorState from '../../../../state/conditionEditor';
import { deepLinkConfigsState } from '../../../../state/deepLinks';

/**
 * Resets all recoil atoms related to flow state
 */
const useResetFlowState = (): Resetter => {
  const resetFlowActions = useResetRecoilState(flowActions);
  const resetFlowSettings = useResetRecoilState(flowSettings);
  const resetInteraction = useResetRecoilState(interactions);
  const resetDialog = useResetRecoilState(dialogs);
  const resetAddBlockMenu = useResetRecoilState(addBlockMenu);
  const resetFlowMetadata = useResetRecoilState(metadata);
  const resetLastAddedAction = useResetRecoilState(lastAddedActionId);
  const resetLastDeletedActionNextChildId = useResetRecoilState(
    lastDeletedActionNextChildId,
  );
  const resetDeepLinkConfigs = useResetRecoilState(deepLinkConfigsState);
  const resetConditionActiveSelector = useResetRecoilState(activeSelectorState);

  const resetFns = useMemo(
    () => [
      resetFlowSettings,
      resetInteraction,
      resetDialog,
      resetAddBlockMenu,
      resetFlowMetadata,
      resetFlowActions,
      resetLastAddedAction,
      resetLastDeletedActionNextChildId,
      resetDeepLinkConfigs,
      resetConditionActiveSelector,
    ],
    [
      resetFlowSettings,
      resetInteraction,
      resetDialog,
      resetAddBlockMenu,
      resetFlowMetadata,
      resetFlowActions,
      resetLastAddedAction,
      resetLastDeletedActionNextChildId,
      resetDeepLinkConfigs,
      resetConditionActiveSelector,
    ],
  );

  const resetFlowState = useCallback(() => {
    resetFns.forEach(fn => fn());
  }, [resetFns]);

  return resetFlowState;
};

export default useResetFlowState;
