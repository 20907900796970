import React, { useState } from 'react';
import SelectorComp from '~/components/organism/Selector';
import { Crumb } from '../../../Breadcrumbs';
import TEST_ID from './index.testid';
import { actionById } from '~/components/page/Automation/v2/state/actions';
import { useRecoilValue } from 'recoil';
import useLinksFromSendEmailAction from '~/components/page/Automation/v2/components/Builder/hooks/useLinksFromSendEmailAction';
import { ListItem } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector';
import type { SelectorContainerProps } from '../..';
import Validation from '~/util/Validation';
import type { ClientFlowActionSendEmailPlain } from '~/graphql/types.client';
import type { Flow___InstanceConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/types.ts';

export const linkSelectorText = {
  emptyBreadcrumbLabel: 'Selecteer een link',
  anyLink: 'Elke link',
};

const LinkSelector: React.FCC<
  Omit<SelectorContainerProps, 'condition'> & {
    action: ClientFlowActionSendEmailPlain;
  }
> = ({
  onClose,
  action,
  onSelect,
  pointerLocation,
  pointerOffset,
  selectorRef,
  ...rest
}) => {
  const [currentPath, setCurrentPath] = useState([]);

  const links = useLinksFromSendEmailAction(action);

  const isDeepLink = (url: string) => Validation.DeepLinkId.isValid(url);

  const linkOptions = [
    <ListItem
      key={'emptylink'}
      onClick={e => {
        e.stopPropagation();
        onSelect({
          __typename: 'Flow___Argument_String',
          value_string: null,
        });
      }}
      data-testid={TEST_ID.LIST_ITEM}
      data-objectid={`emptylink`}
    >
      {linkSelectorText.anyLink}
    </ListItem>,
    ...links.map((link, index) => (
      <ListItem
        key={index}
        onClick={e => {
          e.stopPropagation();
          onSelect({
            __typename: 'Flow___Argument_String',
            value_string: link.url,
          });
        }}
        data-testid={TEST_ID.LIST_ITEM}
        data-objectid={`${link.url}`}
      >
        {link.text} {isDeepLink(link.url) ? '' : <>({link.url})</>}
      </ListItem>
    )),
  ];

  return (
    <SelectorComp
      emptyBreadcrumbLabel={linkSelectorText.emptyBreadcrumbLabel}
      pointerPosition={{
        location: pointerLocation,
        offset: pointerOffset,
      }}
      actions={[
        {
          key: 'close',
          icon: 'close',
          appearance: 'icon',
          onClick: onClose,
        },
      ]}
      crumbs={[
        {
          label: '...',
          key: 'root',
        },
      ]}
      onBreadcrumbClick={(crumb: Crumb, index: number) => {
        if (crumb.key === 'root') {
          return setCurrentPath([]);
        }

        return setCurrentPath(currentPath.slice(0, index));
      }}
      dataTestId={TEST_ID.CONTAINER}
      ref={selectorRef}
      {...rest}
    >
      {linkOptions}
    </SelectorComp>
  );
};

const LinkSelectorFacade: React.FCC<
  Omit<SelectorContainerProps, 'condition'> & {
    condition: Flow___InstanceConditionPartial;
  }
> = props => {
  const [actionId] = props.condition.input.path;
  const action = useRecoilValue(actionById(actionId));

  if (action?.__typename !== 'FlowV2_Action_SendEmail_Plain') {
    return null;
  }

  return <LinkSelector {...props} action={action} />;
};

export default LinkSelectorFacade;
