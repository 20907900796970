import { navigate } from '@gatsbyjs/reach-router';
import { isNil, pluck } from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { useUpdateAppStatusMutation } from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';
import useWizard from '~/hooks/useWizard';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import AppTypeCosts from './components/AppTypeCosts';
import getUpdateForAppStatus from './utils/getUpdateForAppStatus';
import OutputColorGrid from './components/OutputColorGrid';
import OutputImageGrid from './components/OutputImageGrid';
import OutputItem from './components/OutputItem';
import OutputList from './components/OutputList';
import useApp from '~/hooks/useApp';
import ErrorScreen from '~/components/page/ErrorScreen';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import getEnablementFlow from '~/components/organism/Wizard/utils/getEnablementFlow';
import getOutputsForOverview from '../../../utils/getOutputsForOverview';
import useSessionHydration from '~/hooks/useSessionHydration';
import usePermissions from '~/hooks/usePermissions';
import cleanedFilename from '~/util/cleanedFilename';

const text = {
  title: 'Overzicht',
};

export const id = 'AppStatusOverview';
export const title = text.title;

export type OutputType = {
  type: typeof id;
  appStatus?: OutputFieldInvisible<AppStatus>;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const addToast = useAddToast();
  const wizardAPI = useWizard();
  const reporter = useErrorReporter();
  const flowsPermission = usePermissions(['root.automation']);
  const { id: accountId } = useCurrentAccount();
  const { app, loading } = useApp(step.metadata?.typename);
  const [, refetchSessionHydration] = useSessionHydration();

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const onBeforeNext = useCallback(
    async outputMap => {
      const currentOutput: OutputType = outputMap[id];
      if (step.metadata?.typename) {
        if (!currentOutput.appStatus || isNil(currentOutput.appStatus.value)) {
          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | currentOutput.appStatus?.value is null or undefined`,
            ),
          );
          return outputMap[id];
        }

        const update = getUpdateForAppStatus({
          completed: true,
          currentAppStatus: currentOutput.appStatus.value,
        });

        await updateAppStatus({
          variables: {
            accountId,
            update,
          },
        }).catch(err => {
          reporter.captureException(err);
          addToast([
            formatToastMessage(
              `Er is iets mis gegaan bij het aanzetten van de app. Probeer het later opnieuw.`,
              'danger',
            ),
          ]);
        });
        if (app) {
          const appPath = app.type === 'with_config' ? app.slug : null;

          if (appPath) {
            // We don't want Trustoo users to land inside the app when they finish the wizard
            if (appPath !== 'trustoo') {
              void navigate(`/-/apps/${appPath}`);
            }
          }

          // Clear indexedDB states and the output when wizard is completed
          const enablementFlow = getEnablementFlow(
            app,
            flowsPermission.allowed,
          );
          if (enablementFlow) {
            await wizardAPI.clear(enablementFlow.id);
          }
        }

        await refetchSessionHydration();

        return outputMap[id];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, app, step.metadata?.typename],
  );

  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);

  const [, api] = useWizardStep(step.id, stepOptions);

  const stepsInWizard = wizardAPI.getSteps();
  const stepIDs = pluck('id', stepsInWizard);

  const { images, lists, colors, items } = getOutputsForOverview(
    outputMap,
    stepIDs,
  );

  useEffect(() => {
    api.lockGoBack();
    if (app?.appStatus) {
      api.free({
        type: id,
        appStatus: {
          type: 'invisible',
          value: app.appStatus,
        },
      });
    } else {
      api.lock({ type: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.appStatus]);

  if (loading) return <DatHuisLoading />;
  if (!app) {
    return <ErrorScreen />;
  }
  return (
    <JustificationContainer gap="m" direction="column">
      {step.metadata?.typename && (
        <AppTypeCosts
          typename={step.metadata.typename}
          outputMap={outputMap}
          stepIDs={stepIDs}
        />
      )}
      {images.map((grid, index) => (
        <OutputImageGrid key={index} {...grid} />
      ))}
      {colors.map((grid, index) => (
        <OutputColorGrid key={index} {...grid} />
      ))}
      {items.map((grid, index) => (
        <OutputItem key={index} {...grid} />
      ))}
      {lists.map((grid, index) => (
        <OutputList key={index} {...grid} />
      ))}
    </JustificationContainer>
  );
};

export default {
  id,
  title,
};
