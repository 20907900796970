import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  ADD_RELATIE: `${cleanedFilename(__filename)}-add-relatie`,
  ADD_WONEN: `${cleanedFilename(__filename)}-add-wonen`,
  ADD_AGENDA: `${cleanedFilename(__filename)}-add-agenda`,
  DELETE_CONTAINER: `${cleanedFilename(__filename)}-delete-container`,
  NAME: `${cleanedFilename(__filename)}-name`,
};
