import { useRecoilValue } from 'recoil';
import flowIssues, { type Issue } from '../../../../state/flowIssues';
import useBuilderContext from '../useBuilderContext';
import { flowSettingsIssues } from '../../../../state/flowSettings';

/**
 *
 * @returns all issues in flow both for actions and settings
 */
const useIssuesInFlow = (): {
  hasIssues: boolean;
  allIssues: Array<Issue>;
} => {
  const builderContext = useBuilderContext();
  const actionIssues = useRecoilValue(flowIssues(builderContext));
  const settingsIssues = useRecoilValue(flowSettingsIssues);
  const issues = new Map([...actionIssues, ...settingsIssues]);

  return {
    hasIssues: Array.from(issues.values()).flat().length > 0,
    allIssues: Array.from(issues.values()).flat(),
  };
};

export default useIssuesInFlow;
