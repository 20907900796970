import type { FlowV2_TemplateStringFragment } from '~/graphql/types';
import stripHtml from '~/util/stripHtml';

/**
 * Serializes a template string by removing HTML tags while preserving text content and variables
 * @param {FlowV2_TemplateString} templateString - Template string object containing HTML content
 * @returns {FlowV2_TemplateString} Cleaned template string object with HTML removed
 * keywords: template string, HTML cleanup, variable preservation
 */
const serializeStringInput = (
  templateString: FlowV2_TemplateStringFragment,
): FlowV2_TemplateStringFragment => {
  const { template } = templateString;

  return {
    ...templateString,
    template: template ? stripHtml(template) : null,
  };
};

export default serializeStringInput;
