import { DayOfWeek, Trickle_PeriodType } from '~/graphql/types';
import type { ClientFlowActionTrickle } from '~/graphql/types.client';

export const DEFAULT_TRICKLE_RULES: ClientFlowActionTrickle['rules'] = [
  {
    __typename: 'FlowV2_Action_Trickle_ScheduleRule',
    daysOfWeek: [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
    ],
    timeRanges: [
      {
        __typename: 'FlowV2_Action_Trickle_TimeRange',
        start: {
          __typename: 'FlowV2_Action_Trickle_TimeRange_Time',
          hour: 9,
          minute: 0,
        },
        end: {
          __typename: 'FlowV2_Action_Trickle_TimeRange_Time',
          hour: 17,
          minute: 30,
        },
      },
    ],
  },
];

export const DEFAULT_TRICKLE_LIMITS: ClientFlowActionTrickle['limits'] = [
  {
    amount: '1000',
    period: {
      type: Trickle_PeriodType.Days,
      value: '1',
    },
  },
];

export const PERIOD_OPTIONS = [
  {
    key: Trickle_PeriodType.Days,
    label: 'Dagen',
    payload: { value: Trickle_PeriodType.Days },
  },
  {
    key: Trickle_PeriodType.Hours,
    label: 'Uren',
    payload: { value: Trickle_PeriodType.Hours },
  },
];

export const DAYS_OF_WEEK_OPTIONS = {
  Mo: { label: 'Ma', selected: false, key: DayOfWeek.Monday },
  Tu: { label: 'Di', selected: false, key: DayOfWeek.Tuesday },
  We: { label: 'Wo', selected: false, key: DayOfWeek.Wednesday },
  Th: { label: 'Do', selected: false, key: DayOfWeek.Thursday },
  Fr: { label: 'Vr', selected: false, key: DayOfWeek.Friday },
  Sa: { label: 'Za', selected: false, key: DayOfWeek.Saturday },
  Su: { label: 'Zo', selected: false, key: DayOfWeek.Sunday },
};

export const VALIDATION_FOR_LIMIT_FIELDS = {
  contacts: {
    maxAmount: 100000,
    message: 'Waarde mag niet groter zijn dan 100.000',
  },
  days: {
    maxAmount: 365,
    message: 'Waarde mag niet groter zijn dan 365',
  },
  hours: {
    maxAmount: 48,
    message: 'Waarde mag niet groter zijn dan 48',
  },
};
