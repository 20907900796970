import React, { useState } from 'react';
import styled from 'styled-components';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import {
  ContactFilters__Input,
  ImportContactsFileFormat,
  ImportContactsV2MutationVariables,
  useImportContactsV2Mutation,
} from '~/graphql/types';
import ActionsContainer from './components/ActionsContainer';
import Button from '~/components/atom/Button';
import Dropdown, { OptionOf } from '~/components/molecule/Dropdown';
import TEST_ID from './index.testid';
import StartFlowDropdown from './components/StartFlowDropdown';
import ImportContactsModal from './components/ImportContactsModal';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import { FileType } from '~/util/constants';
import usePermissions from '~/hooks/usePermissions';
import AddContactModal from '../../AddContactModal';

export type WorkflowStepType =
  | 'MULTIPLE_CONTACTS'
  | 'MULTIPLE_CONTACTS_SUCCESS';

export type Props = {
  /** Needed for importing contacts */
  contactsMeta: { accountId: string; officeId: string; userId: string };
  onActionCompleted?: () => void;
  /** Function to refetch getContacts query and set version of Contacts table */
  onRefresh: () => void;
  /** Function to call when a delete action has finished */
  onDeleteContact: () => void;
  /** Function to call when add tags action has finished */
  onAddTags: () => void;
  /** Function to call when a change is made in the search field */
  onFilterChange: (text: string) => void;
  /** query and nodes fields returned from getContactsV2 query */
  queryVariables: {
    query?: string;
    filters: ContactFilters__Input;
  };
  /** Array of contact id's that are selected  */
  selectedContacts: Array<string>;
  allSelected: boolean;
  selectedContactAmount: number;
};

// You can find mime here - https://learn.microsoft.com/en-us/previous-versions/office/office-2007-resource-kit/ee309278%28v=office.12%29
const XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const XLS = 'application/vnd.ms-excel';

export const text = {
  searchInputPlaceholder: 'Naam, adres, plaats...',
  title: 'Contacten',
  addContact: 'Nieuw contact',
  importContacts: 'Importeer contacten',
  showThisContact: 'Toon dit contact',
  contactCreatedToastTemplate: (name: string): string =>
    `Nieuw contact "${name}" toegevoegd!`,
  loadingFlows: 'Flows laden...',
};

const importContactsOptions: Array<OptionOf<ImportContactsFileFormat>> = [
  {
    label: 'Realworks - xls',
    payload: ImportContactsFileFormat.Realworks,
    key: ImportContactsFileFormat.Realworks,
  },
  {
    label: 'DatHuis - csv',
    payload: ImportContactsFileFormat.Csv,
    key: ImportContactsFileFormat.Csv,
  },
  {
    label: 'Accelerate - xlsx',
    payload: ImportContactsFileFormat.Accelerate,
    key: ImportContactsFileFormat.Accelerate,
  },
  {
    label: 'Elements - xlsx',
    payload: ImportContactsFileFormat.Elements,
    key: ImportContactsFileFormat.Elements,
  },
  {
    label: 'Kolibri - xlsx',
    payload: ImportContactsFileFormat.Kolibri,
    key: ImportContactsFileFormat.Kolibri,
  },
];

const allowedFileExtenstions: Partial<
  Record<ImportContactsFileFormat, Array<FileType>>
> = {
  [ImportContactsFileFormat.Accelerate]: [XLSX],
  [ImportContactsFileFormat.Csv]: ['text/csv'],
  [ImportContactsFileFormat.Realworks]: [XLSX, XLS],
  [ImportContactsFileFormat.Elements]: [XLSX],
  [ImportContactsFileFormat.Kolibri]: [XLSX],
};

const ContactsToolBar: React.FCC<Props> = ({
  dataTestId,
  contactsMeta,
  onRefresh,
  onFilterChange,
  onAddTags,
  onDeleteContact,
  onActionCompleted,
  ...rest
}) => {
  const addToast = useAddToast();
  const hasFlows = usePermissions(['root.automation']);
  const [showAddContactModal, setShowModal] = useState(false);

  const [showImportContactsModal, setShowImportContactsModal] =
    useState<boolean>(false);

  const [importType, setImportType] = useState<ImportContactsFileFormat | null>(
    null,
  );

  const [workflowStep, setWorkflowStep] =
    useState<WorkflowStepType>('MULTIPLE_CONTACTS');

  const onAddMore = () => setWorkflowStep('MULTIPLE_CONTACTS');

  const onSuccessfulImport = () => {
    setWorkflowStep('MULTIPLE_CONTACTS_SUCCESS');
    setTimeout(() => onRefresh(), 2000);
  };

  const [importContacts, { loading }] = useImportContactsV2Mutation();

  const onImport = async (variables: ImportContactsV2MutationVariables) => {
    const result = await importContacts({
      variables,
    });

    if (!result || !result.data || !result.data.importContactsV2)
      return {
        errors: null,
        success: false,
      };

    if (
      result?.data?.importContactsV2.errors &&
      result?.data?.importContactsV2.errors.length > 0
    )
      return {
        errors: result?.data?.importContactsV2.errors,
        success: false,
      };

    onSuccessfulImport();

    return {
      errors: null,
      success: true,
    };
  };

  return (
    <>
      {showAddContactModal && (
        <AddContactModal
          onClose={() => setShowModal(false)}
          onComplete={({ insertContact }) => {
            addToast([
              formatToastMessage(
                text.contactCreatedToastTemplate(insertContact.name),
                'success',
                {
                  to: `/-/contacts/${insertContact.id}`,
                  label: text.showThisContact,
                },
              ),
            ]);
            setShowModal(false);
            // Refetching contacts delayed because it may take a while until backend returns the newly added contact
            setTimeout(() => onRefresh(), 3000);
          }}
          contactsMeta={contactsMeta}
        />
      )}
      <Container data-testid={dataTestId}>
        <OverviewListHeader
          dataTestId="contact-list-header"
          title={text.title}
          buttons={[
            {
              key: 'overview-header-add-contact-button',
              node: (
                <Button
                  size="medium"
                  icon="plus"
                  onClick={() => setShowModal(true)}
                  data-testid={TEST_ID.ADD_SINGLE_CONTACT_BUTTON}
                  label={text.addContact}
                />
              ),
            },
            {
              key: 'overview-header-import-contacts-button',
              node: (
                <Dropdown
                  appearance="outline"
                  options={importContactsOptions}
                  onChange={({ option: { payload } }) => {
                    setShowImportContactsModal(true);
                    // toggleSidebar(CONTACT_SIDEBAR_STATE.MULTIPLE_CONTACTS);
                    setImportType(payload);
                  }}
                  dataTestId={TEST_ID.IMPORT_MULTIPLE_CONTACTS_DROPDOWN}
                  placeholder={text.importContacts}
                />
              ),
            },
            hasFlows.allowed
              ? {
                  key: 'overview-header-start-flow',
                  node: <StartFlowDropdown {...rest} />,
                }
              : undefined,
            {
              key: 'overview-header-actions-button',
              node: (
                <ActionsContainer
                  {...rest}
                  onComplete={onActionCompleted}
                  onAddTags={onAddTags}
                  onDeleteContact={onDeleteContact}
                />
              ),
            },
          ].filter(x => x)}
          searchInputPlaceholder={text.searchInputPlaceholder}
          onFilterChange={onFilterChange}
        />
      </Container>

      {showImportContactsModal && importType && (
        <ImportContactsModal
          workflowStep={workflowStep}
          onAddMore={onAddMore}
          allowedFileExtensions={allowedFileExtenstions[importType] ?? []}
          loading={loading}
          onImport={onImport}
          fileFormat={importType}
          onClose={() => {
            setShowImportContactsModal(false);
            setWorkflowStep('MULTIPLE_CONTACTS');
          }}
        />
      )}
    </>
  );
};

const Container = styled.div<{}>``;

export default ContactsToolBar;
