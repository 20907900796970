/**
 * Returns a card heading based on action type
 * @param {FlowAction} actionType - The flow action enum
 * keywords: flowaction, heading
 */

import { FlowAction } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import { assertNeverWithoutThrowing } from '~/util/assertion';

export const cardHeadings: Record<ClientFlowAction['actionType'], string> = {
  [FlowAction.Start]: 'Start deze flow...',
  [FlowAction.ZapierTrigger]: 'Verstuur naar Zapier',
  [FlowAction.ContactDetails]: 'Contact wijzigen',
  [FlowAction.TaskCreate]: 'Taak aanmaken',
  [FlowAction.ContactAssign]: 'Contact toewijzen',
  [FlowAction.ContactAddTag]: 'Tag toevoegen',
  [FlowAction.ContactDeleteTag]: 'Tag verwijderen',
  [FlowAction.RealworksSendContact]:
    'Verstuur contact naar vestiging in Realworks',
  [FlowAction.SendEmailPlain]: 'E-mail versturen',
  [FlowAction.SendNotification]: 'Notificatie versturen',
  [FlowAction.IfElse]: 'Controleer of...',
  [FlowAction.Wait]: 'Wacht tot...',
  [FlowAction.Trickle]: 'Druppelactie',
  // [FlowAction.Goal]: 'Doel behaald',
};

const getCardHeading = (actionType: ClientFlowAction['actionType']): string => {
  switch (actionType) {
    case FlowAction.Start:
      return cardHeadings[FlowAction.Start];
    case FlowAction.ZapierTrigger:
      return cardHeadings[FlowAction.ZapierTrigger];
    case FlowAction.ContactDetails:
      return cardHeadings[FlowAction.ContactDetails];
    case FlowAction.TaskCreate:
      return cardHeadings[FlowAction.TaskCreate];
    case FlowAction.ContactAssign:
      return cardHeadings[FlowAction.ContactAssign];
    case FlowAction.ContactAddTag:
      return cardHeadings[FlowAction.ContactAddTag];
    case FlowAction.ContactDeleteTag:
      return cardHeadings[FlowAction.ContactDeleteTag];
    case FlowAction.RealworksSendContact:
      return cardHeadings[FlowAction.RealworksSendContact];
    case FlowAction.SendEmailPlain:
      return cardHeadings[FlowAction.SendEmailPlain];
    case FlowAction.SendNotification:
      return cardHeadings[FlowAction.SendNotification];
    case FlowAction.IfElse:
      return cardHeadings[FlowAction.IfElse];
    case FlowAction.Wait:
      return cardHeadings[FlowAction.Wait];
    case FlowAction.Trickle:
      return cardHeadings[FlowAction.Trickle];
    // case FlowAction.Goal:
    //   return 'Doel behaald';
    default:
      assertNeverWithoutThrowing(actionType);
      return 'Trigger';
  }
};

export default getCardHeading;
