import { navigate, useLocation, WindowLocation } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import { LINK_PREFIX, StepProps } from '../..';
import TEST_ID from './index.testid';
import { relatieHeader } from '~/components/organism/WizardSteps/Apps/Realworks/RealworksImportContacts';
import ImportContactsStepBody from './components/ImportContactsStepBody';

const ImportContactsStep: React.FCC<StepProps> = ({ onCancel }) => {
  const [relatie, setRelatie] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['relatie']>(
      null,
    );

  // @ts-ignore WindowLocation<S = unknown>. S is the type of state but we cannot type it ourselves because the types come from types/reach__router
  const location: WindowLocation<{
    values: UpdateRealworksAppStatus_AddTokenContainer__Input;
  }> = useLocation();

  const locationValues = location?.state?.values || {};

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    void navigate(`${LINK_PREFIX}/import-agenda`, {
      replace: true,
      state: {
        values: {
          ...locationValues,
          relatie,
          wonen: null,
          agenda: null,
        },
      },
    });
  };

  const agendaToken = locationValues?.agenda ?? null;

  return (
    <AppDetailsContainer
      header={relatieHeader}
      icon="incomingUser"
      dataTestId={TEST_ID.CONTAINER}
    >
      <ImportContactsStepBody
        tokens={[agendaToken]}
        value={relatie}
        onChange={value => setRelatie(value)}
        onSubmit={onSubmit}
        navigationFooter={{
          onCancel,
        }}
      />
    </AppDetailsContainer>
  );
};

export default ImportContactsStep;
