/**
 * General number validations
 */

import { isNumber as lodashIsNumber } from 'lodash';

export const isNumber = (value: any): value is number =>
  lodashIsNumber(value) && !isNaN(value);

export const isInteger = (value: any): value is number =>
  isNumber(value) && Number.isInteger(value);

export const isPositiveInteger = (value: any): value is number =>
  isNumber(value) && Number.isInteger(value) && value > 0;

export default {
  isNumber,
  isInteger,
  isPositiveInteger,
};
