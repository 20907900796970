import { RealworksTokenStatus } from '~/graphql/types';
import { Variant as APIVariant } from '~/components/organism/APIBlock';

const apiStatusToVariant = (
  status: RealworksTokenStatus | undefined | 'skipped',
): APIVariant => {
  switch (status) {
    case RealworksTokenStatus.Active:
      return 'success';
    case RealworksTokenStatus.Pending:
      return 'primary';
    case RealworksTokenStatus.Error:
    case 'skipped':
      return 'danger';
    default:
      return 'primary';
  }
};

export default apiStatusToVariant;
