import React, { useState } from 'react';
import Condition from '../Condition';
import { ConnectorOperator } from '~/graphql/types';
import ConnectorComponent from './components/ConnectorComponent';
import AddConditionToGroupComponent from './components/AddConditionToGroupComponent';
import getBaseCondition from './utils/getBaseCondition';
import { clone, remove } from 'ramda';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { ConditionContext, Flow___ConditionPartial } from '../../types';
import { Props as ConditionEditorProps } from '../../index';
import PositionedSelectorContainerV2 from '../SelectorContainerV2';
import { assertNever } from '~/util/assertion';
import { useRecoilState } from 'recoil';
import activeSelectorState from '~/components/page/Automation/v2/state/conditionEditor';

export type Props = Pick<
  ConditionEditorProps,
  'textObject' | 'maps' | 'inputListPrimitives' | 'inputPrimitives'
> & {
  /** The condition group idx */
  conditionSubExpressionIdx: number;
  conditionGroupIdx: number;
  dataTestId?: string;
  /** Condition to render */
  conditions: Array<Flow___ConditionPartial | null>;
  /** Connector between conditions within this group */
  connector: ConnectorOperator;

  /** Context this condition is rendered in */
  context: ConditionContext;

  onUpdate: (nextCondition: {
    conditions: Array<Flow___ConditionPartial | null>;
    connector: ConnectorOperator;
  }) => void;

  onDelete: (args: {
    conditionSubExpressionIdx: number;
    conditionGroupIdx: number;
  }) => void;
};

const ConditionGroup: React.FC<Props> = ({
  dataTestId,
  conditions,
  connector,
  conditionSubExpressionIdx,
  conditionGroupIdx,
  onDelete,
  onUpdate,
  inputListPrimitives,
  inputPrimitives,
  maps,
  textObject,
  context,
}) => {
  const { type } = context;
  const [activeSelector, setActiveSelector] =
    useRecoilState(activeSelectorState);

  const conditionGroupKey = `${conditionSubExpressionIdx}-${conditionGroupIdx}`;

  const [groupHover, setGroupHover] = useState<boolean>(false);
  const setConnector = (nextConnector: ConnectorOperator) =>
    onUpdate({
      conditions,
      connector: nextConnector,
    });

  const setConditions = (
    nextConditions: Array<Flow___ConditionPartial | null>,
  ) =>
    onUpdate({
      conditions: nextConditions,
      connector,
    });

  const isActive =
    activeSelector != null &&
    activeSelector.conditionGroupIdx === conditionGroupIdx &&
    activeSelector.conditionSubExpressionIdx === conditionSubExpressionIdx;

  const isTrigger = type === 'trigger';
  const emptyConditionLabel = isTrigger
    ? textObject.emptyTrigger
    : textObject.emptyCondition;

  const baseCondition = conditions[0];

  const elements = conditions.map((condition, conditionIdx) => {
    const conditionKey = `${conditionGroupKey}-${
      condition?.id ?? conditionIdx
    }`;

    const isLast = conditionIdx === conditions.length - 1;
    const hasMany = conditions.length > 1;
    const layout = conditionIdx === 0 ? 'complete' : 'onlyArguments';

    /**
     * After the condition we either render the connector "and" / "or" or the
     * ability to add another condition within the group, if allowed.
     *
     * Currently, triggers (start trigger / wait trigger) do not allow more than one condition in the group.
     */
    const afterConditionActionElement = (() => {
      if (isLast) {
        if (!baseCondition) return null;
        if (!condition) return null;
        if (condition.args.length === 0) return null;
        if (isTrigger) return null;

        return (
          <AddConditionToGroupComponent
            hidden={!groupHover}
            label={textObject.addValue}
            onClick={() => {
              const nextConditions = [
                ...conditions,
                getBaseCondition(baseCondition),
              ];

              setConditions(nextConditions);

              /**
               * We updated the condition, check if there is a next
               * not-yet-filled-in argument.
               */
              const nextCondition = nextConditions[nextConditions.length - 1];

              if (nextCondition == null) return setActiveSelector(null);

              const nextEmptyArgumentIndex = nextCondition.args.findIndex(
                arg => arg == null,
              );

              if (nextEmptyArgumentIndex === -1) return setActiveSelector(null);

              setActiveSelector({
                conditionGroupIdx,
                conditionSubExpressionIdx,
                conditionIdx: conditionIdx + 1,
                argumentIdx: nextEmptyArgumentIndex + 1,
              });
            }}
          />
        );
      }

      if (!hasMany) return null;

      return (
        <ConnectorComponent
          connector={connector}
          onChange={nextValue => setConnector(nextValue)}
          editable={groupHover}
        />
      );
    })();

    return (
      <React.Fragment key={conditionKey}>
        <Condition
          conditionSubExpressionIdx={conditionSubExpressionIdx}
          conditionGroupIdx={conditionGroupIdx}
          conditionIdx={conditionIdx}
          emptyConditionLabel={emptyConditionLabel}
          condition={condition}
          isDeletable={hasMany}
          isLast={isLast}
          layout={layout}
          groupHover={groupHover}
          onDelete={() => {
            if (conditions.length < 2) {
              /** No more conditions left, delete the whole group */
              return onDelete({ conditionGroupIdx, conditionSubExpressionIdx });
            }

            setActiveSelector({
              conditionGroupIdx,
              conditionSubExpressionIdx,
            });

            setConditions(remove(conditionIdx, 1, conditions));
          }}
          context={context}
          textObject={textObject}
          inputListPrimitives={inputListPrimitives}
          inputPrimitives={inputPrimitives}
          maps={maps}
        />
        {afterConditionActionElement}
      </React.Fragment>
    );
  });

  const selector = (() => {
    if (!isActive) return null;
    if (activeSelector?.conditionIdx == null) return null;
    if (activeSelector?.inputRef == null) return null;

    const { conditionIdx, argumentIdx, inputRef } = activeSelector;
    const condition = conditions[conditionIdx];
    const isTriggerCondition = isTrigger && conditionIdx === 0;

    return (
      <PositionedSelectorContainerV2
        parentRef={inputRef}
        condition={condition}
        inputPrimitives={inputPrimitives}
        inputListPrimitives={inputListPrimitives}
        variable={argumentIdx}
        opts={{
          action: context.action,
          conditionType: context.type,
          conditionMap: maps.conditionMap,
          directoryMap: maps.directoryMap,
          subjectMap: maps.subjectMap,
          subjectToConditionMap: maps.subjectToConditionMap,
        }}
        instanceMap={maps.instanceMap}
        onClose={() => setActiveSelector(null)}
        onSelect={(arg, opts) => {
          const duplicateInput =
            !isTriggerCondition && opts?.duplicateInput === true;

          const next = (() => {
            switch (arg.__typename) {
              case 'Flow___Argument_Pointer':
              case 'Flow___Argument_String':
              case 'Flow___Argument_Integer':
              case 'Flow___Argument_Float':
              case 'Flow___Argument_Boolean':
              case 'Flow___Argument_AWSDateTime':
              case 'Flow___Argument_File': {
                if (!condition || argumentIdx == null) return;

                /** Adjust an argument */
                const nextCondition = clone(condition);
                const nextConditions = clone(conditions);

                nextCondition.args[argumentIdx - 1] = arg;
                nextConditions[conditionIdx] = nextCondition;
                return { nextConditions, nextConditionIdx: conditionIdx };
              }

              case 'Flow___InstanceCondition':
              case 'Flow___SubjectFieldCondition':
                return { nextConditions: [arg], nextConditionIdx: 0 };
              default:
                return assertNever(arg);
            }
          })();

          if (!next) return;
          const { nextConditions, nextConditionIdx } = next;
          setConditions(nextConditions);

          /**
           * We updated the condition, check if there is a next
           * not-yet-filled-in argument.
           */
          const nextCondition = nextConditions[nextConditionIdx];

          if (nextCondition == null) {
            return setActiveSelector(null);
          }

          const nextEmptyArgumentIndex = nextCondition.args.findIndex(
            arg => arg == null,
          );

          if (nextEmptyArgumentIndex === -1) {
            if (duplicateInput && baseCondition) {
              const nextConditionEmpty = getBaseCondition(nextCondition);
              let argIdx = -1;

              nextConditionEmpty.args = nextCondition.args.map((arg, index) => {
                if (argIdx !== -1) return null;

                switch (arg?.__typename) {
                  case 'Flow___Argument_String':
                    argIdx = index;
                    return { ...arg, value_string: null };
                  case 'Flow___Argument_Float':
                    argIdx = index;
                    return { ...arg, value_float: 1 };
                  case 'Flow___Argument_Integer':
                    argIdx = index;
                    return { ...arg, value_integer: 1 };
                }

                return null;
              });

              setConditions([...nextConditions, nextConditionEmpty]);

              setActiveSelector({
                conditionGroupIdx,
                conditionSubExpressionIdx,
                conditionIdx: conditionIdx + 1,
                argumentIdx: argIdx + 1,
              });

              return;
            }

            return setActiveSelector(null);
          }

          setActiveSelector({
            conditionGroupIdx,
            conditionSubExpressionIdx,
            conditionIdx,
            argumentIdx: nextEmptyArgumentIndex + 1,
          });
        }}
      />
    );
  })();

  return (
    <JustificationContainer
      data-testid={dataTestId}
      onMouseEnter={() => setGroupHover(true)}
      onMouseLeave={() => setGroupHover(false)}
      onMouseOver={() => !groupHover && setGroupHover(true)}
      wrap="wrap"
      padding={['s', 'xs']}
      align="center"
      border={{ radius: 'm' }}
      gap="xs"
    >
      {elements}
      {selector}
    </JustificationContainer>
  );
};

export default ConditionGroup;
