import type { FlowData___FlowInstanceFragment } from '~/graphql/types';
import type { SubjectMap } from '../../components/UpdateAction/components/Selector/utils/getSubject';
import { atom, selectorFamily } from 'recoil';
import { actionById, flowActions } from '../actions';
import getParentInstances from '../../components/Builder/getParentInstances';

const flowInstance = atom<Array<FlowData___FlowInstanceFragment>>({
  key: 'flowInstance',
  default: [],
});

export const instancesByActionId = selectorFamily<
  Array<FlowData___FlowInstanceFragment> | null,
  {
    /** The id of the action to get the relative instances */
    actionId: string;
    subjectMap: SubjectMap;
  }
>({
  key: 'instanceByActionId',
  get:
    ({ actionId, subjectMap }) =>
    ({ get }) => {
      const allActions = get(flowActions);
      const action = get(actionById(actionId));

      if (!action) return null;

      return getParentInstances({
        action,
        allActions,
        subjectMap,
      }).map(i => i.instance);
    },
});

export default flowInstance;
