import type { ClientFlowActionSendEmailPlain } from '~/graphql/types.client';

import type { Issue } from '~/components/page/Automation/v2/state/flowIssues';
import type { DeepLinksMap } from '~/components/page/Automation/v2/state/deepLinks';

const text = {
  missingDeepLink: 'Een app link bestaat niet meer',
};

/**
 * Check if the pointer attachment is missing in the action
 *
 * keywords: pointer attachment validation
 */
const collectIssuesForDeepLinks = (
  action: ClientFlowActionSendEmailPlain,
  deepLinksMap: DeepLinksMap,
) => {
  const issues: Array<Issue> = [];
  if (!action.deepLinks) return [];

  for (const deepLink of action.deepLinks) {
    if (!deepLinksMap[deepLink.id]) {
      issues.push({
        key: deepLink.id,
        message: text.missingDeepLink,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
      });
    }
  }

  return issues;
};

export default collectIssuesForDeepLinks;
