import { VariableElement } from '~/components/organism/PluginsEditor/types';
import updateAttrsAndConvertToString from '~/components/organism/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

/**
 * serializes the VariableElement into html
 * @param {VariableElement} node - VariableElement
 */
const serialize = (node: VariableElement): string => {
  if (!node.mappingId || node.pending) return '';

  const attrsAsString = updateAttrsAndConvertToString({
    style: {
      ...(node.children[0].bold ? { 'font-weight': 'bold' } : {}),
      ...(node.children[0].italic ? { 'font-style': 'italic' } : {}),
    },
  });
  // 'data-dhvariable' attribute will help to differentiate between old and new version variable elements
  return `<span${attrsAsString ? ` ${attrsAsString}` : ''} data-dhvariable="true">{{${node.mappingId}}}</span>`;
};

export default serialize;
