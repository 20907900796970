import type { ClientFlowAction } from '~/graphql/types.client';
import type { IssueOptions } from '../..';
import type { Issue } from '~/components/page/Automation/v2/state/flowIssues';
import { reporter } from '~/hooks/useErrorReporter';
import { isNil } from 'ramda';
import { messages } from '../../validationMap';
import type { RelativeMaps } from '~/components/page/Automation/v2/types';
import getPathForPointer from '../../../../components/Selector/utils/getPathForPointer';

const text = {
  pathToPointerError:
    'Er mist een variabele, waarschijnlijk heb je een stap aangepast of verwijderd',
  invalidOrMissingVariable: 'Ongeldige of ontbrekende variabele verwijzing',
  error: 'This function can only collect issues for Zapier actions',
};

export const checkZapierTriggerOutOfBound = (
  action: ClientFlowAction,
  opts?: IssueOptions,
): Array<Issue> => {
  if (action.__typename !== 'FlowV2_Action_Zapier_Trigger') {
    const err = new Error(text.error);
    reporter.captureException(err);
    throw err;
  }

  if (!opts?.zapier || action.zapierTriggerId === '') return [];
  const foundTrigger = opts.zapier.triggers.find(
    id => id === action.zapierTriggerId,
  );

  if (isNil(foundTrigger)) {
    return [
      {
        message: messages.zapier.missingTrigger,
        level: 'error',
        type: 'ZapierTriggerOutOfBounds',
        actionId: action.id,
        actionType: action.actionType,
      },
    ];
  }

  return [];
};

export const collectZapierMappingIssues = (
  action: ClientFlowAction,
  relativeMaps?: RelativeMaps,
): Array<Issue> => {
  if (action.__typename !== 'FlowV2_Action_Zapier_Trigger') {
    const err = new Error(text.error);
    reporter.captureException(err);
    return [];
  }

  const mappingIssues = action.mappings.reduce<Array<Issue>>((acc, mapping) => {
    if (!mapping.mapping) {
      acc.push({
        message: text.invalidOrMissingVariable,
        level: 'error',
        type: 'ValidationIssue',
        actionId: action.id,
        actionType: action.actionType,
        key: mapping.mappingId,
      });
      return acc;
    }

    if (
      mapping.mapping.__typename === 'Flow___Argument_Pointer' &&
      relativeMaps
    ) {
      const pathToPointer = getPathForPointer(
        mapping.mapping.pointer,
        relativeMaps,
      );

      if (pathToPointer.error) {
        acc.push({
          message: text.pathToPointerError,
          level: 'error',
          type: 'BadVariableIssue',
          actionId: action.id,
          actionType: action.actionType,
        });
      }
    }
    return acc;
  }, []);

  return mappingIssues;
};
