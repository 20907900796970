import React from 'react';
import {
  RealworksTokenType,
  type AppStatus_Realworks_RealworksTokenAgenda_AgendaSync,
  RealworksTokenStatus,
  type AppStatus_Realworks_OfficeData,
} from '~/graphql/types';
import TEST_ID from '../../components/Connections/components/TokenContainer/index.testid';
import AgendaSettings from '../../components/AgendaSettings';
import { Body, Label } from '~/components/atom/Typography';
import Icon from '~/components/atom/Icon';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
`;

const text = {
  activeRelatieRequiredWarning:
    'Je moet een actieve relatie API hebben om deze koppeling te gebruiken.',
  mustBelongToSameOfficeWarning:
    'Deze API moet bij dezelfde Realworks vestiging in deze koppeling horen.',
  agendaSyncNotEnabledWarning: (
    <>
      Koppeling inactief. Ga naar de instellingen via de knop (
      <StyledIcon name="gear" strokeWidth={2} />) om deze koppeling te
      activeren.
    </>
  ),
};

export type RealworksTokenWithStatus = {
  token?: string | null;
  status: RealworksTokenStatus | 'skipped';
  error?: string | null;
};

type RealworksAPIConfig = {
  heading: string;
  tokenType: RealworksTokenType;
  description: React.ReactNode;
  value?: RealworksTokenWithStatus | null;
  dataTestId?: string;
  settings?: React.ReactNode;
  warnings?: Array<string | React.ReactNode> | null;
};

type Args = {
  tokenContainerId?: string;
  wonen?: RealworksTokenWithStatus | null;
  agenda?:
    | (RealworksTokenWithStatus & {
        agendaSync?: AppStatus_Realworks_RealworksTokenAgenda_AgendaSync | null;
      })
    | null;
  relatie?: RealworksTokenWithStatus | null;
  makelaarData?: AppStatus_Realworks_OfficeData | null;
};

const getRealworksAPIConfigs = ({
  tokenContainerId,
  wonen,
  agenda,
  relatie,
  makelaarData,
}: Args): Array<RealworksAPIConfig> => [
  {
    heading: 'Relaties',
    tokenType: RealworksTokenType.Relatie,
    value: relatie,
    description: (
      <>
        <Body size="base">Met deze API haal je contacten uit Realworks.</Body>
        {makelaarData && (
          <Label size="base" margin={[null]}>
            {text.mustBelongToSameOfficeWarning}
          </Label>
        )}
      </>
    ),
    dataTestId: TEST_ID.ADD_RELATIE,
  },
  {
    heading: 'Agenda',
    tokenType: RealworksTokenType.Agenda,
    value: agenda,
    description: (
      <>
        <Body size="base">
          Met deze API haal je agenda-items op uit Realworks.
          <Label size="base" margin={[null]}>
            Let op: deze koppeling moet aan dezelfde Realworks-vestiging als de
            Relatie API gekoppeld zijn.
          </Label>
        </Body>
      </>
    ),
    dataTestId: TEST_ID.ADD_AGENDA,
    settings:
      agenda && agenda?.agendaSync && tokenContainerId ? (
        <AgendaSettings
          agenda={agenda}
          agendaSync={agenda.agendaSync}
          tokenContainerId={tokenContainerId}
        />
      ) : null,
    warnings: getWarningsForAgenda(agenda, relatie),
  },
  {
    heading: 'Wonen',
    tokenType: RealworksTokenType.Wonen,
    value: wonen,
    description: (
      <>
        {/* <Body size="base">Met deze API stuur je contacten naar Realworks.</Body> */}
        <Label size="base" margin={[null]}>
          Deze API is tijdelijk niet beschikbaar.
        </Label>
      </>
    ),
    dataTestId: TEST_ID.ADD_WONEN,
  },
];

export default getRealworksAPIConfigs;

const getWarningsForAgenda = (
  agenda?:
    | (RealworksTokenWithStatus & {
        agendaSync?: AppStatus_Realworks_RealworksTokenAgenda_AgendaSync | null;
      })
    | null,
  relatie?: RealworksTokenWithStatus | null,
): Array<string | React.ReactNode> | null => {
  const warnings: Array<string | React.ReactNode> = [];

  if (
    agenda?.status === RealworksTokenStatus.Active &&
    relatie?.status !== RealworksTokenStatus.Active
  ) {
    warnings.push(text.activeRelatieRequiredWarning);
  }

  if (
    agenda?.status === RealworksTokenStatus.Active &&
    agenda?.agendaSync &&
    !agenda.agendaSync?.enabled
  ) {
    warnings.push(text.agendaSyncNotEnabledWarning);
  }
  return warnings.length > 0 ? warnings : null;
};
