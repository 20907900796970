import React from 'react';
import { useRecoilState } from 'recoil';
import { hoveringToolbarState } from '../../state/HoveringToolbarState';
import ToolbarMainContent from './components/ToolbarMainContent';
import { ReactEditor, useSlate } from 'slate-react';

const HoveringToolbar: React.FC = () => {
  const editor = useSlate();
  const [toolbarElement, setToolbarElement] =
    useRecoilState(hoveringToolbarState);

  // If editor is not focused, Slate tries to access wrong editor
  // (can happen when more than one editor is on the page)
  if (!toolbarElement || !ReactEditor.isFocused(editor)) return null;

  return (
    <ToolbarMainContent
      toolbarElement={toolbarElement}
      setToolbarElement={setToolbarElement}
    />
  );
};

export default HoveringToolbar;
