import React from 'react';

import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';
import TEST_ID from './index.testid';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import { SortDirection } from '~/graphql/types';
import EnabledStatusIndicator from '~/components/atom/EnabledStatusIndicator';
import { navigate } from '@gatsbyjs/reach-router';
import ShareIndicator from '~/components/organism/ShareIndicator';
import DateCell from '~/components/bad/DataTables/components/DateCell';

export type SortToggleFunction = (
  sortField: Array<string>,
  sortDirection: SortDirection | null,
) => void;

const formListColumns = (
  sortToggle: SortToggleFunction,
  _version: string,
): Array<TableHeaderDefinitionCellType<any>> => {
  const columns = [
    TableHeaderCell.string({
      label: 'Naam',
      dataProperty: 'name',
      width: 100,
      dataTestId: TEST_ID.NAME,
      sorting: {
        onSort: sortDirection => sortToggle(['name'], sortDirection),
      },
    }),

    TableHeaderCell.string({
      label: 'Description',
      dataProperty: 'formDescription',
      width: 200,
      dataTestId: TEST_ID.DESCRIPTION,
      sorting: {
        onSort: sortDirection => sortToggle(['formDescription'], sortDirection),
      },
    }),

    TableHeaderCell.date({
      label: 'Aangemaakt',
      dataProperty: 'createdDate',
      dataTestId: TEST_ID.CREATED,
      width: 90,
      renderFunction: value => <DateCell value={value} />,
      sorting: {
        onSort: sortDirection => sortToggle(['createdDate'], sortDirection),
      },
    }),
    TableHeaderCell.date({
      label: 'Laatst gewijzigd',
      dataProperty: 'lastModified',
      dataTestId: TEST_ID.LAST_MODIFIED,
      width: 110,
      sorting: {
        onSort: sortDirection => sortToggle(['lastModified'], sortDirection),
      },
      renderFunction: value => <DateCell value={value} />,
    }),

    TableHeaderCell.custom({
      label: 'Gedeeld',
      dataProperty: 'shared',
      width: 50,
      sorting: {
        onSort: sortDirection =>
          sortToggle(['shared', 'enabled'], sortDirection),
      },
      dataTestId: TEST_ID.SHARED_COLUMN,
      renderFunction: (value: { enabled: boolean; id: string }) => (
        <ShareIndicator
          shared={value.enabled}
          onClick={() => navigate(`/-/forms/sharing/${value.id}`)}
        />
      ),
    }),

    TableHeaderCell.boolean({
      label: 'Status',
      dataProperty: 'enabled',
      width: 50,
      sorting: {
        onSort: sortDirection => sortToggle(['enabled'], sortDirection),
      },
      renderFunction: value => <EnabledStatusIndicator value={value} />,
      dataTestId: TEST_ID.ENABLED,
    }),

    TableHeaderCell.action({
      dataTestId: TEST_ID.ACTION,
    }),
  ];

  return columns;
};

export default formListColumns;
