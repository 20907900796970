import React from 'react';
import styled, { css } from 'styled-components';

import { SizeMap } from '~/theme/System/tokens/sizes';
import { Code } from '~/components/atom/Typography';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = {
  children: JSX.Element | string;
  /** Custom padding */
  padding?: keyof SizeMap;
  backgroundColor?: ThemeColor;
};

const CodeBlock: React.FCC<Props> = ({
  dataTestId,
  children,
  backgroundColor = { group: 'tertiary', variant: 'light' },
  ...rest
}) => (
  <Container
    data-testid={dataTestId}
    {...rest}
    backgroundColor={backgroundColor}
  >
    <StyledCode>{children}</StyledCode>
  </Container>
);

const Container = styled.pre<{
  padding?: Props['padding'];
  backgroundColor: ThemeColor;
}>(
  ({ theme, padding, backgroundColor }) => css`
    background-color: ${theme.color(
      backgroundColor.group,
      backgroundColor.variant,
    )};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space(padding || 's')};
    margin: 5px 0;
    display: inline-block;
    white-space: pre-wrap;
    width: 100%;
    overflow-x: scroll;
  `,
);

const StyledCode = styled(Code)<{}>(
  ({ theme }) => css`
    margin: 0;
    font-family: ${theme.getTokens().type.fontFamily.monospace};
    font-size: ${theme.fs('base')};
    background-color: transparent;
    width: 100%;
  `,
);

export default CodeBlock;
