import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type {
  FormBuilder_Event_Field,
  FormBuilder_NodeFragment,
  FormBuilder_ScreenNode,
  FormBuilder_ScreenNode_Block,
  Maybe,
} from '~/graphql/types';
import { blocksMap } from '../../constants';
import { Heading5, Label, Variant } from '~/components/atom/Typography';
import EditableBlock from './components/EditableBlock';
import SquareIcon from '../../../../../../../../../../atom/Icon/components/SquareIcon';
import styled from 'styled-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { eventsState } from '../../../../../../state/nodesAndEvents';

import InBasicForm from '../../../../../InBasicForm';
import withIconTooltip from '~/hocs/withIconTooltip';
import Div from '~/components/atom/Div';
import DebouncedInput from '~/components/molecule/DebouncedInput';
import getEventFieldForBlock from '~/components/page/Forms/components/Builder/utils/autoGeneratedEventUtils/getEventFieldForBlock';
import { autoGeneratedEvent } from '~/components/page/Forms/components/Builder/state/autoGeneratedEvent';

const text = {
  identifierLabel: 'Identificatielabel',
  tooltipMessage:
    'Met dit label kun je dit veld identificeren in de tijdlijn van het contact, in contactfilters en in flows.',
};

type Props = {
  node: Maybe<FormBuilder_ScreenNode>;
  id: string;
  focusedBlock: Maybe<FormBuilder_ScreenNode_Block>;
  nodes: Array<FormBuilder_NodeFragment>;
  onBlockChange: (update: FormBuilder_ScreenNode_Block) => void;
  onBlockFocus: (block: FormBuilder_ScreenNode_Block) => void;
};

const EditBlockArea: React.FC<Props> = ({
  onBlockChange,
  id,
  focusedBlock,
  onBlockFocus,
  nodes,
}) => {
  const setEvents = useSetRecoilState(eventsState);
  const autoGeneratedEventState = useRecoilValue(autoGeneratedEvent);

  const eventFieldForBlock = getEventFieldForBlock({
    blockKey: focusedBlock?.key || '',
    nodes,
    eventFields:
      autoGeneratedEventState && 'fields' in autoGeneratedEventState
        ? autoGeneratedEventState.fields
        : [],
  });

  const onChange = (value: string) => {
    if (!eventFieldForBlock) return;

    const updatedFieldForThisBlock: FormBuilder_Event_Field = {
      ...eventFieldForBlock,
      name: value,
    };

    setEvents(prev => {
      if (!prev || prev.length === 0) return prev;
      const otherBlockFields = prev[0].fields.filter(
        field => field.key !== eventFieldForBlock.key,
      );

      return [
        {
          ...prev[0],
          fields: [...otherBlockFields, updatedFieldForThisBlock],
        },
      ];
    });
  };

  if (!focusedBlock) return null;

  return (
    <Container
      direction="column"
      width="100%"
      backgroundColor={{ group: 'primary', variant: 'translucent' }}
      border={{ radius: 'base' }}
      padding={['base']}
      gap="base"
      key={focusedBlock.key}
    >
      <JustificationContainer
        align="center"
        gap="base"
        onClick={() => onBlockFocus(focusedBlock)}
      >
        <SquareIcon
          name={blocksMap[focusedBlock.__typename].icon}
          color={blocksMap[focusedBlock.__typename].color}
        />
        <Heading5 margin={[null]} variant={Variant.primary}>
          {blocksMap[focusedBlock.__typename].label.nl}
        </Heading5>
      </JustificationContainer>

      <InBasicForm>
        {eventFieldForBlock && (
          <Div width="100%">
            <LabelWithTooltip
              tooltipMessage={text.tooltipMessage}
              margin={[null]}
            >
              {text.identifierLabel}
            </LabelWithTooltip>
            <DebouncedInput
              width="100%"
              value={eventFieldForBlock.name}
              onDebouncedChange={onChange}
            />
          </Div>
        )}
      </InBasicForm>
      <EditableBlock
        key={focusedBlock.key}
        block={focusedBlock}
        nodeId={id}
        onChange={onBlockChange}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)`
  cursor: pointer;
`;

const LabelWithTooltip = withIconTooltip(Label);

export default EditBlockArea;
