import { isEmpty, isNil } from 'ramda';
import {
  FlowV2_TemplateStringFragment,
  type FlowV2_ToAddress,
} from '~/graphql/types';
import {
  ClientFlowAction,
  ClientFlowActionTaskCreate,
} from '~/graphql/types.client';
import stripHtmlWithRegex from '~/util/stripHtmlWithRegex';
import Validation from '~/util/Validation';

export type ValidationObj = Record<
  string,
  (
    /**
     * The value you want to check
     */
    value: unknown,
  ) => string | null
>;
export type ValidationMap = Record<
  Exclude<
    ClientFlowAction,
    | { __typename: 'FlowV2_Action_IfElse' }
    | {
        __typename: 'FlowV2_Action_Wait';
      }
    | {
        __typename: 'FlowV2_Action_Start';
      }
  >['__typename'],
  ValidationObj
>;

export const messages = {
  tag: {
    tagInvalid: 'Waarde van de tag is niet correct',
  },
  contactAssign: {
    missingOffice: 'Er moet een vestiging gekozen worden',
  },
  invalidContactDetailsField:
    'Naam, telefoonnummer of uitschrijven moet worden opgegeven',
  sendEmail: {
    missingSubject: 'Een onderwerp is verplicht voor emails',
    missingBody: 'Wij versturen geen lege emails',
  },
  sendNotification: {
    missingSubject: 'Een onderwerp is verplicht voor emails',
    missingBody: 'Wij versturen geen lege emails',
    missingTo: 'Het Aan veld kan niet leeg zijn',
  },
  task: {
    missingTitle: 'Taken hebben een titel nodig',
    missingDescription: 'Taken hebben een omschrijving nodig',
    noOfficeForUser:
      'Taken moeten toegewezen zijn aan een gebruiker binnen een kantoor',
  },
  zapier: {
    missingTrigger: 'Er is geen uitgaande koppeling geselecteerd',
    missingMapping: 'Waardes kunnen niet leeg zijn',
  },
};

// Validation for add and delete tag is the same. Therefore we reuse the same object instead of redefining the same obj
const tagValidation = {
  name: (value: string): string | null => {
    if (Validation.Tag.isValid(value)) return null;
    return messages.tag.tagInvalid;
  },
};

const validationMap: ValidationMap = {
  FlowV2_Action_Contact_AddTag: tagValidation,
  FlowV2_Action_Contact_DeleteTag: tagValidation,
  FlowV2_Action_Contact_Assign: {
    office: value => {
      if (Validation.String.isNonEmptyString(value)) return null;
      return messages.contactAssign.missingOffice;
    },
    user: () => null,
    overwriteAssignee: () => null,
  },
  FlowV2_Action_Contact_Details: {
    field: (value: {
      name?: FlowV2_TemplateStringFragment;
      phone?: FlowV2_TemplateStringFragment;
      unsubscribeEmail?: boolean;
    }) => {
      const validPhone =
        value.phone &&
        Validation.String.isNonEmptyString(value.phone.template?.trim());
      const validName =
        value.name &&
        Validation.String.isNonEmptyString(value.name.template?.trim());

      if (!validPhone && !validName && isNil(value.unsubscribeEmail)) {
        return messages.invalidContactDetailsField;
      }

      return null;
    },
  },
  FlowV2_Action_Realworks_SendContact: {
    tokenContainerId: () => null,
  },
  FlowV2_Action_SendEmail_Plain: {
    from: () => null,
    signature: () => null,
    subject: (value: FlowV2_TemplateStringFragment) => {
      if (Validation.String.isNonEmptyString(value.template)) return null;
      return messages.sendEmail.missingSubject;
    },
    body: (value: FlowV2_TemplateStringFragment) => {
      if (!value.template) return messages.sendEmail.missingBody;
      const stripped = stripHtmlWithRegex(value.template);

      if (Validation.String.isNonEmptyString(stripped)) return null;
      return messages.sendEmail.missingBody;
    },
    attachments: () => null,
    isTransactional: () => null,
  },
  FlowV2_Action_SendNotification: {
    to: (value: Array<FlowV2_ToAddress>) => {
      if (isEmpty(value)) return messages.sendNotification.missingTo;
      return null;
    },
    subject: (value: FlowV2_TemplateStringFragment) => {
      if (Validation.String.isNonEmptyString(value.template)) return null;
      return messages.sendNotification.missingSubject;
    },
    body: (value: FlowV2_TemplateStringFragment) => {
      if (!value.template) return messages.sendNotification.missingBody;
      const stripped = stripHtmlWithRegex(value.template);

      if (Validation.String.isNonEmptyString(stripped)) return null;
      return messages.sendNotification.missingBody;
    },
    attachments: () => null,
  },
  FlowV2_Action_Task_Create: {
    assignToContactOwner: () => null,
    description: (value: FlowV2_TemplateStringFragment) => {
      if (Validation.String.isNonEmptyString(value.template)) return null;
      return messages.task.missingDescription;
    },
    title: (value: FlowV2_TemplateStringFragment) => {
      if (Validation.String.isNonEmptyString(value.template)) return null;
      return messages.task.missingTitle;
    },
    assignedToOffice: () => null,
    user: () => null,
    type: () => null,
    interField: (action: ClientFlowActionTaskCreate) => {
      if (!isNil(action.assignedToOffice) && !isEmpty(action.assignedToOffice))
        return null;
      return messages.task.noOfficeForUser;
    },
  },
  FlowV2_Action_Zapier_Trigger: {
    zapierTriggerId: value => {
      if (Validation.String.isNonEmptyString(value)) return null;
      return messages.zapier.missingTrigger;
    },
    mappings: value => {
      if (Array.isArray(value)) return null;
      return messages.zapier.missingMapping;
    },
  },
  FlowV2_Action_Trickle: {
    limits: () => null,
    rules: () => null,
  },
};

export default validationMap;
