import { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useEffect } from 'react';
import FullPageInformation from '~/components/template/FullPageInformation';
import useTimeout from '~/hooks/useTimeout';

const text = {
  title: 'Succes!',
  explanation: 'De brainbay waardecheck is succesvol geactiveerd.',
  complete: 'Sluiten',
};

export const nvmOAuthSuccessPath = 'nvm-oAuth-success';
export const nvmSuccessMessage = 'nvm-success';
const AUTO_CLOSE_TIMER = 5000;

type Props = RouteComponentProps & {};

const NvmOAuthSuccess: React.FCC<Props> = ({}) => {
  useTimeout(() => window.close(), AUTO_CLOSE_TIMER);

  useEffect(() => {
    if (window.opener) window.opener.postMessage(nvmSuccessMessage, '*');
  }, []);

  return (
    <FullPageInformation
      title={text.title}
      explanation={text.explanation}
      button={{ label: text.complete, onClick: () => window.close() }}
    />
  );
};

export default NvmOAuthSuccess;
