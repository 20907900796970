/**
 * Extracts the `style` attribute from a string of HTML attributes.
 *
 * This function searches for a `style` attribute within the provided
 * string of HTML attributes and returns it in the format `style="..."`.
 * If no `style` attribute is found, it returns an empty string.
 *
 * @param {string} attributes - A string containing HTML attributes.
 * @returns {string} - The extracted `style` attribute or an empty string if not found.
 */
const extractStyleAttributes = (attributes: string): string => {
  const styleMatch = attributes.match(/style="([^"]*)"/);
  return styleMatch ? `style="${styleMatch[1]}"` : '';
};

export default extractStyleAttributes;
