import { RealworksTokenStatus } from '~/graphql/types';

/** Backend returns this error when the office does not match in the inserted token */
const OFFICE_MISMATCH_ERROR = 'Office mismatch';

const apiStatusToDescription = (
  status: RealworksTokenStatus | undefined | 'skipped',
  error?: string | null,
): React.ReactNode => {
  switch (status) {
    case RealworksTokenStatus.Pending:
      return 'De koppeling is in afwachting van goedkeuring.';
    case RealworksTokenStatus.Error:
      if (error && error === OFFICE_MISMATCH_ERROR) {
        return 'Deze API moet bij dezelfde Realworks vestiging in deze koppeling horen.';
      }
      return 'Er is iets misgegaan met het opzetten van de koppeling voor deze vestiging. Klik hieronder om het opnieuw te proberen.';
    case 'skipped':
      return 'Deze koppeling is overgeslagen';
    case RealworksTokenStatus.Active:
    default:
      return null;
  }
};

export default apiStatusToDescription;
