import { SetterOrUpdater } from 'recoil';
import { ClientFlowAction } from '~/graphql/types.client';
import deleteAction from './utils/deleteAction';
import type { FlowV2_Update_Metadata } from '~/graphql/types';

const onDeleteAction = (
  subjectAction: Exclude<
    ClientFlowAction,
    { __typename: 'FlowV2_Action_IfElse' }
  >,
  setActions: SetterOrUpdater<Array<ClientFlowAction>>,
  setLastDeletedActionNextChildId: (parentId: string | null) => void,
  setFlowMetadata: SetterOrUpdater<Array<FlowV2_Update_Metadata>>,
): void => {
  let lastDeletedActionNextChildId: string | null = null;

  setActions(actions => {
    const { actions: updatedActions, nextActionId } = deleteAction({
      actions,
      subjectAction,
    });

    lastDeletedActionNextChildId = nextActionId;

    return updatedActions;
  });

  setFlowMetadata(prev =>
    prev.map(metadata => {
      if (metadata.nextFlowBlueprintActionId === subjectAction.id) {
        return {
          ...metadata,
          nextFlowBlueprintActionId: lastDeletedActionNextChildId,
        };
      }
      return metadata;
    }),
  );
  setLastDeletedActionNextChildId(lastDeletedActionNextChildId);
};

export default onDeleteAction;
