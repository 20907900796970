import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer, {
  Props as JustificationContainerProps,
} from '~/components/atom/JustificationContainer';
import { ClientFlowActionTrickle } from '~/graphql/types.client';
import { DAYS_OF_WEEK_OPTIONS } from '../../constants';
import type { DayOfWeek } from '~/graphql/types';
import { Label } from '~/components/atom/Typography';

export type Props = JustificationContainerProps & {
  dataTestId?: string;
  /** The rule to update */
  rule: ClientFlowActionTrickle['rules'][number];

  /** The size of the days of week container */
  size?: 'base' | 'small';

  /** The function to update the rule */
  onUpdateRule: (rule: ClientFlowActionTrickle['rules'][number]) => void;
};

const DaysOfWeekContainer: React.FCC<Props> = ({
  rule,
  onUpdateRule,
  className,
  size = 'base',
  ...rest
}) => {
  const onDayClick = (day: DayOfWeek) => {
    onUpdateRule({
      ...rule,
      daysOfWeek: !rule.daysOfWeek.includes(day)
        ? [...rule.daysOfWeek, day]
        : rule.daysOfWeek.filter(key => key !== day),
    });
  };

  const isSmall = size === 'small';

  return (
    <Container className={className} $isSmall={isSmall} {...rest}>
      {Object.values(DAYS_OF_WEEK_OPTIONS).map(({ label, key }) => {
        const isSelected = rule.daysOfWeek.includes(key);
        return (
          <DayContainer
            align="center"
            justification="center"
            key={key}
            onClick={() => onDayClick(key)}
            backgroundColor={
              isSelected
                ? { group: 'primary', variant: 'light' }
                : { group: 'tertiary', variant: 'dark' }
            }
            width={isSmall ? '25px' : '30px'}
            height={isSmall ? '25px' : '30px'}
            border={{ radius: 'full' }}
          >
            <Label
              color={{ group: 'white' }}
              margin={[null]}
              size={isSmall ? 'xs' : 's'}
              fontWeight={isSmall ? 'regular' : 'semiBold'}
            >
              {label}
            </Label>
          </DayContainer>
        );
      })}
    </Container>
  );
};

export default DaysOfWeekContainer;

const Container = styled(JustificationContainer)<{ $isSmall: boolean }>(
  ({ $isSmall, theme }) => css`
    display: inline-flex;
    gap: ${$isSmall ? `calc(${theme.space('xxxs')} * 2)` : theme.space('xxs')};
  `,
);

const DayContainer = styled(JustificationContainer)<{}>(
  ({}) => css`
    cursor: pointer;
  `,
);
