import React, { useEffect } from 'react';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Dropdown from '~/components/molecule/Dropdown';

export type OnChangeProps = {
  userId?: string | null;
  officeId?: string | null;
};

export type Props = {
  /** The selected userId */
  userId?: string | null;
  /** The selected officeId */
  officeId?: string | null;
  /** The direction of the assign block */
  direction?: 'row' | 'column';
  /** Fires whenever a reassign happens */
  onChange: ({ userId, officeId }: OnChangeProps) => void;
  /** The officeIds that are disabled in the options */
  disabledOfficeIds?: Array<string>;
};

const AssignBlock: React.FCC<Props> = ({
  onChange,
  userId,
  officeId: passedOfficeId,
  disabledOfficeIds,
  direction = 'row',
}) => {
  const officeOptions = useOfficeOptions({
    userId: userId ?? undefined,
  });

  const officeId: string | null = passedOfficeId ?? null;

  useEffect(() => {
    if (officeId !== passedOfficeId) onChange({ officeId, userId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userOptions = useUserOptions({ officeId, withAllUsersOption: true });

  const selectedOfficeIdx = officeOptions.findIndex(
    ({ payload }) => payload?.id == officeId,
  );
  const selectedUserIdx = userOptions.findIndex(
    ({ payload }) => payload?.id == userId,
  );

  return (
    <JustificationContainer
      justification="space-between"
      align="center"
      gap="base"
      direction={direction}
      width="100%"
    >
      <Dropdown
        dataTestId={TEST_ID.ASSIGNED_OFFICE_DROPDOWN}
        options={officeOptions.map(option => ({
          ...option,
          type:
            option.payload?.id && disabledOfficeIds?.includes(option.payload.id)
              ? 'DISABLED'
              : null,
        }))}
        selectedOptionIdx={selectedOfficeIdx}
        onChange={({ option }) => {
          const nextOfficeId = option.payload?.id ?? null;
          onChange({ officeId: nextOfficeId, userId: null });
        }}
        label="Vestiging"
        required
      />

      <Dropdown
        dataTestId={TEST_ID.ASSIGNED_USER_DROPDOWN}
        options={userOptions}
        selectedOptionIdx={selectedUserIdx}
        onChange={({ option }) => {
          const nextUserId = option.payload?.id ?? null;
          onChange({ officeId, userId: nextUserId });
        }}
        label="Gebruiker"
      />
    </JustificationContainer>
  );
};

export default AssignBlock;
