import React from 'react';
import styled, { css } from 'styled-components';
import SlideComponent from '../SlideComponent';
import type { Direction } from '~/styles/constants';

export type Props = {
  /**
   * Direction of insertion. The opposite is the exit direction.
   *
   * Default: 'top'
   */
  direction?: Direction;

  /**
   * Label to animate.
   */
  label: JSX.Element;
};

/**
 * Animates the switching between one / multiple labels.
 *
 * Labels can be anything, but should be rendered in one line.
 * If you apply padding or borders make sure to translateY to
 * ensure that it is rendered in the center.
 *
 * The height of this component is set to 0px to ensure no
 * flickering / resizing of the parent component.
 */
const AnimatedLabel: React.FCC<Props> = ({
  dataTestId,
  label,
  direction = 'top',
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <SlideComponent isVisible={label != null} direction={direction || 'top'}>
      {label}
    </SlideComponent>
  </Container>
);

const Container = styled.div<{}>(
  () => css`
    position: relative;
    height: 0px;
    white-space: nowrap;
  `,
);

export default AnimatedLabel;
