import { useEffect, useRef } from 'react';
import { useGetSessionHydrationQuery } from '~/graphql/types';
import type { ToAddressOption } from '~/components/page/Automation/v2/components/UpdateAction/components/SendNotification/utils/convertToDropdownOption';
import type { OfficesWithEmails } from '~/components/page/Automation/v2/components/UpdateAction/components/SendNotification/reducer';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useBuilderContext from '../../../../../Builder/hooks/useBuilderContext';

type Args = {
  /** selected options in the action */
  selectedOptions: Array<ToAddressOption>;

  /** callback to update office label and valid state */
  onUpdate: (offices: OfficesWithEmails) => void;
};

/**
 * Custom hook for polling session hydration based on selected options.
 * It starts polling when there are selected offices without an email
 * and updates the action state when an email gets added to the office.
 */
const usePollingForOfficeEmail = ({ selectedOptions, onUpdate }: Args) => {
  const { id: accountId } = useCurrentAccount();
  const { offices } = useBuilderContext();

  const isPolling = useRef(false);

  const { startPolling, stopPolling } = useGetSessionHydrationQuery({
    variables: { accountId },
  });

  const currentOfficesWithEmails: OfficesWithEmails = offices.reduce(
    (prev, o) => {
      if (o.email) prev[o.id] = { name: o.name, email: o.email };
      return prev;
    },
    {},
  );

  const hasMissingEmails =
    selectedOptions.filter(
      ({ payload: { value } }) =>
        value.officeId != null &&
        currentOfficesWithEmails[value.officeId] == null,
    ).length > 0;

  const onStopPolling = () => {
    if (isPolling.current) {
      stopPolling();
      isPolling.current = false;
    }
  };

  useEffect(() => {
    if (hasMissingEmails && !isPolling.current) {
      isPolling.current = true;
      startPolling(2000);
    } else {
      onStopPolling();
    }

    return () => onStopPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMissingEmails]);

  // We use a string because memoizing currentOfficesWithEmails causes infinite calls to useEffect
  const dep = JSON.stringify(currentOfficesWithEmails);

  useEffect(() => {
    onUpdate(currentOfficesWithEmails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep]);
};

export default usePollingForOfficeEmail;
