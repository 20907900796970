import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import React, { useEffect } from 'react';
import { useGetFlowV2Query } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderWithContext from '../BuilderWithContext';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';
import getFlowActionsToClientActions from '../../util/getFlowActionsToClientActions';
import { useSetRecoilState } from 'recoil';
import { flowActions } from '../../state/actions';
import NotFound from '~/components/page/404';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import Catalog from '~/Catalog';
import createPageTitle from '~/util/createPageTitle';
import { initialFlowState } from '../../state';

const text = {
  pageTitle: 'Flow niet gevonden!',
  title: 'Flow niet gevonden!',
  explanation:
    'Helaas kunnen we deze flow niet vinden, waarschijnlijk is deze flow al verwijderd.',
  buttonText: 'Terug naar overzicht',
};

type Props = RouteComponentProps<{ id: string }>;

const UpdateFlowBuilder: React.FCC<Props> = ({ id }) => {
  const account = useCurrentAccount();
  const { data, loading, error } = useGetFlowV2Query({
    variables: {
      id: id as string,
      accountId: account.id,
    },
  });

  const setActions = useSetRecoilState(flowActions);

  const setInitialFlow = useSetRecoilState(initialFlowState);

  useEffect(() => {
    if (data?.getFlowV2) {
      const clientActions = getFlowActionsToClientActions(
        data?.getFlowV2.Actions ?? [],
      );
      setActions(clientActions);

      const initialFlow = data?.getFlowV2;
      if (initialFlow) {
        setInitialFlow({
          flowName: initialFlow.name,
          flowDescription: initialFlow.description || null,
          enabled: initialFlow.enabled,
          maximumFlowRun: initialFlow.maximumFlowRun || null,
          actions: clientActions,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getFlowV2, setActions]);

  if (error && error.message.includes('Cannot find flow with id')) {
    return (
      <JustificationContainer
        width="100%"
        padding={['xl']}
        justification="center"
        align="center"
      >
        <NotFound
          pageTitle={text.pageTitle}
          title={text.title}
          explanation={text.explanation}
          buttonText={text.buttonText}
          redirectTo={'/-/automation/flows'}
        />
      </JustificationContainer>
    );
  }
  if (error) {
    return (
      <JustificationContainer
        width="100%"
        padding={['xl']}
        justification="center"
        align="center"
      >
        <AppErrorScreen
          setBackgroundColor={false}
          message={Catalog.genericUnknownErrorMessage}
        />
      </JustificationContainer>
    );
  }

  if (loading || !data?.getFlowV2 || !data.getFlowV2.id)
    return (
      <>
        <MetaTags>
          <title>{createPageTitle('Laden...')}</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  return <BuilderWithContext flowId={data.getFlowV2.id} />;
};

export default withRouterTransitionContext(UpdateFlowBuilder);
