import React from 'react';
import { animated, CSS } from 'react-spring';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import { Body, Heading4, Label } from '~/components/atom/Typography';
import TextButton from '~/components/atom/TextButton';
import Tooltip from '~/components/molecule/Tooltip';
import Icon, { IconType } from '~/components/atom/Icon';
import useHover from '~/hooks/useHover';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Variant = 'success' | 'danger' | 'primary' | 'warning';

export type Props = {
  heading: string;
  description?: React.ReactNode;
  token?: string;
  variant?: Variant;
  disabled?: boolean;
  style?: typeof CSS;
  cancelText?: string;
  tooltipText?: string;
  settings?: React.ReactNode;
  warnings?: Array<string | React.ReactNode> | null;
  onDelete?: () => void;
};

const APIBlock: React.FCC<Props> = ({
  dataTestId,
  heading,
  description,
  token,
  cancelText,
  onDelete,
  tooltipText,
  variant = 'primary',
  disabled = false,
  settings,
  warnings,
  ...rest
}) => {
  const [showTooltip, tooltipProps] = useHover();

  return (
    <Container
      data-testid={dataTestId}
      data-variant={variant}
      $variant={variant}
      $disabled={disabled}
      {...rest}
    >
      <JustificationContainer
        width="100%"
        justification="space-between"
        align="center"
      >
        <Heading4 margin={[null]} color={{ group: variant }}>
          {heading}
        </Heading4>
        {settings}
      </JustificationContainer>
      {token && <Token>{token}</Token>}
      <JustificationContainer width="100%" height="100%">
        {description && (
          <>
            <Icon
              name={getIconNameForVariant(variant)}
              background={{ group: variant }}
              color={{ group: 'white' }}
            />
            <Body
              margin={[
                'xxxs',
                null,
                onDelete && cancelText ? 'xxs' : null,
                'xs',
              ]}
              size="base"
            >
              {description}
            </Body>
          </>
        )}

        <JustificationContainer
          direction="column"
          gap="xxs"
          margin={[null, null, 'xxs', null]}
        >
          {warnings &&
            warnings?.length > 0 &&
            warnings.map((warning, index) => (
              <JustificationContainer
                key={`warning-${index}`}
                align="center"
                gap="xxs"
              >
                <Icon
                  name="exclamation"
                  color={{ group: 'warning' }}
                  strokeWidth={2.5}
                  margin={[null]}
                />
                <Label
                  size="base"
                  color={{ group: 'warning', variant: 'dark' }}
                  margin={[null]}
                >
                  {warning}
                </Label>
              </JustificationContainer>
            ))}
        </JustificationContainer>
      </JustificationContainer>

      {onDelete && cancelText && (
        <>
          {showTooltip && tooltipText && (
            <StyledTooltip>{tooltipText}</StyledTooltip>
          )}
          <TextButton
            onClick={onDelete}
            label={cancelText}
            appearance="danger"
            padding={[null]}
            dataTestId={TEST_ID.DELETE_TOKEN}
            disabled={disabled}
            {...tooltipProps}
          />
        </>
      )}
    </Container>
  );
};

const getIconNameForVariant = (variant: Variant): IconType => {
  switch (variant) {
    case 'primary':
      return 'clock';
    case 'danger':
      return 'error';
    default:
      return 'check';
  }
};

const Token = styled(JustificationContainer)(
  ({ theme }) => css`
    color: ${theme.color('tertiary', 'dark')};
  `,
);

const StyledTooltip = styled(Tooltip)<{}>`
  top: 0;
`;

const Container = styled(animated.div)<{
  $variant: Variant;
  $disabled: boolean;
}>(
  ({ theme, $variant, $disabled }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space('xs')};

    background-color: ${theme.color($variant, 'translucent')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.m};
    opacity: ${$disabled ? '0.7' : 1};
    cursor: ${$disabled ? 'not-allowed' : 'default'};
  `,
);

export default APIBlock;
