import { lensIndex, over } from 'ramda';
import { selector, selectorFamily } from 'recoil';
import type { FormBuilder_OptionListFragment } from '~/graphql/types';
import isRecoilDefaultValue from '~/typeguards/isRecoilDefaultValue';
import { formState } from '.';

export const optionListsState = selector<Array<FormBuilder_OptionListFragment>>(
  {
    key: 'formBuilder/optionListsSelector',
    get: ({ get }) => {
      const form = get(formState);

      return form?.optionLists ?? [];
    },

    set: ({ get, set }, optionLists) => {
      if (isRecoilDefaultValue(optionLists)) return;

      const prevForm = get(formState);
      set(formState, { ...prevForm, optionLists });
    },
  },
);

export const optionListById = selectorFamily<
  FormBuilder_OptionListFragment | null,
  string
>({
  key: 'formBuilder/optionListsSelector',
  get:
    id =>
    ({ get }) => {
      const form = get(formState);
      const found = (form?.optionLists ?? []).find(list => list.id === id);

      return found ?? null;
    },

  set:
    id =>
    ({ get, set }, optionList) => {
      if (isRecoilDefaultValue(optionList)) return;
      const prevForm = get(formState);
      if (!prevForm) return;

      const index = (prevForm?.optionLists ?? []).findIndex(
        list => list.id === id,
      );
      const lens = lensIndex(index);

      const updatedOptionLists = over(
        lens,
        () => optionList,
        prevForm?.optionLists,
      );

      set(formState, { ...prevForm, optionLists: updatedOptionLists });
    },
});
