import { navigate, useLocation, WindowLocation } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import { agendaHeader } from '~/components/organism/WizardSteps/Apps/Realworks/RealworksAgenda';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import type { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import { LINK_PREFIX, type StepProps } from '../..';
import ImportAgendaStepBody from './components/ImportAgendaStepBody';
import TEST_ID from './index.testid';

const ImportAgendaStep: React.FCC<StepProps> = ({ onCancel }) => {
  const [agenda, setAgenda] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['agenda']>(null);

  // @ts-ignore WindowLocation<S = unknown>. S is the type of state but we cannot type it ourselves because the types come from types/reach__router
  const location: WindowLocation<{
    values: UpdateRealworksAppStatus_AddTokenContainer__Input;
  }> = useLocation();

  const locationValues = location?.state?.values || {};

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    void navigate(`${LINK_PREFIX}/activation-pending`, {
      replace: true,
      state: {
        values: {
          ...locationValues,
          agenda,
          wonen: null,
        },
      },
    });
  };

  const skipCurrentStep = () =>
    navigate(`${LINK_PREFIX}/activation-pending`, {
      replace: true,
      state: {
        values: {
          ...locationValues,
          wonen: null,
          agenda: null,
        },
      },
    });

  return (
    <AppDetailsContainer
      header={agendaHeader}
      icon="calendar"
      dataTestId={TEST_ID.CONTAINER}
    >
      <ImportAgendaStepBody
        // This is the first step, no tokens to validate against
        tokens={[]}
        onSubmit={onSubmit}
        navigationFooter={{
          onCancel,
        }}
        skipStepBlock={{
          usage: 'app',
          onSkip: skipCurrentStep,
        }}
        value={agenda}
        onChange={value => setAgenda(value)}
      />
    </AppDetailsContainer>
  );
};

export default ImportAgendaStep;
