import StyleToJS from 'style-to-js';
import type { MarkStyles } from '../../components/Buttons/Mark';
import { fontWeight } from '~/theme/System/tokens';

/**
 * This function is used to parse style attributes to slate marks for the custom elements
 * @param styleString - The style string to parse
 * @returns The slate marks
 */
const parseStyleAttributesToSlateMarks = (
  styleString?: any,
): { [key in MarkStyles]?: boolean } => {
  if (!styleString || typeof styleString !== 'string') return {};
  const styleObject: {
    [key in MarkStyles]?: boolean;
  } = {};
  const styleAttributes = StyleToJS(styleString);

  if (
    styleAttributes.fontWeight === 'bold' ||
    styleAttributes.fontWeight === `${fontWeight.bold}`
  ) {
    styleObject.bold = true;
  }

  if (styleAttributes.fontStyle === 'italic') {
    styleObject.italic = true;
  }

  return styleObject;
};

export default parseStyleAttributesToSlateMarks;
