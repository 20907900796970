import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { v4 as uuid } from 'uuid';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  VariableElement,
  type DHEditor,
} from '~/components/organism/PluginsEditor/types';

const insertVariable = (editor: DHEditor) => {
  const marks = Editor.marks(editor);
  const dhVariable: VariableElement = {
    type: ELEMENTS.VARIABLE,
    variableName: '...',
    variableType: undefined,
    /** Add as pending because we do not know tha name of the variable yet */
    pending: true,
    mappingId: uuid().replace(/-/g, ''),
    children: [{ text: '', ...marks }],
  };

  Transforms.insertNodes(editor, dhVariable);
  ReactEditor.focus(editor);
  Transforms.move(editor, {
    distance: 1,
    unit: 'offset',
  });
};

export default insertVariable;
