import React, { useContext, useRef, useState } from 'react';
import type { Option, SelectedOption } from '~/components/molecule/Dropdown';
import {
  ConnectorOperator,
  GetContactsQueryVariables,
  useGetContactsQuery,
} from '~/graphql/types';

import asNodeInput from '~/components/organism/Filters/asNodeInput';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TaskModalContext from '~/components/page/Tasks/context/TaskModalContext';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { Heading3 } from '~/components/atom/Typography';
import Catalog from '~/Catalog';
import StatusContainer from '~/components/organism/StatusContainer';
import JustificationContainer from '~/components/atom/JustificationContainer';
import DebouncedInput from '~/components/molecule/DebouncedInput';

const text = {
  heading: 'Contact toevoegen',
  inputLabel: 'Contact zoeken',
  inputPlaceholder: 'Typ hier contactnaam',
  error:
    'Er is iets misgegaan bij het zoeken van een contact. Probeer het opnieuw. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

type Props = {
  error?: string;
  onResetError: () => void;
};

const ContactSearch = ({ error, onResetError }: Props) => {
  const { updateTaskDetails } = useContext(TaskModalContext);
  const { id: accountId } = useCurrentAccount();

  const [variables, setVariables] = useState<GetContactsQueryVariables>({
    nodes: asNodeInput({ filters: [], operator: ConnectorOperator.And }),
    accountId,
    query: null,
  });

  const [showDropdownList, setShowDropdownList] = useState(false);
  const inputContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    data,
    error: queryError,
    loading,
  } = useGetContactsQuery({
    variables,
    // initially it is null so don't run the query the first time
    skip: variables.query === null,
    onCompleted: () => setShowDropdownList(true),
    onError: () => setShowDropdownList(false),
  });

  const hasError = Boolean(error || queryError);

  const options: Array<Option> = (() => {
    if (loading || !data) return [];

    const contactResults = data == null ? [] : data.getContacts.items;

    if (contactResults.length > 0)
      return contactResults.map(contact => ({
        label: contact.name,
        payload: contact,
        key: contact.id,
      }));

    return [
      {
        label: Catalog.noResults,
        payload: null,
        key: 'no-result',
        disabled: true,
        type: 'DISABLED',
      },
    ];
  })();

  const onSelect = (selectedOption: SelectedOption) => {
    const { option } = selectedOption;
    if (option && option.payload && option.payload.id) {
      const { id, name, email } = option.payload;

      updateTaskDetails({
        contactId: id,
        Contact: {
          id,
          name,
          email,
        },
      });
    }

    setShowDropdownList(false);
  };

  const onSearch = value => {
    setVariables(prevVariables => ({
      ...prevVariables,
      query: value,
    }));
  };

  return (
    <JustificationContainer
      direction="column"
      gap="base"
      width="100%"
      data-testid="task-modal-contact-search"
    >
      <Heading3>{text.heading}</Heading3>

      <DebouncedInput
        label={{ text: text.inputLabel }}
        onDebouncedChange={value => {
          if (error) onResetError();
          onSearch(value);
        }}
        onFocus={() => {
          if (!data) onSearch('');
          if (!hasError) {
            setShowDropdownList(true);
          }
        }}
        width="100%"
        placeholder={text.inputPlaceholder}
        icon={{ name: 'search' }}
        appearance={hasError ? 'danger' : undefined}
        externalErrors={error ? [error] : undefined}
        inputContainerRef={inputContainerRef}
        dataTestId="search-field"
      />

      <DropdownListContainer
        dataTestId="contact-search-dropdown-list"
        options={options}
        dropdownListOpen={showDropdownList}
        onChange={onSelect}
        onClose={() => setShowDropdownList(false)}
        onClickOutside={() => setShowDropdownList(false)}
        openerRef={inputContainerRef}
        loading={loading}
      />

      {queryError && <StatusContainer level="danger" label={text.error} />}
    </JustificationContainer>
  );
};

export default ContactSearch;
