import React, { useRef } from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import styled, { css } from 'styled-components';
import { LinkElement } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serialize from './serialize';
import TEST_ID from './index.testid';
import { hoveringToolbarState } from '../../../state/HoveringToolbarState';
import { useSetRecoilState } from 'recoil';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Props = RenderElementProps & {
  element: LinkElement;
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: '0' }}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const Link: React.FCC<Props> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const errorReporter = useErrorReporter();
  const active = selected && focused;
  const ref = useRef<HTMLSpanElement>(null);
  const setHoveringToolbar = useSetRecoilState(hoveringToolbarState);

  return (
    <Container
      {...attributes}
      {...element.attributes}
      style={{
        ...element?.attributes?.style,
        display: 'inline',
      }}
      href={element.url}
      data-testid={TEST_ID.CONTAINER}
      onClick={e => {
        e.stopPropagation();

        if (!ref.current) {
          errorReporter.captureException(
            new Error('LinkElement ref.current is not defined'),
            'error',
          );
          return;
        }

        setHoveringToolbar({ element, elementRef: ref.current });
      }}
      $active={active}
    >
      <span ref={ref}>
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </span>
    </Container>
  );
};

const Container = styled.a<{ $active: boolean }>(
  ({ theme, $active }) => css`
    font-weight: ${$active ? theme.fontWeight('semiBold') : 'auto'};
  `,
);

export default {
  nodeName: 'A',
  renderComponent: props => <Link {...props} />,
  deserialize: el => ({
    type: ELEMENTS.LINK,
    url: el.getAttribute('href'),
  }),
  serialize,
};
