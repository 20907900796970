import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import icons from '~/components/atom/Icon/components';
import { Body, Label, Variant } from '~/components/atom/Typography';
import JustificationContainer, {
  Justification,
} from '~/components/atom/JustificationContainer';
import useTipBanner from './hooks/useTipBanner';
import { animated, useTransition } from 'react-spring';
import { SystemSize } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';
import { ANIMATION_CONFIG } from '~/styles/constants';

export type TipId =
  | string
  | 'realworks-agenda-tip'
  | 'realworks-connections-tip'
  | 'export-contacts-step-tip'
  | 'import-contacts-step-tip'
  | 'setup-step-id'
  | 'zapier-details-tip'
  | 'events-tip'
  | 'triggers-tip'
  | 'add-event-tip'
  | 'add-trigger-tip'
  | 'field-editing-section-tip'
  | 'contact-segments-tip'
  | 'trustoo-add-token'
  | 'generic-wizard'
  | 'add-widget-script-onboarding'
  | 'sync-email-step';

export type Props = {
  id: TipId;

  children: React.ReactNode;
  icon?: keyof typeof icons;
  headerText?: string;
  withoutBorder?: boolean;
  /* Can the user dismiss this tip? defaults to true */
  dismissible?: boolean;
  margin?: Array<SystemSize | null>;
  /** Justify dismiss tip button */
  dismissButtonPosition?: Justification;
  /** Font size */
  fontSize?: SystemSize;
  width?: string;
};

const text = {
  dismissTip: 'Sluit deze tip',
};

const TipBanner: React.FCC<Props> = ({
  dataTestId,
  id,
  children,
  icon = 'question-mark',
  headerText = 'Hulp nodig?',
  withoutBorder = false,
  dismissible = true,
  dismissButtonPosition = 'end',
  margin = [null, null, 'm', null],
  fontSize = 'base',
  width = 'auto',
  ...rest
}) => {
  const [showTip, onDismiss] = useTipBanner(id);

  const transitions = useTransition(showTip, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    config: { ...ANIMATION_CONFIG.config },
  });

  return (
    <>
      {transitions(
        (style, item) =>
          item && (
            <animated.div key={id} style={{ ...style, width }}>
              <Container
                $margin={margin}
                data-testid={dataTestId}
                $withoutBorder={withoutBorder}
                $width={width}
                {...rest}
              >
                <TipHeader align="center" justification="start">
                  <StyledIcon name={icon} strokeWidth={3} />
                  <Label variant={Variant.primary} margin={[null]} size="base">
                    {headerText}
                  </Label>
                </TipHeader>

                <Body size={fontSize} margin={[null]}>
                  {children}
                </Body>

                {dismissible && (
                  <JustificationContainer justification={dismissButtonPosition}>
                    <DismissTip onClick={onDismiss} $size={fontSize}>
                      {text.dismissTip}
                    </DismissTip>
                  </JustificationContainer>
                )}
              </Container>
            </animated.div>
          ),
      )}
    </>
  );
};

const SVG_SIZE = '1.2em';

const Container = styled(animated.div)<{
  $withoutBorder: boolean;
  $margin?: Array<SystemSize | null>;
  $width: string;
}>(
  ({
    theme,
    $withoutBorder,
    $margin = [null, null, 'base', null],
    $width,
  }) => css`
    border: ${$withoutBorder ? 'none' : `1px solid ${theme.color('tertiary')}`};
    padding: ${theme.space('m')};
    transition: all 0.3s ease;
    margin: ${arrayToCss($margin, theme)};
    width: ${$width};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

const DismissTip = styled.span<{ $size?: SystemSize }>(
  ({ theme, $size = 'base' }) => css`
    color: ${theme.color('primary', 'light')};
    font-weight: ${theme.fw('semiBold')};
    margin-top: ${theme.space('xxs')};
    font-size: ${theme.fontSize($size)};
    cursor: pointer;
    transition: all 0.3s ease-out;

    &:hover {
      color: ${theme.color('primary', 'dark')};
    }
  `,
);

const TipHeader = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    padding-bottom: ${theme.space('xxs')};
  `,
);

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    padding-bottom: ${theme.space('xxxs')};
    padding-right: ${theme.space('s')};

    svg {
      height: ${SVG_SIZE};
      width: ${SVG_SIZE};
    }
  `,
);

export default TipBanner;
