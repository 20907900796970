import ELEMENTS from './components/elements/elementsEnum';
import type { EditorValue } from './types';

/**
 * Attribute names for the inline images
 *
 * Inline images inserted as:
 *
 * <img src="" data-dhfilename="<filename>" data-dhinlineid="<inlineId>" data-s3key="<s3key>" data-dhcontenttype="image/png" data-dhcontentlength="24000" />
 */
export const INLINE_IMAGE_ID_ATTR = 'data-dhinlineid';
export const INLINE_IMAGE_FILENAME_ATTR = 'data-dhfilename';
export const INLINE_IMAGE_S3KEY_ATTR = 'data-s3key';
export const INLINE_IMAGE_CONTENT_TYPE_ATTR = 'data-dhcontenttype';
export const INLINE_IMAGE_CONTENT_LENGTH_ATTR = 'data-dhcontentlength';

/** Returns the id in [[inlineId:...]] */
export const INLINE_ID_FROM_IMAGE_SRC = /src="\[\[inlineId:(.*?)\]\]"/;
export const INLINE_IMAGE_FROM_BACKEND_REGEX =
  /<img[^>]*?src="\[\[inlineId:.*?\]\].*?"[^>]*?>/g;

/**
 * Matches a dhvariable, including the curly braces: {{123}}.
 * Optionally matches a surrounding <span> tag.
 */
export const TEMPLATE_STRING_VARIABLE_REGEX =
  /(?:<span([^>]*)>)?{{(.+?)}}(?:<\/span>)?/g;

/**
 * Matches deep links, including the delimiters _% %_.
 * Optionally matches a surrounding <span> tag.
 */
export const TEMPLATE_STRING_DEEP_LINK_REGEX =
  /(?:<span([^>]*)>)?_%(.+?)%_(?:<\/span>)?/g;

/** Matches everything inside double quotes including src="..." */
export const SRC_REGEX = /src="[^"]*?"/g;

/** Used when the deep link config is not available anymore */
export const MISSING_DEEP_LINK_CONFIG_ID = 'missing-deep-link-config-id';

/** Used when sanitizing html */
export const allowedTags = [
  'a',
  'acronym',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'bdo',
  'big',
  'blockquote',
  'br',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'dd',
  'del',
  'details',
  'dfn',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'footer',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hr',
  'i',
  'iframe',
  'img',
  'ins',
  'kbd',
  'legend',
  'li',
  'map',
  'nav',
  'object',
  'ol',
  'p',
  'param',
  'picture',
  'pre',
  'q',
  'rp',
  'rt',
  'ruby',
  'samp',
  'section',
  'small',
  'span',
  'strike',
  'strong',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'time',
  'tr',
  'track',
  'tt',
  'u',
  'ul',
  'var',
  'video',
  'dhsignature',
  'dhimmutable',
  'deeplink',
];

export const EMPTY_EDITOR_VALUE: EditorValue = [
  {
    type: ELEMENTS.DIV,
    children: [{ text: '' }],
  },
];
