import React from 'react';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading5, Variant } from '~/components/atom/Typography';

const text = {
  officeLabel: 'Vestiging',
  officeOwnerLabel: 'Stuur naar de vestiging van het contact',
  fallbackLabel: 'Vestiging (indien het contact geen vestiging heeft)',
  noOfficeSelected: 'Vestiging niet geselecteerd',
};

export type Props = {
  dataTestId?: string;
  className?: string;
  shouldSendToOwner: boolean;
  tokenName?: string;
};

const CardContent: React.FCC<Props> = ({ shouldSendToOwner, tokenName }) => {
  if (shouldSendToOwner) {
    return (
      <>
        <JustificationContainer
          direction="row"
          align="center"
          gap="xs"
          margin={[null, null, 's']}
        >
          <Icon name="user" strokeWidth={2} />
          <Body margin={[null]} size="m">
            {text.officeOwnerLabel}
          </Body>
        </JustificationContainer>

        <Heading5 variant={Variant.primary}>{text.fallbackLabel}</Heading5>

        {tokenName && <Body>{tokenName}</Body>}
      </>
    );
  }

  return (
    <>
      <Heading5 inline variant={Variant.primary}>
        {text.officeLabel}
      </Heading5>
      <Body>{tokenName || '-'}</Body>
    </>
  );
};

export default CardContent;
