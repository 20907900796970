import React from 'react';

import { ReactEditor } from 'slate-react';
import {
  DHEditor,
  LinkElement,
} from '~/components/organism/PluginsEditor/types';
import { Path } from 'slate';
import { insertLink } from '~/components/organism/PluginsEditor/commands/modify/link';
import { deleteNodeAtPath } from '~/components/organism/PluginsEditor/commands';
import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';
import EditUrlBlock from '~/components/organism/PluginsEditor/components/Buttons/InsertLink/components/EditUrlBlock';
import { useSetRecoilState } from 'recoil';
import { hoveringToolbarState } from '~/components/organism/PluginsEditor/state/HoveringToolbarState';

const text = {
  heading: 'Link bijwerken',
};

export type Props = {
  element: LinkElement;
  editor: DHEditor;
  path: Path;
};

const LinkToolbar: React.FCC<Props> = ({ element, path, editor }) => {
  const urlText = element.children[0].text;

  const setHoveringToolbar = useSetRecoilState(hoveringToolbarState);

  return (
    <EditUrlBlock
      onSave={({ url, text }) => {
        // We cannot edit the children of a node directly so we delete it and re-insert it
        deleteNodeAtPath(editor, path);
        insertLink(editor, url, text);
        ReactEditor.blur(editor);
        setHoveringToolbar(null);
      }}
      urlValue={element.url}
      textValue={urlText || ''}
      headingText={text.heading}
    >
      <Button
        ghost
        icon="trashcan"
        appearance="danger"
        onClick={() => {
          deleteNodeAtPath(editor, path);
          setHoveringToolbar(null);
        }}
        dataTestId={TEST_ID.DELETE_BUTTON}
      />
    </EditUrlBlock>
  );
};

export default LinkToolbar;
