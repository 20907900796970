import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import useDebouncedState from '~/hooks/useDebouncedState';
import { TRIANGLE_HEIGHT } from '~/components/organism/Selector';
import Portal from '~/components/molecule/Portal';
import useMeasure from '~/hooks/useMeasure';
import useOutsideClick from '~/hooks/useClickOutside';

const SELECTOR_WIDTH = 600;
const MIN_LEFT_OFFSET = 50;
const RIGHT_OVERFLOW_ADJUSTMENT = 150;

type PointerLocation = 'top' | 'bottom' | null;

export type Props = {
  dataTestId?: string;

  /** Button or container clicked to open the Selector */
  parentRef: HTMLElement | null;

  /** Function to close the selector */
  onClose: () => void;

  /** Children that receive the positioning props */
  children?: React.ReactNode;
};

const PositionWrapperV2: React.FC<Props> = ({
  parentRef: relativeToElementRef,
  children,
  dataTestId,
  onClose,
}) => {
  const selectorRef = useRef<HTMLDivElement>(null);
  const { bounds: ownContainerBounds, ref: ownContainerRef } = useMeasure();

  useOutsideClick(ownContainerRef, onClose, 'dropdown-portal');

  const [pointerOffset, setPointerOffset] = useState(0);
  const [pointerLocation, setPointerLocation] = useState<PointerLocation>(null);

  /** Used to force a re-render when the relativeToElementRef changes */
  const [renderKey, setRenderKey] = useState(0);

  /** Triggers a re-render - important to handle resize events  */
  const [renderId, setRenderId] = useDebouncedState(0, { delay: 200 });

  const [leftOffset, setLeftOffset] = useState<number | null>(null);
  const [moveSelector, setMoveSelector] = useState<number | null>(null);

  useEffect(() => {
    const handleResize = () => setRenderId(Date.now());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setRenderId]);

  /** Forces a re-render when the relativeToElementRef changes */
  useEffect(() => {
    setRenderKey(renderKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relativeToElementRef]);

  /** Calculate the position of the selector */
  useEffect(() => {
    if (!relativeToElementRef) return;
    if (!ownContainerRef.current) return;

    const relativeToElementBB = relativeToElementRef?.getBoundingClientRect();
    const spaceBelowRelativeElement =
      window.innerHeight - relativeToElementBB.bottom;

    /** Calculate vertical position */

    const renderBelow = relativeToElementBB.top < spaceBelowRelativeElement;

    setPointerLocation(renderBelow ? 'top' : 'bottom');

    const newMoveSelector = renderBelow
      ? relativeToElementBB.top + relativeToElementBB.height + TRIANGLE_HEIGHT
      : spaceBelowRelativeElement +
        relativeToElementBB.height +
        TRIANGLE_HEIGHT;
    setMoveSelector(newMoveSelector);

    /** Calculate horizontal position */

    const overflowRight =
      relativeToElementBB.left + SELECTOR_WIDTH > window.innerWidth;
    const idealOffset =
      relativeToElementBB.left -
      SELECTOR_WIDTH / 2 +
      relativeToElementBB.width / 2;

    /** If it overflows on the right move it to left a little bit */
    const idealOffsetWithOverflow =
      idealOffset - (overflowRight ? RIGHT_OVERFLOW_ADJUSTMENT : 0);

    /** Final offset to move the selector to the left */
    const newLeftOffset = Math.max(idealOffsetWithOverflow, MIN_LEFT_OFFSET);

    const pointerOffset =
      relativeToElementBB.left - newLeftOffset + relativeToElementBB.width / 2;

    setLeftOffset(newLeftOffset);
    setPointerOffset(pointerOffset);
  }, [
    relativeToElementRef,
    ownContainerRef,
    renderId,
    ownContainerBounds.height,
  ]);

  if (!relativeToElementRef) {
    return <></>;
  }

  return (
    <Portal root="condition-editor">
      <RenderReferenceContainer
        id="RenderReferenceContainer"
        ref={ownContainerRef}
        style={{
          left: leftOffset ?? undefined,
        }}
        $openingDirection={pointerLocation}
        $moveSelector={moveSelector}
      >
        {pointerLocation && (
          <Container
            data-testid={dataTestId}
            $openingDirection={pointerLocation}
          >
            <React.Fragment key={renderKey}>
              {React.Children.map(
                children,
                child =>
                  React.isValidElement(child) &&
                  React.cloneElement(child, {
                    ...child.props,
                    selectorRef,
                    pointerLocation,
                    pointerOffset,
                  }),
              )}
            </React.Fragment>
          </Container>
        )}
      </RenderReferenceContainer>
    </Portal>
  );
};

const appear = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

/**
 * Rendered first to get the ref etc.
 */
const RenderReferenceContainer = styled.div<{
  $openingDirection: 'top' | 'bottom' | null;
  $moveSelector: number | null;
}>(
  ({ $moveSelector, $openingDirection, theme }) => css`
    position: absolute;
    width: ${SELECTOR_WIDTH}px;
    z-index: ${theme.getTokens().zIndex.top};
    display: flex;

    ${() => {
      if ($moveSelector === null) return;

      switch ($openingDirection) {
        case 'bottom':
          return css`
            flex-direction: column-reverse;
            bottom: ${$moveSelector}px;
            transform-origin: bottom;
          `;

        case 'top':
          return css`
            top: ${$moveSelector}px;
            transform-origin: top;
          `;
        default:
          return;
      }
    }};
  `,
);

const Container = styled.div<{
  $openingDirection: 'top' | 'bottom' | null;
}>(
  ({ $openingDirection }) => css`
    width: 100%;
    height: 100%;
    line-height: normal;
    display: flex;
    flex-direction: column;
    animation-name: ${appear};
    animation-duration: 200ms;
    animation-timing-function: ease-out;

    ${$openingDirection === 'bottom' &&
    css`
      flex-direction: column-reverse;
    `}
  `,
);

export default PositionWrapperV2;
