import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { FlowAction, useGetAppStatusRealworksQuery } from '~/graphql/types';
import { ClientFlowActionRealworksSendContact } from '~/graphql/types.client';
import useApp from '~/hooks/useApp';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import getActiveTokenOptions from '~/util/getActiveTokenOptions';
import getCardHeading from '../../../../utils/getCardHeading';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import CardContent from './components/CardContent';
import IssuesForAction from '../IssuesForAction';

export type Props = Omit<
  CardProps<ClientFlowActionRealworksSendContact>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const SendRealworksContactActionCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const { id: accountId } = useCurrentAccount();

    const { data: realworksData } = useGetAppStatusRealworksQuery({
      variables: {
        accountId,
      },
    });

    const { app } = useApp('AppStatus_Realworks');

    const realworksStatus = realworksData?.getAppStatusRealworks;
    const { tokenContainerId } = data.action;

    const tokenOptions = realworksStatus
      ? getActiveTokenOptions(realworksStatus)
      : [];

    const tokenName = tokenOptions.find(
      option => option.payload == tokenContainerId,
    )?.label;

    const realworksCardData = {
      ...data,
      action: {
        ...data.action,
        appSlug: app?.slug ? `/-/apps/setup/${app.slug}` : null,
      },
    };

    const shouldSendToOwner = data.action.sendToContactOwner === true;

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.RealworksSendContact}
          heading={getCardHeading(FlowAction.RealworksSendContact)}
          selected={selected}
          disabled={disabled}
          data={realworksCardData}
        >
          <JustificationContainer direction="column">
            <WithMargins>
              <CardContent
                shouldSendToOwner={shouldSendToOwner}
                tokenName={tokenName}
              />
            </WithMargins>
            <IssuesForAction actionId={data.action.id} />
          </JustificationContainer>
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const WithMargins = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default SendRealworksContactActionCard;
