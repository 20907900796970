import { isEmpty, isNil } from 'ramda';
import { StyleBlockFE, type QueriedStyleBlock } from '../../types';
import type { PrimitiveStyleBlock } from '~/graphql/types';

/**
 * Nest the blocks we get from backend
 *
 * keywords: event body, convertBlocks, backendShape, to frontendShape
 */
const addBlocks = (
  blocks: Array<QueriedStyleBlock | StyleBlockFE>,
  parentId: string,
): StyleBlockFE | null => {
  const item = blocks.find(item => item.id === parentId);

  if (!item) {
    return null;
  }

  // item is a primitive block
  if (!('childrenIds' in item)) {
    return item as PrimitiveStyleBlock;
  }

  // item is layout block but childrenIds is null, undefined or empty string
  if (isNil(item.childrenIds) || isEmpty(item.childrenIds)) {
    return { ...item, blocks: [] };
  }

  return {
    ...item,
    blocks: item.childrenIds
      .map(childId => addBlocks(blocks, childId))
      .filter((block): block is StyleBlockFE => !isNil(block)),
  };
};

/**
 * Creates the nested shape for the frontend
 */
const convertToFrontend = (
  body: Array<QueriedStyleBlock>,
): Array<StyleBlockFE> => {
  const rootIds: Array<string> = [];

  body.forEach(b => {
    if (b.id.includes('root')) {
      rootIds.push(b.id);
    }
  });

  const nestedBlocks: Array<StyleBlockFE> = [];

  rootIds.forEach(rootId => {
    const block = addBlocks(body, rootId);
    if (block) nestedBlocks.push(block);
  });

  return nestedBlocks;
};

export default convertToFrontend;
