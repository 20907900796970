import React from 'react';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import type { SystemSize } from '~/theme';

const text = {
  relatieRequiredWarning:
    'Je moet een actieve Relatie API hebben om deze API te activeren.',
};

type Props = {
  margin?: Array<SystemSize | null>;
};

const RelatieTokenWarning: React.FC<Props> = ({
  margin = ['base', null, null, null],
}) => (
  <CallToActionBlock
    icon={{ name: 'exclamation', background: { group: 'warning' } }}
    header={text.relatieRequiredWarning}
    margin={margin}
  />
);

export default RelatieTokenWarning;
