import type { FlowV2_Action__Input } from '~/graphql/types';
import type { ClientFlowAction } from '~/graphql/types.client';
import { isNil } from 'ramda';
import serializeAction from '../../components/Builder/utils/serializeAction';

/**
 * Takes an array of ClientFlowAction, serializes them and filters from null values.
 * Returns an array of serializedActions
 * @param {Array<ClientFlowAction>}: actions
 * keywords: issueMap, serializedActions, actions, serialized
 */
type ReturnType =
  | {
      serializedActions?: undefined;
    }
  | {
      serializedActions: Array<FlowV2_Action__Input>;
    };
const getSerializedActions = ({
  actions,
}: {
  actions: Array<ClientFlowAction>;
}): ReturnType => {
  const serializedActions: Array<FlowV2_Action__Input> = actions
    .map(action => serializeAction(action))
    .filter((action): action is FlowV2_Action__Input => !isNil(action));

  return { serializedActions };
};

export default getSerializedActions;
