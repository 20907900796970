import React from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Label, Variant } from '~/components/atom/Typography';
import { FlowAction, Trickle_PeriodType } from '~/graphql/types';
import { ClientFlowActionTrickle } from '~/graphql/types.client';
import getCardHeading from '../../../../utils/getCardHeading';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import DaysOfWeekContainer from '../../../../../UpdateAction/components/Trickle/components/DaysOfWeekContainer';
import { convertToFullTimeString } from '~/util/time';
import Divider from '~/components/atom/Divider';
import Statistics from '../Statistics';
import IssuesForAction from '../IssuesForAction';
export type Props = Omit<
  CardProps<ClientFlowActionTrickle>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const text = {
  processedOn: 'Alleen verwerken op',
  limits: 'Limieten',
  noRulesDefined: 'Geen regels gedefinieerd',
  noLimitsDefined: 'Geen limieten gedefinieerd',
};

const TrickleCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const hasRules = data.action.rules.length > 0;
    const hasLimits = data.action.limits.length > 0;

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.Trickle}
          heading={getCardHeading(FlowAction.Trickle)}
          selected={selected}
          disabled={disabled}
          data={data}
        >
          <JustificationContainer direction="column" width="100%">
            <div>
              <Heading4
                variant={Variant.primary}
                size="base"
                fontWeight="semiBold"
                margin={[null, null, 's']}
              >
                {text.processedOn}
              </Heading4>
              {hasRules
                ? data.action.rules.map((rule, index) => (
                    <JustificationContainer align="center" key={index}>
                      <DaysOfWeekContainer
                        rule={rule}
                        onUpdateRule={() => {}}
                        size="small"
                      />
                      <Body size="s" margin={[null, 'xxs']}>
                        om
                      </Body>
                      <Label margin={[null]} inline variant={Variant.primary}>
                        {convertToFullTimeString(
                          rule.timeRanges[0].start.hour,
                          rule.timeRanges[0].start.minute,
                        )}
                        -
                        {convertToFullTimeString(
                          rule.timeRanges[0].end.hour,
                          rule.timeRanges[0].end.minute,
                        )}
                      </Label>
                    </JustificationContainer>
                  ))
                : text.noRulesDefined}
            </div>
            <Divider margin={['m', null, 'base']} />
            <div>
              <Heading4 variant={Variant.primary} size="base">
                {text.limits}
              </Heading4>

              {hasLimits ? (
                <StyledList>
                  {data.action.limits.map((limit, index) => (
                    <li key={index}>
                      <Label inline variant={Variant.primary}>
                        {limit.amount}
                      </Label>
                      &nbsp;per&nbsp;
                      <Label inline variant={Variant.primary}>
                        {limit.period.value}
                      </Label>
                      &nbsp;
                      {getTimeUnitString(limit.period.type, limit.period.value)}
                    </li>
                  ))}
                </StyledList>
              ) : (
                text.noLimitsDefined
              )}
            </div>

            {data.action.Statistics && (
              <>
                <Divider margin={['m', null, 'base']} />
                <Statistics
                  leads={data.action.Statistics.waitingFlowInstancesTotal ?? 0}
                  linkTo={'/-/contacts'}
                />
              </>
            )}
          </JustificationContainer>
          <IssuesForAction
            margin={['base', null, null]}
            actionId={data.action.id}
          />
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const getTimeUnitString = (type: Trickle_PeriodType, value: string) => {
  if (type === Trickle_PeriodType.Days) {
    return value === '1' ? 'dag' : 'dagen';
  }
  return value === '1' ? 'uur' : 'uren';
};

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: ${({ theme }) => theme.space('xs')};
`;

export default TrickleCard;
