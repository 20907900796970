import { isObject } from 'lodash';
import { FlowActionChanges } from '~/components/page/Automation/v2/state/actions';
import type { FlowSettingsChanges } from '../../../../state/flowSettings';

const checkValue = (
  fields: Partial<FlowActionChanges> | FlowSettingsChanges,
): boolean => {
  for (const key in fields) {
    if (fields[key] === true) {
      return true;
    }

    if (isObject(fields[key])) {
      return checkValue(fields[key]) ?? false;
    }
  }

  return false;
};

/**
 * Returns true one of the values is true
 * keywords: flowhasChanges, flowChanges
 */
const checkFlowHasChanges = ({
  flowSettings,
  actions,
  actionsCount,
}: {
  flowSettings: FlowSettingsChanges;
  actions: FlowActionChanges['actions'];
  actionsCount: FlowActionChanges['actionCount'];
}): boolean =>
  checkValue(flowSettings) || checkValue(actions) || actionsCount || false;

export default checkFlowHasChanges;
