import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';

import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';

import TEST_ID from './flowListColumns.testid';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';

import {
  FlowV2_ConditionListFragment,
  SortDirection,
  type FlowV2_Statistics,
} from '~/graphql/types';
import { formattedDateWithMonth } from '~/util/date';
import BuilderContext, {
  IBuilderContext,
} from '../../v2/components/Builder/context';
import RepresentationString from '../../v2/components/RepresentationString';
import JustificationContainer from '~/components/atom/JustificationContainer';
import StatisticsProgressBar from '~/components/molecule/StatisticsProgressBar';
import EnabledStatusIndicator from '~/components/atom/EnabledStatusIndicator';
import ShareIndicator from '~/components/organism/ShareIndicator';
import DateCell from '~/components/bad/DataTables/components/DateCell';

export type SortToggleFunction = (
  sortField: Array<string>,
  sortDirection: SortDirection | null,
) => void;

const text = {
  notModifiedMessage: 'Nooit',
};

const StyledRepresentationString = styled(RepresentationString)(
  ({ theme }) => css`
    font-weight: ${theme.fw('regular')};
  `,
);

const TriggerComp: React.FC<{
  iBuilderContext: IBuilderContext;
  actionId: string;
  conditionList?: FlowV2_ConditionListFragment;
}> = ({ iBuilderContext, conditionList, actionId }) => {
  if (!conditionList) return <>Geen condities</>;

  if (!conditionList.conditions[0]) return <>Geen condities</>;

  return (
    <BuilderContext.Provider value={iBuilderContext}>
      <JustificationContainer direction="column">
        <StyledRepresentationString
          key={conditionList.conditions[0].id}
          condition={conditionList.conditions[0]}
          actionId={actionId}
          hasIssue={false}
        />
      </JustificationContainer>
    </BuilderContext.Provider>
  );
};

const flowListColumns = (
  sortToggle: SortToggleFunction,
  version: string,
): Array<TableHeaderDefinitionCellType<any>> => {
  const triggerColumn: TableHeaderDefinitionCellType<any> =
    TableHeaderCell.custom({
      label: 'Trigger',
      dataProperty: 'trigger',
      width: 200,
      dataTestId: TEST_ID.TRIGGER,
      sorting: {
        onSort: sortDirection => sortToggle(['trigger'], sortDirection),
      },
      version,
      renderFunction: value => <TriggerComp {...value} />,
    });

  const columns = [
    TableHeaderCell.string({
      label: 'Naam',
      dataProperty: 'name',
      width: 250,
      dataTestId: TEST_ID.NAME,
      sorting: {
        onSort: sortDirection => sortToggle(['name'], sortDirection),
      },
    }),
    /** This is only added to access flow description when copying flow-v2 */
    TableHeaderCell.string({
      label: 'Description',
      dataProperty: 'description',
      sorting: {
        onSort: sortDirection => sortToggle(['description'], sortDirection),
      },
      isResizable: false,
      width: 0,
      dataTestId: TEST_ID.DESCRIPTION,
    }),
    TableHeaderCell.custom<FlowV2_Statistics>({
      label: 'Resultaten',
      dataProperty: 'Statistics',
      dataTestId: TEST_ID.STATISTICS,
      width: 160,
      renderFunction: value => (
        <StatisticsProgressBar
          completedAmount={value.completedFlowInstancesTotal}
          nonCompletedAmount={value.nonCompletedFlowInstancesTotal}
        />
      ),
      sorting: {
        onSort: sortDirection => sortToggle(['Statistics'], sortDirection),
      },
      version,
    }),
    TableHeaderCell.date({
      label: 'Aangemaakt',
      dataProperty: 'createdDate',
      dataTestId: TEST_ID.CREATED,
      width: 90,
      renderFunction: value => <DateCell value={value} />,
      sorting: {
        onSort: sortDirection => sortToggle(['createdDate'], sortDirection),
      },
    }),
    TableHeaderCell.date({
      label: 'Laatst gewijzigd',
      dataProperty: 'lastModified',
      dataTestId: TEST_ID.LAST_MODIFIED,
      width: 110,
      sorting: {
        onSort: sortDirection => sortToggle(['lastModified'], sortDirection),
      },
      renderFunction: value => {
        const lastModifiedString = value
          ? formattedDateWithMonth(value, {
              showTodayAsLabel: true,
              showYesterdayAsLabel: true,
            })
          : text.notModifiedMessage;

        return <span>{lastModifiedString}</span>;
      },
    }),

    TableHeaderCell.custom({
      label: 'Gedeeld',
      dataProperty: 'shared',
      width: 75,
      sorting: {
        onSort: sortDirection =>
          sortToggle(['shared', 'enabled'], sortDirection),
      },
      dataTestId: TEST_ID.SHARED,
      renderFunction: (value: { enabled: boolean; id: string }) => (
        <ShareIndicator
          shared={value.enabled}
          onClick={() => navigate(`/-/automation/flows/sharing/${value.id}`)}
        />
      ),
    }),
    TableHeaderCell.boolean({
      label: 'Status',
      dataProperty: 'enabled',
      width: 75,
      sorting: {
        onSort: sortDirection => sortToggle(['enabled'], sortDirection),
      },
      renderFunction: value => <EnabledStatusIndicator value={value} />,
      dataTestId: TEST_ID.ENABLED,
    }),
    TableHeaderCell.action({
      dataTestId: TEST_ID.ACTION,
    }),
  ];

  columns.splice(1, 0, triggerColumn);
  return columns;
};

export default flowListColumns;
