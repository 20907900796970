import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import NavigationItem from './components/NavigationItem';
import { navigationConfig, SIDE_MAX_WIDTH, SIDE_MIN_WIDTH } from './constants';
import SettingsMenu from './components/SettingsMenu';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import AccountSwitcher from './components/AccountSwitcher';
import useDHFlag from '~/hooks/useDHFlag';
import use100vh from '~/hooks/use100vh';
export type Props = {};

const text = {
  settings: 'Instellingen',
  logout: 'Uitloggen',
};

const Navigation: React.FCC<Props> = ({ dataTestId }) => {
  const containerRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [expandSettings, setExpandSettings] = useState(false);
  useOnClickOutside(containerRef, () => {
    setExpandSettings(false);
  });

  const hasFormBuilder = useDHFlag('form-builder');
  const navHeight = use100vh();

  return (
    <>
      <Container
        data-testid={dataTestId}
        $expanded={expanded || expandSettings}
        $navHeight={navHeight}
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
        ref={containerRef}
      >
        <JustificationContainer
          direction="column"
          justification="space-between"
          height="100%"
          width="100%"
        >
          <JustificationContainer direction="column" width="100%" gap="xxs">
            {navigationConfig.map(item => {
              if (item.to === '/-/forms' && !hasFormBuilder) return null;
              return (
                <NavigationItem
                  key={item.label?.toString()}
                  expanded={expanded || expandSettings}
                  onClick={() => setExpanded(false)}
                  {...item}
                />
              );
            })}
          </JustificationContainer>
          <JustificationContainer direction="column" width="100%" gap="xxs">
            <SettingsContainer>
              <AccountSwitcher expanded={expanded || expandSettings} />
              <NavigationItem
                expanded={expanded || expandSettings}
                icon={{ name: 'gear' }}
                label={text.settings}
                onClick={() => setExpandSettings(prev => !prev)}
                dataTestId="global-settings-button"
              />
              {expandSettings && (
                <SettingsMenu
                  onNavigate={() => {
                    setExpanded(false);
                    setExpandSettings(false);
                  }}
                />
              )}
            </SettingsContainer>
            <NavigationItem
              dataTestId="global-logout-button"
              expanded={expanded || expandSettings}
              icon={{ name: 'sign-out' }}
              to="/logout"
              label={text.logout}
            />
          </JustificationContainer>
        </JustificationContainer>
      </Container>
    </>
  );
};

const Container = styled.nav<{ $expanded: boolean; $navHeight: string }>(
  ({ theme, $expanded, $navHeight }) => css`
    padding: calc(${theme.space('xxxxl')} * 2.5) ${theme.space('xxs')}
      ${theme.space('xxs')} ${theme.space('xxs')};
    width: ${$expanded ? SIDE_MAX_WIDTH : SIDE_MIN_WIDTH};
    height: ${$navHeight};
    background-color: ${theme.color('primary', 'dark')};
    color: ${theme.color('white')};
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: ${theme.z('top') - 1};

    transition: width 0.2s ease-out;
  `,
);

const SettingsContainer = styled.div`
  width: 100%;
  position: relative;
`;

export default Navigation;
