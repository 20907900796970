import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useConfirmModal from '~/hooks/useConfirmModal';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  Body,
  Heading3,
  Heading4,
  Label,
  type Props as LabelProps,
  Variant,
} from '~/components/atom/Typography';
import {
  AppStatus_Realworks_RealworksTokenContainer,
  RealworksTokenType,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import formatToastMessage from '~/util/formatToastMessage';
import AddTokenModal from './components/AddTokenModal';
import AssignContactWithSave from './components/AssignContactWithSave';
import TEST_ID from './index.testid';
import { isNil } from 'ramda';
import useErrorReporter from '~/hooks/useErrorReporter';
import getRealworksAPIConfigs from '../../../../utils/getRealworksAPIConfigs';
import APIBlockFacade from './components/APIBlockFacade';
import { generateConcatenatedNonNullString } from '~/util/textConversion';
import Div from '~/components/atom/Div';
import OptionsButton from '~/components/organism/OptionsButton';
import type { Option } from '~/components/molecule/Dropdown';

export type Props = {
  usedOffices: Record<string, string>;
  refreshAppStatus: () => void;
  /** Updates the used offices in the app */
  onOfficeChange: (officeId: string) => void;
} & Omit<AppStatus_Realworks_RealworksTokenContainer, '__typename'>;

const text = {
  deleteConnectionModal: {
    title: 'Koppeling verwijderen',
    message: 'Weet je zeker dat je deze koppeling wilt verwijderen?',
  },
  deleteTokenModal: {
    title: 'Token verwijderen',
    message: 'Weet je zeker dat je dit token wilt verwijderen?',
  },
  confirm: 'Verwijderen',
  apiKoppelingen: 'API koppelingen',
  linkInfo: 'Vestiging in Realworks',
  deleteLinkButtonLabel: 'Koppeling verwijderen',
  officeCode: 'Afdelingscode',
  officeName: 'Kantoornaam',
  address: 'Adres',
  email: 'E-mailadres',
  saveError:
    'Er is iets fout gegaan bij het opslaan, probeer het later opnieuw.',
  deleteError:
    'Er is iets fout gegaan bij het verwijderen van het token, probeer het later opnieuw.',
  activeRelatieRequiredWarning:
    'Je moet een actieve relatie API hebben om deze koppeling te gebruiken.',
};

const TokenContainer: React.FCC<Props> = ({
  officeId,
  userId,
  wonen,
  id,
  name,
  agenda,
  relatie,
  makelaarData,
  usedOffices,
  refreshAppStatus,
  onOfficeChange,
}) => {
  const errorReporter = useErrorReporter();
  const addToast = useAddToast();
  const [addToken, setAddToken] = useState<RealworksTokenType | null>(null);
  const { id: accountId } = useCurrentAccount();
  const [updateRealworksAppStatus, { loading }] =
    useUpdateRealworksAppStatusMutation({});

  const {
    setShowModal: setDeleteConnectionConfirmModal,
    modal: deleteConnectionConfirmModal,
  } = useConfirmModal({
    level: 'danger',
    labels: text.deleteConnectionModal,
    buttons: [
      {
        onClick: async () =>
          updateRealworksAppStatus({
            variables: {
              accountId,
              deleteTokenContainer: { tokenContainerId: id },
            },
          }).then(() => refreshAppStatus()),
        label: text.confirm,
      },
    ],
  });

  const [tokenTypeToDelete, setTokenTypeToDelete] =
    useState<RealworksTokenType | null>(null);
  const {
    setShowModal: setDeleteTokenConfirmModal,
    modal: deleteTokenConfirmModal,
  } = useConfirmModal({
    level: 'danger',
    labels: text.deleteTokenModal,
    buttons: [
      {
        label: text.confirm,
        onClick: async () => {
          if (isNil(tokenTypeToDelete)) {
            return errorReporter.captureException(
              new Error('tokenTypeToDelete is null in Realworks'),
            );
          }

          void updateRealworksAppStatus({
            variables: {
              accountId,
              deleteToken: {
                tokenContainerId: id,
                type: tokenTypeToDelete,
              },
            },
          }).then(onUpdateCompleted);
        },
      },
    ],
  });

  const onUpdateCompleted = async ({ errors }) => {
    if (errors && errors.length !== 0) {
      return addToast([formatToastMessage(text.deleteError, 'danger')]);
    }

    return refreshAppStatus();
  };

  const addressStr = makelaarData?.address
    ? generateConcatenatedNonNullString(
        [
          makelaarData.address.street
            ? `${makelaarData.address.street} ${makelaarData.address.houseNumber || ''}`
            : null,
          makelaarData.address.addition,
          makelaarData.address.postcode,
          makelaarData.address.city,
          makelaarData.address.country,
        ],
        ', ',
      )
    : null;

  const options: Array<Option> = [
    {
      label: text.deleteLinkButtonLabel,
      icon: { name: 'trashcan' },
      key: 'delete-connection',
      type: 'DANGER',
      onClickAction: () => setDeleteConnectionConfirmModal(true),
      payload: null,
    },
  ];

  const labelProps: LabelProps = {
    margin: [null, null, 'xxxs'],
    variant: Variant.primary,
    size: 'base',
  };

  return (
    <>
      {deleteTokenConfirmModal}
      {deleteConnectionConfirmModal}

      <Container data-testid={TEST_ID.CONTAINER}>
        <JustificationContainer
          gap="base"
          width="100%"
          justification="space-between"
          align="center"
          margin={[null, null, 'l']}
        >
          <TokenContainerHeading variant={Variant.secondary} margin={[null]}>
            {name}
          </TokenContainerHeading>

          <OptionsButton icon="gear" options={options} />
        </JustificationContainer>

        <JustificationContainer gap="l" align="stretch">
          <ContentContainer direction="column">
            <AssignContactWithSave
              userId={userId}
              officeId={officeId}
              loading={loading}
              usedOffices={usedOffices}
              onOfficeChange={onOfficeChange}
              onSave={({ officeId, userId }) => {
                void updateRealworksAppStatus({
                  variables: {
                    accountId,
                    updateTokenContainer: {
                      tokenContainerId: id,
                      officeId,
                      userId,
                      name,
                    },
                  },
                }).then(async ({ errors }) => {
                  if (errors && errors.length !== 0) {
                    return addToast([
                      formatToastMessage(text.saveError, 'danger'),
                    ]);
                  }

                  return refreshAppStatus();
                });
              }}
              direction={!makelaarData ? 'row' : 'column'}
            />
          </ContentContainer>

          {makelaarData && (
            <ContentContainer direction="column">
              <Heading4 variant={Variant.primary}>{text.linkInfo}</Heading4>
              <Div
                padding={['m']}
                border={{ radius: 'base' }}
                backgroundColor={{ group: 'primary', variant: 'translucent' }}
                height="100%"
              >
                <Label {...labelProps}>{text.officeName}</Label>
                <Body size="base">{makelaarData.name}</Body>

                <Label {...labelProps}>{text.officeCode}</Label>
                <Body size="base">{makelaarData.officeCode}</Body>

                <Label {...labelProps}>{text.address}</Label>
                <Body size="base">{addressStr}</Body>

                <Label {...labelProps}>{text.email}</Label>
                <Body size="base" margin={[null]}>
                  {makelaarData.email}
                </Body>
              </Div>
            </ContentContainer>
          )}
        </JustificationContainer>

        <Heading4 margin={['m', null, 'xxs', null]} variant={Variant.primary}>
          {text.apiKoppelingen}
        </Heading4>
        <BlockContainer>
          {getRealworksAPIConfigs({
            tokenContainerId: id,
            agenda,
            wonen,
            relatie,
            makelaarData,
          }).map(config => (
            <APIBlockFacade
              key={config.tokenType}
              {...config}
              onDelete={() => {
                setTokenTypeToDelete(config.tokenType);
                setDeleteTokenConfirmModal(true);
              }}
              onAdd={() => setAddToken(config.tokenType)}
              insertDisabled={config.tokenType === RealworksTokenType.Wonen}
            />
          ))}
        </BlockContainer>
      </Container>
      {addToken !== null && (
        <AddTokenModal
          containerId={id}
          tokenType={addToken}
          onClose={() => {
            setAddToken(null);
            void refreshAppStatus();
          }}
        />
      )}
    </>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

const TokenContainerHeading = styled(Heading3)<{}>(
  ({ theme }) => css`
    /** We need a size between m and l */
    font-size: calc(${theme.fontSize('m')} * 1.25);
  `,
);

export const BlockContainer = styled.div<{}>(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${theme.space('l')};
    margin-bottom: ${theme.space('l')};
  `,
);

const ContentContainer = styled(JustificationContainer)`
  flex: 1;
`;

export default TokenContainer;
