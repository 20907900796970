import { atom } from 'recoil';

import { ClientFlowAction } from '~/graphql/types.client';
import { type FlowV2 } from '~/graphql/types';

export type InitialFlow = {
  flowName: FlowV2['name'];
  flowDescription: FlowV2['description'];
  enabled: FlowV2['enabled'];
  maximumFlowRun: FlowV2['maximumFlowRun'];
  actions: Array<ClientFlowAction>;
};

export const initialFlowState = atom<InitialFlow | null>({
  key: 'initialFlow',
  default: null,
});
