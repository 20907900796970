import React from 'react';
import Div, { type Props as DivProps } from '~/components/atom/Div';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = DivProps & {
  align?: 'center' | 'start' | 'end';
  justify?: 'space-between' | 'start' | 'end';
};

const Toolbar: React.FCC<Props> = ({
  dataTestId,
  children,
  margin = [null],
  align = 'center',
  justify = 'space-between',
  ...rest
}) => (
  <Div
    data-testid={dataTestId}
    margin={margin}
    backgroundColor={{ group: 'white' }}
    border={{
      radius: 'm',
      width: 's',
      color: { group: 'tertiary', variant: 'light' },
    }}
    padding={['xxs']}
    width="100%"
    {...rest}
  >
    <JustificationContainer justification={justify} align={align}>
      {children}
    </JustificationContainer>
  </Div>
);

export default Toolbar;
