import { unnest } from 'ramda';
import { jsx } from 'slate-hyperscript';
import getElements from '../../components/elements';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import convertDomAttrsToReactProps from './utils/convertDOMAttrsToReactProps';
import { reporter } from '~/hooks/useErrorReporter';
import insertSpaceAfterLastIndex from './utils/insertSpaceAfterLastIndex';

const TEXT_TAGS = {
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

const deserializers = customElements => {
  const els = getElements(customElements);
  return Object.values(els).reduce((acc, { deserialize, nodeName }) => {
    if (nodeName) return { ...acc, [nodeName]: deserialize };
    return acc;
  }, {});
};

/**
 * attributes and attrsToPassThrough are external attributes that an element has received when copy pasting html or via InsertHtml modal.
 *
 * attributes: Are passed to the components directly. They are overwritten if we have the functionality to overwrite them in the editor.
 *
 * attrsToPassThrough: Are the same as attributes. They cannot be passed to React components directly, that is why we keep them around.
 * We use them when serializing the elements. They are overwritten if the corresponding attribute has been changed in our editor.
 *
 */
const deserialize = ({
  el,
  markAsPendingImage,
  customElements = [],
  initialValue,
}: {
  el: Element | ChildNode;
  markAsPendingImage?: boolean;
  customElements: Array<ELEMENTS>;
  /** Added for debugging purposes */
  initialValue: string;
}) => {
  if (el.nodeType === 3) {
    return el.textContent; // is text node
  } else if (el.nodeType !== 1) {
    return null; // is not element node
  } else if (el.nodeName === 'BR') {
    return '\n';
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }

  let children = unnest(
    Array.from(parent.childNodes).map(item =>
      deserialize({
        el: item,
        markAsPendingImage,
        customElements,
        initialValue,
      }),
    ),
  );

  // @ts-ignore TS is expecting an Element but el can be ChildNode too
  const elAttributes = el.attributes;

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  if (children == null) {
    reporter.captureException(
      new Error(
        `children in deserialize is null or undefined ${JSON.stringify(
          initialValue,
        )}`,
      ),
      'error',
    );
  }

  insertSpaceAfterLastIndex({
    children,
  });

  if (el.nodeName === 'BODY') {
    return jsx(
      'fragment',
      {},
      children.filter(child => child != null),
    );
  }

  const customDeserializers = deserializers(customElements);

  if (customDeserializers[nodeName]) {
    const deserialized = customDeserializers[nodeName](el);
    const attrs = {
      ...deserialized,
      /** Do not overwrite these in custom deserializers */
      attributes: convertDomAttrsToReactProps(elAttributes),
      attrsToPassThrough: convertHtmlAttrsToObject(elAttributes),
    };

    /** If an image is pasted while inserting html, mark it as pending so that it can be uploaded to S3 */
    if (nodeName === 'IMG' && markAsPendingImage) {
      return jsx('element', { ...attrs, pending: true }, children);
    }

    /**
     * We do not want to have any children of ImmutableHtmlElement inside of Slate.
     * They are not used, updated, focused in any way.
     * */
    if (nodeName === 'DHIMMUTABLE') {
      children = [{ text: '' }];
    }

    /** When the deeplink comes to us, it has no children (e.g `<deeplink {...attrs}></deeplink>`), therefore empty children node is being added,
     *  but we need to use the one from the Deeplink deserialize function in order to keep all style marks */
    if (nodeName === 'DEEPLINK' && deserialized.children.length !== 0) {
      return jsx('element', attrs, deserialized.children[0]);
    }

    /** When the dhvariable comes to us, it has no children (e.g `<dhvariable {...attrs} />`), therefore empty children node is being added,
     *  but we need to use the one from the dhvariable deserialize function in order to keep all style marks */
    if (nodeName === 'DHVARIABLE' && deserialized.children.length !== 0) {
      return jsx('element', attrs, deserialized.children[0]);
    }

    return jsx('element', attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);

    return children.map(child => {
      if (child.children) {
        return addAttrsToChildren(child, attrs);
      }

      return jsx('text', attrs, child);
    });
  }

  /** For all other elements return GenericHtmlElement, pass all the attrs directly */
  if (
    nodeName &&
    nodeName.length > 0 &&
    /**
     * These tags have a custom shape such as <deeplink>, <dhvariable>  from the html that we have converted
     * so do not create a generic html element from these tags
     */
    nodeName !== 'IMG' &&
    nodeName !== 'DHVARIABLE' &&
    nodeName !== 'DEEPLINK'
  ) {
    const element = {
      type: ELEMENTS.GENERIC_HTML_ELEMENT,
      name: nodeName.toLowerCase(),
      attributes: convertDomAttrsToReactProps(elAttributes),
      attrsToPassThrough: convertHtmlAttrsToObject(elAttributes),
    };

    return jsx('element', element, children);
  }

  return children;
};

export default deserialize;

const addAttrsToChildren = (child: any, attrs: any) => {
  if (child.children) {
    child.children = child.children.map((item: any) => {
      const itemWithAttrs = addAttrsToChildren(item, attrs);
      return { ...itemWithAttrs, ...attrs };
    });
  }
  return child;
};

const convertHtmlAttrsToObject = (attrs: NamedNodeMap) => {
  const obj: { [key: string]: string } = {};
  const arr = [...attrs];

  for (const attr of arr) {
    obj[attr.name] = attr.value;
  }
  return obj;
};
