import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from '../../../../state';
import useMeasure from '~/hooks/useMeasure';
import { issuesBySettingsId } from '../../../../state/issues';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import InputLabel from '~/components/atom/InputLabel';
import { FormBuilder_Locale } from '~/graphql/types';
import LocaleIcon from '~/components/atom/Icon/components/LocaleIcon';
import Textarea from '~/components/molecule/Textarea';
import BrandSettingsCTA from '~/components/organism/BrandSettingsCTA';
import JustificationContainer, {
  Props as JustificationContainerProps,
} from '~/components/atom/JustificationContainer';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  brandSettingsCTAHeader:
    'Pas de instellingen van je huisstijl toe op dit formulier',
  heading: 'Widget instellingen',
  title: {
    nl: (
      <>
        Titel <LocaleIcon locale={FormBuilder_Locale.Nl} />
      </>
    ),
    en: (
      <>
        Titel <LocaleIcon locale={FormBuilder_Locale.En} />
      </>
    ),
  },
  subTitle: {
    nl: (
      <>
        Onderkop <LocaleIcon locale={FormBuilder_Locale.Nl} />
      </>
    ),
    en: (
      <>
        Onderkop <LocaleIcon locale={FormBuilder_Locale.En} />
      </>
    ),
  },
  logoImage: 'Logo afbeelding',
};

const WidgetSettings: React.FCC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
}) => {
  const [state, setState] = useRecoilState(formState);
  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  const issues = useRecoilValue(issuesBySettingsId('widget-settings'));

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const availableLocale = state?.style.availableLocale;
  const hasNL = availableLocale?.includes(FormBuilder_Locale.Nl);
  const hasEN = availableLocale?.includes(FormBuilder_Locale.En);

  const onIntlChange = ({
    fieldName,
    key,
    value,
  }: {
    fieldName: 'title' | 'subTitle';
    key: 'nl' | 'en';
    value: string | null;
  }) => {
    setState(prev =>
      prev
        ? {
            ...prev,
            [fieldName]: {
              ...prev[fieldName],
              [key]: value,
            },
          }
        : prev,
    );
  };

  const onUpdateLogo = ({ s3Key, url }: { s3Key: string; url: string }) => {
    setState(prev =>
      prev
        ? {
            ...prev,
            style: {
              ...prev?.style,
              theme: {
                ...prev?.style.theme,
                logo: { __typename: 'DHImage', s3key: s3Key, url },
              },
            },
          }
        : prev,
    );
  };

  const logoImage = useImageUpload({
    initialUrl: state?.style.theme.logo?.url,
    s3Key: state?.style.theme.logo?.s3key,
    onUpload: ({ s3Key, url }) => onUpdateLogo({ s3Key, url }),
  });

  const errorLabels = issues.reduce(
    (prev, curr) => {
      if (!curr) return prev;
      if (!curr.inputKey) return prev;

      const [fieldKey, localeKey] = curr.inputKey?.split('-');
      const prevValue = prev[fieldKey] || {};

      return {
        ...prev,
        [fieldKey]: {
          ...prevValue,
          [localeKey]: curr.message || null,
        },
      };
    },
    { title: { nl: null, en: null }, subTitle: { nl: null, en: null } },
  );

  const inputContainerProps: JustificationContainerProps = {
    direction: 'column',
    margin: [null, null, 'xxs', null],
    width: '100%',
  };

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        heading={text.heading}
        icon="grid"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <JustificationContainer
          direction="column"
          width="100%"
          gap="xs"
          padding={[null, 'm']}
          ref={ref}
        >
          <BrandSettingsCTA
            header={text.brandSettingsCTAHeader}
            subjects={['logo']}
            onConfirm={values => {
              if (values.logo) {
                const { s3key, url } = values.logo;
                onUpdateLogo({ s3Key: s3key, url });
              }
            }}
          />
          <JustificationContainer {...inputContainerProps}>
            <InputLabel label={text.logoImage} />
            <ImageUploadComponent
              uploading={logoImage.uploading}
              imageUrl={logoImage.s3Key != null ? logoImage.url : null}
              onUpload={file =>
                void logoImage.upload({
                  file: file[0],
                  filename: 'default_theme_logo_image',
                })
              }
              direction="column"
            />
          </JustificationContainer>

          {hasNL && (
            <>
              <JustificationContainer {...inputContainerProps}>
                <Textarea
                  name="title-nl"
                  value={state?.title.nl ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'title',
                        key: 'nl',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({ fieldName: 'title', key: 'nl', value });
                  }}
                  error={errorLabels.title.nl}
                  label={text.title.nl}
                />
              </JustificationContainer>
              <JustificationContainer {...inputContainerProps}>
                <Textarea
                  name="subTitle-nl"
                  value={state?.subTitle.nl ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'subTitle',
                        key: 'nl',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({
                      fieldName: 'subTitle',
                      key: 'nl',
                      value,
                    });
                  }}
                  error={errorLabels.subTitle?.nl}
                  label={text.subTitle.nl}
                />
              </JustificationContainer>
            </>
          )}

          {hasEN && (
            <>
              <JustificationContainer {...inputContainerProps}>
                <Textarea
                  name="title-en"
                  value={state?.title.en ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'title',
                        key: 'en',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({ fieldName: 'title', key: 'en', value });
                  }}
                  error={errorLabels.title.en}
                  label={text.title.en}
                />
              </JustificationContainer>
              <JustificationContainer {...inputContainerProps}>
                <Textarea
                  name="subTitle-en"
                  value={state?.subTitle.en ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'subTitle',
                        key: 'en',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({
                      fieldName: 'subTitle',
                      key: 'en',
                      value,
                    });
                  }}
                  error={errorLabels.subTitle.en}
                  label={text.subTitle.en}
                />
              </JustificationContainer>
            </>
          )}
        </JustificationContainer>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow: hidden;
`;

export default WidgetSettings;
