import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import flowSettings from '~/components/page/Automation/v2/state/flowSettings';
import useBuilderContext from '../../hooks/useBuilderContext';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import { uniqBy } from 'ramda';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import {
  FlowV2_Action__Input,
  FlowV2__Input,
  useInsertFlowBlueprintMutation,
  useUpdateFlowBlueprintMutation,
} from '~/graphql/types';
import getSerializedActions from '~/components/page/Automation/v2/util/getSerializedActions';
import metadata from '~/components/page/Automation/v2/state/metadata';
import { initialFlowState } from '~/components/page/Automation/v2/state';
import { flowActions } from '~/components/page/Automation/v2/state/actions';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';
import useIssuesInFlow from '../../hooks/useIssuesInFlow';

const text = {
  success: 'Aanpassingen opgeslagen',
  unresolvedIssues:
    'Deze flow bevat fouten. Corrigeer de fouten en probeer opnieuw.',
  createError: 'Er is iets fout gegaan tijdens het opslaan van flow',
  updateError:
    'Er is iets mis gegaan bij het opslaan van de aanpassingen, probeer het later opnieuw',
};

type ReturnProps = {
  /** Function to pass to the save button */
  onSave: () => Promise<void>;

  /** Function to pass to the cancel button */
  onCancel: () => void;

  /** Loading state of the mutation */
  loading: boolean;
};

const useControlFunctions = (): ReturnProps => {
  const location = useLocation();
  const addToast = useAddToast();
  const errorReporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const { flowBlueprintId } = useBuilderContext();

  const [actions, setActions] = useRecoilState(flowActions);
  const flowMetadata = useRecoilValue(metadata);
  const initialFlow = useRecoilValue(initialFlowState);
  const { hasIssues } = useIssuesInFlow();

  const { flowDescription, flowName, maximumFlowRun, enabled } =
    useRecoilValue(flowSettings);
  const resetFlowSettings = useResetRecoilState(flowSettings);
  const resetFlowMetadata = useResetRecoilState(metadata);

  const [updateFlowBlueprint, { loading: updateFlowLoading }] =
    useUpdateFlowBlueprintMutation();

  const flowVariables: Omit<FlowV2__Input, 'Actions'> = useMemo(
    () => ({
      accountId,
      id: flowBlueprintId,
      name: flowName,
      description: flowDescription ?? null,
      enabled,
      maximumFlowRun,
    }),
    [
      accountId,
      enabled,
      flowBlueprintId,
      flowDescription,
      flowName,
      maximumFlowRun,
    ],
  );

  const updateFlow = async () => {
    if (hasIssues) {
      return addToast([formatToastMessage(text.unresolvedIssues, 'danger')]);
    }

    const { serializedActions } = getSerializedActions({
      actions,
    });

    await updateFlowBlueprint({
      variables: {
        accountId,
        flow: {
          ...flowVariables,
          Actions: serializedActions as Array<FlowV2_Action__Input>,
        },
        metadata: uniqBy(action => action.flowBlueprintActionId, flowMetadata),
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([formatToastMessage(text.updateError, 'danger')]);
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        resetFlowMetadata();
        return;
      }
    });
  };

  const [insertFlowBlueprint, { loading: insertFlowLoading }] =
    useInsertFlowBlueprintMutation({
      fetchPolicy: 'no-cache',
    });

  const createFlow = async () => {
    if (hasIssues) {
      return addToast([formatToastMessage(text.unresolvedIssues, 'danger')]);
    }

    const { serializedActions } = getSerializedActions({
      actions,
    });

    await insertFlowBlueprint({
      variables: {
        accountId,
        flow: {
          ...flowVariables,
          Actions: serializedActions as Array<FlowV2_Action__Input>,
        },
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([formatToastMessage(text.createError, 'danger')]);
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        resetFlowSettings();
        return navigate(
          `/-/automation/flows/builder-v2/update/${flowBlueprintId}`,
        );
      }
    });
  };

  const onCancel = useCallback(() => {
    if (!initialFlow) {
      return errorReporter.captureException(new Error('No initial flow found'));
    }

    const clientActions = initialFlow.actions ?? [];
    setActions(clientActions);
    resetFlowSettings();
    resetFlowMetadata();
  }, [
    initialFlow,
    errorReporter,
    setActions,
    resetFlowSettings,
    resetFlowMetadata,
  ]);

  if (location.pathname.includes('update')) {
    return {
      loading: updateFlowLoading,
      onSave: updateFlow,
      onCancel,
    };
  }

  return {
    loading: insertFlowLoading,
    onSave: createFlow,
    onCancel,
  };
};

export default useControlFunctions;
