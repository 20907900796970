import type { SkipStepConfig } from './components/Connections/components/AddTokenContainerWizard/components/RealworksTokenBodyTemplate';

export const bdnlRealworksDeveloperId = 'eb92a256-b27d-4747-80c4-4d4b45485cc8';

export const realworksCRMLink =
  'https://crm.realworks.nl/servlets/objects/admin.marketplace/overview';

export type TokenStepBodyProps = {
  navigationFooter?: {
    onCancel?: () => void;
  };
  value?: string | null;
  /** Is used to validate the current token against other tokens */
  tokens: Array<string | null>;
  skipStepBlock?: Omit<SkipStepConfig, 'description'>;
  onChange: (value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

/**
 * This is initially passed to the token container when it is created. If it has no
 * active tokens, this name will be replaced with `Nieuwe koppeling ${index + 1}`
 * Otherwise it will be replaced by backend to display the Realworks office name
 */
export const DEFAULT_TOKEN_NAME = 'DefaultName';
