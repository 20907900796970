import React from 'react';
import TipBanner, { TipId } from '~/components/organism/TipBanner';
import Link from '~/components/molecule/Link';
import ChatLink from '~/components/organism/TipBanner/ChatLink';

export type Props = {
  tipId: TipId;
};

const RealworksHelpBlock: React.FCC<Props> = ({ dataTestId, tipId }) => (
  <TipBanner dataTestId={dataTestId} id={tipId} margin={['xl', null]}>
    Je kunt meer lezen over hoe je de koppeling moet instellen in onze{' '}
    <Link to="https://help.dathuis.nl/" target="_blank">
      kennisbank
    </Link>
    . Kom je er niet uit?{' '}
    <ChatLink text="Ik heb hulp nodig" linkText="Start een chat met ons." />
  </TipBanner>
);

export default RealworksHelpBlock;
