import React, { useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import useOutsideClick from '~/hooks/useClickOutside';
import isCursorInBoundary from '~/util/isCursorInBoundary';
import { isNil } from 'ramda';
import PortalledPositioner from '../PortalledPositioner';
import type { RootId } from '../Portal';
import getClosestScrollableParent from '~/util/getClosestScrollableParent';

export type Props = {
  isVisible: boolean;
  onClickOutside?: (e: MouseEvent) => void;
  openerRef: HTMLElement | null;
  rootId: RootId;
};

const MAX_WIDTH_FOR_POPUP = 400;
const MAX_HEIGHT_FOR_POPUP = 400;

const ResponsivePopup: React.FCC<Props> = ({
  dataTestId,
  onClickOutside,
  isVisible,
  openerRef,
  rootId,
  children,
}) => {
  const openerRect = openerRef?.getBoundingClientRect();
  const childrenRef = useRef<HTMLDivElement>(null);
  const scrollableParent = openerRef
    ? getClosestScrollableParent(openerRef)
    : null;

  const [childSize, setChildSize] = useState({
    width: 0,
    height: 0,
  });

  // Set child size and event listeners
  useLayoutEffect(() => {
    const updateChildSize = () => {
      const childRect = childrenRef.current?.getBoundingClientRect();
      if (childRect) {
        setChildSize({
          width: childRect.width,
          height: childRect.height,
        });
      }
    };

    window.addEventListener('resize', updateChildSize);
    // Use capture phase to catch all scroll events
    // Allows the popup to stay by the opener component
    window.addEventListener('scroll', updateChildSize, true);

    if (scrollableParent) {
      scrollableParent.addEventListener('scroll', updateChildSize);
    }

    // Create ResizeObserver to handle content size changes
    const resizeObserver = new ResizeObserver(updateChildSize);
    if (childrenRef.current) {
      resizeObserver.observe(childrenRef.current);
    }

    return () => {
      window.removeEventListener('resize', updateChildSize);
      window.removeEventListener('scroll', updateChildSize, true);

      if (scrollableParent)
        scrollableParent.removeEventListener('scroll', updateChildSize);

      resizeObserver.disconnect();
    };
  }, [isVisible, scrollableParent]);

  useOutsideClick(childrenRef, e => {
    if (isNil(e) || isNil(openerRect) || isNil(onClickOutside)) return;

    const isClickOnOpener = isCursorInBoundary({
      event: e,
      boundary: openerRect,
    });
    if (isClickOnOpener) return;
    onClickOutside(e);
  });

  if (isVisible) {
    return (
      <PortalledPositioner
        rootId={rootId}
        openerRect={openerRect || null}
        listSize={
          childSize
            ? {
                width: childSize.width,
                height: childSize.height,
              }
            : {
                width: MAX_WIDTH_FOR_POPUP,
                height: MAX_HEIGHT_FOR_POPUP,
              }
        }
        dataTestId={dataTestId}
      >
        <div ref={childrenRef}>{children}</div>
      </PortalledPositioner>
    );
  }

  return null;
};

// Wrap the children with this component if you want to style the element
export const BoxShadowContainer = styled.div<{ $width?: string }>`
  ${({ theme, $width }) => css`
    width: ${$width || '200px'};
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${theme.color('white')};
    padding: ${theme.space('base')};
    box-shadow: ${theme.boxShadow('base')};
  `}
`;

export default ResponsivePopup;
