import { Helmet as MetaTags } from 'react-helmet';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  FlowData___ActionFragment,
  useGetFlowDataQuery,
  useGetZapierTriggersQuery,
  UserStatus,
} from '~/graphql/types';
import flowInstance from '../../state/flowInstance';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderContext from '../Builder/context';
import Builder from '../Builder';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { flowNameSelector } from '../../state/flowSettings';
import { isNil } from 'ramda';
import 'reactflow/dist/base.css';
import generateMapsCombined from '../UpdateAction/components/Selector/utils/generateMapsCombined';
import createPageTitle from '~/util/createPageTitle';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';

export type Props = {
  dataTestId?: string;
  flowId: string;
};

const BuilderWithContext: React.FCC<Props> = React.memo(({ flowId }) => {
  const account = useCurrentAccount();
  const [instance, setFlowInstance] = useRecoilState(flowInstance);

  const { data: flowData, loading: flowDataLoading } = useGetFlowDataQuery({
    variables: {
      accountId: account.id,
    },
  });

  const flowName = useRecoilValue(flowNameSelector);

  useEffect(() => {
    if (flowData && flowData.getFlowData) {
      setFlowInstance(flowData.getFlowData.instance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowData]);

  const { data: zapierTriggerData } = useGetZapierTriggersQuery({
    variables: { accountId: account.id },
  });
  const offices = useOffices({ onlyExistingOffices: true });
  const users = useUsers({ statuses: [UserStatus.Active] });

  if (!flowId || flowDataLoading || !flowData?.getFlowData)
    return (
      <>
        <MetaTags>
          <title>{createPageTitle('Laden...')}</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  const {
    subjectMap,
    conditionMap,
    directoryMap,
    primitiveInputMap,
    instanceMap,
    subjectToConditionMap,
  } = generateMapsCombined(flowData.getFlowData, instance);

  const availableActions =
    flowData.getFlowData.availableActions.filter(
      (action): action is FlowData___ActionFragment => !isNil(action),
    ) ?? [];

  return (
    <BuilderContext.Provider
      value={{
        flowBlueprintId: flowId,
        opts: {
          subjectMap,
          conditionMap,
          subjectToConditionMap,
          directoryMap,
        },
        instanceMap,
        primitiveInputMap,
        primitiveInput: flowData.getFlowData.primitiveInput,
        primitiveListInput: flowData.getFlowData.primitiveListInput,
        superSubjects: flowData.getFlowData.superSubjects,
        subjects: flowData.getFlowData.subjects,
        instances: flowData.getFlowData.instance,
        accountId: account.id,
        availableActions,
        zapierTriggers: zapierTriggerData?.getZapierTriggers || null,
        offices,
        users,
      }}
    >
      <Container>
        <MetaTags>
          <title>{createPageTitle(flowName)}</title>
        </MetaTags>
        <BuilderContainer>
          <Builder />
        </BuilderContainer>
      </Container>
    </BuilderContext.Provider>
  );
});

const BuilderContainer = styled.main`
  height: 100%;
`;

const Container = styled.div`
  height: 100%;
`;

export default BuilderWithContext;
