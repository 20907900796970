import React from 'react';
import DeepLinkToolbar from '../DeepLinkToolbar';
import LinkToolbar from '../LinkToolbar';
import ImageToolbar from '../ImageToolbar';
import ELEMENTS from '../../../elements/elementsEnum';
import DeleteButton from '../../../Buttons/Delete';
import type {
  CustomElement,
  DHEditor,
} from '~/components/organism/PluginsEditor/types';
import type { Path } from 'slate';
import { isNil } from 'ramda';

export type Props = {
  dataTestId?: string;
  selectedEl: { element: CustomElement; path: Path } | null;
  editor: DHEditor;
};

const ContentFacade: React.FCC<Props> = ({ selectedEl, editor }) => {
  if (isNil(selectedEl)) return null;

  if (
    selectedEl.element.type === ELEMENTS.IMAGE ||
    selectedEl.element.type === ELEMENTS.DH_IMAGE
  ) {
    return <ImageToolbar path={selectedEl.path} element={selectedEl.element} />;
  }

  if (selectedEl?.element.type === ELEMENTS.LINK) {
    return (
      <LinkToolbar
        path={selectedEl.path}
        element={selectedEl.element}
        editor={editor}
      />
    );
  }

  if (selectedEl?.element.type === ELEMENTS.DEEP_LINK) {
    return (
      <DeepLinkToolbar
        path={selectedEl.path}
        element={selectedEl.element}
        editor={editor}
      />
    );
  }

  /**
   * This is added be able to delete unknown elements in the editor
   * otherwise it gets difficult to remove them sometimes
   */
  if (selectedEl?.element.type === ELEMENTS.GENERIC_HTML_ELEMENT) {
    return <DeleteButton editor={editor} path={selectedEl.path} />;
  }

  return null;
};

export default ContentFacade;
