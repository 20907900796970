import React, { createContext, Reducer } from 'react';
import { Props as ButtonProps } from '~/components/atom/Button';
import { Props as TextButtonProps } from '~/components/atom/TextButton';
import { IconType } from '~/components/atom/Icon';

import {
  OutputMap,
  StepId,
  StepOutput,
} from '~/components/organism/WizardSteps';
import { StoredState } from '../../db';
import { AppType, PermissionId, StellaxAiReportType } from '~/graphql/types';
import { initialHypotheekColorSettingsState } from '~/components/organism/WizardSteps/Apps/Hypotheekbond/HypotheekColorSettings';
import { valueReportInitialColorSettingsState } from '~/components/organism/WizardSteps/Apps/ValueReport/ValueReportColorSettings';
import { AppStatus__typename, WizardFlow } from '~/graphql/types.client';

export type StepMetadata = {
  appType?: AppType;
};

export type WizardStepProps = {
  step: WizardStep;
  outputMap: OutputMap;
};

export type WizardStepHooks = {
  onEnter?: () => void;
  onBeforeEnter?: () => void;
  onExit?: () => void;
  onBeforeExit?: () => void;
};

export type WizardStep = {
  id: StepId;
  title: React.ReactNode;
  subSteps?: Array<Omit<WizardStep, 'subSteps'>>;
  icon?: IconType;
  description?: React.ReactNode;
  isTouched?: boolean;
  canGoBack?: boolean;
  skippable?: boolean;
  nextButton?: Omit<ButtonProps, 'onClick'>;
  previousButton?: Omit<TextButtonProps, 'onClick'>;
  cancelButton?: {
    label: React.ReactNode;
  };
  metadata?: {
    typename: AppStatus__typename;
  };
  permissions?: Array<PermissionId>;
};

export type StepOptions = {
  onBeforeNext?: (output: OutputMap) => Promise<StepOutput>;
  onBeforePrevious?: (output: OutputMap) => Promise<StepOutput>;
};

export type ReducerAction<T, P extends object> = {
  type: T;
  payload: P;
};

type AddStepAction = ReducerAction<
  'addStep',
  { step: WizardStep; addAfter?: StepId }
>;
type NextStepAction = ReducerAction<'next', {}>;
type PreviousStepAction = ReducerAction<'previous', {}>;
type ShowAction = ReducerAction<'show', WizardFlow & Partial<StoredState>>;
type HideAction = ReducerAction<'hide', {}>;

// Step actions
type FreeStepAction = ReducerAction<
  'freeStep',
  { step: StateStep; output: StepOutput }
>;
type LockStepAction = ReducerAction<
  'lockStep',
  { step: StateStep; output: StepOutput }
>;
type AddSubStepAction = ReducerAction<
  'addSubStep',
  { step: StateStep; subStep: WizardStep }
>;
type RemoveSubStepAction = ReducerAction<
  'removeSubStep',
  { step: StateStep; subStep: WizardStep }
>;
type UpdateStepAction = ReducerAction<
  'updateStep',
  { step: StateStep; options: StepOptions }
>;
type LockGoBackAction = ReducerAction<'lockGoBack', { step: StateStep }>;
type FreeGoBackAction = ReducerAction<'freeGoBack', { step: StateStep }>;

type LockSkipAction = ReducerAction<'lockSkip', { step: StateStep }>;
type FreeSkipAction = ReducerAction<'freeSkip', { step: StateStep }>;

// Utility actions
type RestoreStateAction = ReducerAction<'restoreState', StoredState>;
type SetOutputAction = ReducerAction<'setOutput', OutputMap>;
type ClearStateAction = ReducerAction<'clear', {}>;

export type WizardAction =
  // Wizard API
  | AddStepAction
  | NextStepAction
  | PreviousStepAction
  | ShowAction
  | HideAction
  // Step API
  | FreeStepAction
  | LockStepAction
  | AddSubStepAction
  | RemoveSubStepAction
  | UpdateStepAction
  | LockGoBackAction
  | FreeGoBackAction
  | LockSkipAction
  | FreeSkipAction
  // Utility actions
  | RestoreStateAction
  | SetOutputAction
  | ClearStateAction;

export type StateStep = Omit<WizardStep, 'subSteps'> & {
  subSteps?: Array<StateStep>;
  isFree?: boolean;
  isTouched?: boolean;
  options?: StepOptions;
};

export type StateStepWithSubSteps = Omit<StateStep, 'subSteps'> & {
  subSteps: Array<StateStep>;
};

export type WizardState = {
  id: string;
  show: boolean;
  currentStep: number;
  currentSubStep: null | number;
  steps: Array<StateStep>;
  outputMap: OutputMap;
  header?: string;
  canClose?: boolean;
};

export type WizardReducer = Reducer<WizardState, WizardAction>;

export type IWizardContextProps = {
  id: string;
  dispatch: React.Dispatch<WizardAction>;
  state: WizardState;
};

export type OutputFieldItem = {
  /**
   * Used to group output types
   */
  category: string;
  type: 'item';
  label: string;
  icon?: IconType;
  value: string & { insertedId?: string };
};

export type OutputFieldList<T> = {
  /**
   * Used to group output types
   */
  category: string;
  type: 'list';
  value: Array<T & { label: string; insertedId?: string; icon?: IconType }>;
};

export type OutputFieldColor = {
  /**
   * Used to group output types
   */
  category: string;
  /**
   * Used for rendering names on the overview page.
   */
  label: string;
  type: 'color';
  value: string;
};

export type OutputFieldImage<T> = {
  /**
   * Used to group output types
   */
  category: string;
  type: 'image';
  label: string;
  value: T | null;
};

export type OutputFieldInvisible<T> = {
  type: 'invisible';
  value: T;
};

export type OutputFieldPlain<T> = {
  type: 'plain';
  value: T;
  label?: string;
  category: string;
};

export type OutputFieldAccordions<T> = {
  type: 'accordions';
  category: string;
  values: Array<{ label: string; children: Array<OutputField<T>> }>;
};

/**
 * Should not be used as an output type only for reasoning about "all" outputs
 */

export type OutputField<T> =
  | OutputFieldPlain<T>
  | OutputFieldItem
  | OutputFieldList<T>
  | OutputFieldInvisible<T>
  | OutputFieldColor
  | OutputFieldImage<T>
  | OutputFieldAccordions<T>;

export const initialState: WizardState = {
  id: 'initialId',
  currentSubStep: null,
  currentStep: 0,
  steps: [],
  show: false,
  outputMap: {
    // <------------ START of Onboarding Output ------------>
    WelcomeScreen: {
      type: 'WelcomeScreen',
    },
    WidgetStyleConfiguration: {
      type: 'WidgetStyleConfiguration',
      completed: false,
      websiteUrl: {
        type: 'item',
        category: 'Adres van je website',
        value: '',
        label: '',
      },
      widgetLogo: {
        type: 'image',
        category: 'Images',
        label: 'Logo',
        value: null,
      },
      widgetImage: {
        type: 'image',
        category: 'Images',
        label: 'Highlight Image',
        value: null,
      },
      primaryBackgroundColor: {
        category: 'Huisstijl',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      primaryColor: {
        category: 'Huisstijl',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      secondaryBackgroundColor: {
        category: 'Huisstijl',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      secondaryColor: {
        category: 'Huisstijl',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
    },

    UpdateSignature: {
      type: 'UpdateSignature',
    },

    AddWidgetScript: { type: 'AddWidgetScript' },

    SynchronizeEmail: {
      type: 'SynchronizeEmail',
      synchronisedEmail: {
        type: 'plain',
        label: '',
        value: '',
        category: '',
      },
    },

    SelectAppsForWidget: {
      type: 'SelectAppsForWidget',
      appsAndItemsToEnable: {
        type: 'invisible',
        value: undefined,
      },
      selectedGroupsMap: {
        type: 'invisible',
        value: undefined,
      },
      widgetItemGroups: {
        type: 'invisible',
        value: [],
      },
    },
    OnboardingStellaxAiSelectProduct: {
      type: 'OnboardingStellaxAiSelectProduct',
      reportType: {
        type: 'invisible',
        value: StellaxAiReportType.B,
      },
    },

    ConfirmAppsEnablement: {
      type: 'ConfirmAppsEnablement',
      appsToEnable: {
        type: 'invisible',
        value: null,
      },
    },
    WalletSettings: {
      type: 'WalletSettings',
      accountName: {
        type: 'plain',
        category: '',
        label: '',
        value: '',
      },
      walletEmail: {
        type: 'plain',
        category: '',
        label: '',
        value: '',
      },
      ibanMasked: {
        type: 'plain',
        category: '',
        label: '',
        value: '',
      },
    },
    LoadingScreen: {
      type: 'LoadingScreen',
      enabledAppsOutput: {
        type: 'accordions',
        category: '',
        values: [],
      },
      failedAppsOutput: {
        type: 'accordions',
        category: '',
        values: [],
      },
    },
    EnabledAppsOverview: {
      type: 'EnabledAppsOverview',
      enabledApps: {
        type: 'list',
        category: '',
        value: [],
      },
    },
    // <------------ END of Onboarding Output ------------>

    // <--- Start of generic steps --->
    GenericStart: {
      type: 'GenericStart',
    },

    // Enable apps for widget
    GenericWidgetConfiguration: {
      type: 'GenericWidgetConfiguration',
      selectedApps: {
        category: '',
        type: 'list',
        value: [],
      },
    },

    OnboardingOverviewStep: {
      type: 'OnboardingOverviewStep',
    },

    // Enable flows
    EnableFlowsForAppStatus: {
      type: 'EnableFlowsForAppStatus',
      appStatus: {
        type: 'invisible',
        value: null,
      },
      insertedFlows: {
        category: '',
        type: 'list',
        value: [],
      },
      selectedTemplates: {
        type: 'invisible',
        value: [],
      },
    },

    // Overview
    AppStatusOverview: {
      type: 'AppStatusOverview',
    },

    // <--- End of generic steps --->

    // Zapier
    ConnectZapier: {
      type: 'ConnectZapier',
    },
    AddZapierEvent: {
      type: 'AddZapierEvent',
      selectedEvents: {
        category: '',
        type: 'list',
        value: [],
      },
    },
    AddZapierTrigger: {
      type: 'AddZapierTrigger',
      selectedTriggers: {
        category: '',
        type: 'list',
        value: [],
      },
    },
    ZapierNextSteps: {
      type: 'ZapierNextSteps',
    },

    // Realworks
    RealworksActivationPending: {
      type: 'RealworksActivationPending',
      relatieToken: undefined,
      agendaToken: undefined,
      // wonenToken: undefined,
    },
    RealworksAgenda: {
      type: 'RealworksAgenda',
      agendaToken: {
        type: 'invisible',
        value: '',
      },
    },
    // RealworksExportContacts: {
    //   type: 'RealworksExportContacts',
    //   wonenToken: {
    //     type: 'invisible',
    //     value: '',
    //   },
    // },
    RealworksImportContacts: {
      type: 'RealworksImportContacts',
      relatieToken: { type: 'invisible', value: '' },
    },
    RealworksSetup: {
      type: 'RealworksSetup',
      addTokenContainerInput: {
        type: 'invisible',
        value: {
          name: '',
        },
      },
    },

    // Waardecheck
    WaardecheckGeneral: {
      type: 'WaardecheckGeneral',
      route: {
        type: 'invisible',
        value: null,
      },
    },
    WaardecheckDesign: {
      type: 'WaardecheckDesign',
      data: {
        type: 'invisible',
        value: null,
      },
      logoImage: {
        type: 'image',
        category: 'Images',
        label: 'Logo',
        value: null,
      },
      fallbackImage: {
        type: 'image',
        category: 'Images',
        label: 'Background Image',
        value: null,
      },
      logoLink: {
        type: 'invisible',
        value: {
          en: null,
          nl: null,
        },
      },
      primaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      primaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      secondaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      secondaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      tertiaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      tertiaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      quaternaryBackgroundColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      quaternaryColor: {
        category: 'Kleuren',
        type: 'color' as OutputFieldColor['type'],
        label: '',
        value: '',
      },
      sameColors: false,
    },
    // Trustoo
    AddTrustooKoppeling: {
      type: 'AddTrustooKoppeling',
      trustooTokens: {
        type: 'item',
        category: '',
        value: '',
        label: '',
      },
      trustooOfficeId: {
        type: 'invisible',
        value: '',
      },
    },
    // Contaqt
    AddContaqtKoppeling: {
      type: 'AddContaqtKoppeling',
      contaqtToken: {
        type: 'item',
        category: '',
        value: '',
        label: '',
      },
    },

    // Hypotheekbond
    HypotheekColorSettings: initialHypotheekColorSettingsState,

    // Stellax
    StellaxInstall: {
      type: 'StellaxInstall',
      reportType: {
        type: 'invisible',
        value: StellaxAiReportType.B,
      },
      email: {
        type: 'item',
        label: '',
        category: 'Email',
        value: '',
      },
      logo: {
        type: 'image',
        label: '',
        category: 'Logo',
        value: null,
      },
      name: {
        type: 'item',
        label: '',
        category: 'Bedrijfsnaam',
        value: '',
      },
      website: {
        type: 'item',
        label: '',
        category: 'Website',
        value: '',
      },
    },

    // ValueReport
    ValueReportGeneralImageSettings: {
      type: 'ValueReportGeneralImageSettings',
      logoImage: {
        type: 'image',
        category: 'Report Image',
        value: null,
        label: 'Logo image',
      },
      valueReportLogoLink: undefined,
      backgroundImage: {
        category: '',
        type: 'image',
        value: null,
        label: '',
      },
      addedReport: undefined,
    },

    ValueReportColorSettings: valueReportInitialColorSettingsState,
  },
};

const WizardContext = createContext<IWizardContextProps>({
  id: 'intialID',
  dispatch: () => {},
  state: initialState,
});

export default WizardContext;
