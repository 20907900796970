import { isNil } from 'ramda';
import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading5, Variant } from '~/components/atom/Typography';
import { FlowAction } from '~/graphql/types';
import { ClientFlowActionContactAssign } from '~/graphql/types.client';
import useOffices from '~/hooks/useOffices';
import useUsers from '~/hooks/useUsers';
import getCardHeading from '../../../../utils/getCardHeading';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import IssuesForAction from '../IssuesForAction';

export type Props = Omit<
  CardProps<ClientFlowActionContactAssign>,
  'heading' | 'actionType'
>;

const AssignContactCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const { office: officeId, user: userId, setByWorkingArea } = data.action;

    const officeName = useOffices({}).find(
      office => office.id === officeId,
    )?.name;
    const userName = useUsers({ officeIds: [officeId] }).find(
      user => user.id === userId,
    )?.name;

    const InnerComp = setByWorkingArea
      ? WithSetByWorkingArea
      : WithoutSetByWorkingArea;

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.ContactAssign}
          heading={getCardHeading(FlowAction.ContactAssign)}
          selected={selected}
          disabled={disabled}
          data={data}
        >
          <InnerComp
            officeName={isNil(officeName) ? '-' : officeName}
            userName={userId == null ? 'Alle gebruikers' : userName ?? '-'}
            actionId={data.action.id}
          />
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

type InnerProps = {
  officeName: string;
  userName: string;
  actionId: string;
};
const WithSetByWorkingArea: React.FC<InnerProps> = ({
  officeName,
  userName,
  actionId,
}) => (
  <JustificationContainer direction="column">
    <WithMargins>
      <Body>Toewijzen via werkgebied, daarbuiten:</Body>
    </WithMargins>
    <WithMargins>
      <Heading5 inline variant={Variant.primary}>
        Vestiging:{' '}
      </Heading5>
      {isNil(officeName) ? '-' : officeName}
    </WithMargins>
    <div>
      <Heading5 inline variant={Variant.primary}>
        Gebruiker:{' '}
      </Heading5>
      {userName}
    </div>
    <IssuesForAction actionId={actionId} />
  </JustificationContainer>
);

const WithoutSetByWorkingArea: React.FC<InnerProps> = ({
  officeName,
  userName,
  actionId,
}) => (
  <JustificationContainer direction="column">
    <WithMargins>
      <Heading5 inline variant={Variant.primary}>
        Vestiging:{' '}
      </Heading5>
      {isNil(officeName) ? '-' : officeName}
    </WithMargins>
    <div>
      <Heading5 inline variant={Variant.primary}>
        Gebruiker:{' '}
      </Heading5>
      {userName}
    </div>
    <IssuesForAction actionId={actionId} />
  </JustificationContainer>
);

const WithMargins = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default AssignContactCard;
