import type { FormBuilder_ScreenNode_Block } from '~/graphql/types';
import cleanedFilename from '~/util/cleanedFilename';

const getComponentPreviewInitialHeight = (
  typename: FormBuilder_ScreenNode_Block['__typename'],
) => {
  switch (typename) {
    case 'FormData_Input_Address':
    case 'FormData_Input_Contact_Address':
    case 'FormData_Input_Contact_Email':
    case 'FormData_Input_Contact_Name':
    case 'FormData_Input_Contact_Phone':
    case 'FormData_Input_Email':
    case 'FormData_Input_Integer':
    case 'FormData_Input_Text':
    case 'FormData_Select_Radio':
    case 'FormData_Select_MultiButton':
    case 'FormData_UI_RichText':
    case 'FormData_UI_Typography':
      return 52;

    case 'FormData_Input_Multiline':
      return 82;
    case 'FormData_Select_Dropdown':
      return 50;

    case 'FormData_UI_Image':
      return 250;

    default:
      throw new Error(
        `${cleanedFilename(__filename)}. Unknown block type: ${typename}`,
      );
  }
};

export default getComponentPreviewInitialHeight;
