import React from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from '../../index.testid';
import { Props } from '../..';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Link } from 'react-feather';
import Button from '~/components/atom/Button';
import CloseButton from '~/components/atom/CloseButton';
import Icon from '~/components/atom/Icon';
import { Heading5, Variant, Body } from '~/components/atom/Typography';

const Small: React.FCC<Props> = ({
  dataTestId,
  icon,
  header,
  description,
  button,
  children,
  margin,
  width,
  padding = ['m'],
  align,
  linkButton,
  onClose,
  ...rest
}) => (
  <Container
    backgroundColor={{ group: 'primary' }}
    direction="column"
    justification="space-between"
    border={{ radius: 'm' }}
    align={align}
    margin={margin}
    padding={padding}
    dataTestId={dataTestId ?? TEST_ID.CONTAINER}
    width={width}
    {...rest}
  >
    <StyledIcon
      name={icon?.name || 'triangle'}
      background={icon?.background}
      color={{ group: 'white' }}
    />

    <JustificationContainer
      width="100%"
      margin={[null, null, button ? 'base' : null, null]}
      direction="column"
    >
      {header && (
        <Heading5
          margin={[null]}
          variant={Variant.primary}
          color={{ group: 'white' }}
        >
          {header}
        </Heading5>
      )}
      {description && (
        <Body margin={[null]} size="base" color={{ group: 'white' }}>
          {description}
        </Body>
      )}
      {children}
    </JustificationContainer>

    {button && (
      <StyledButton
        loading={button.loading}
        size="small"
        label={button.label}
        icon={button.icon}
        appearance={button.appearance}
        onClick={button.onClick}
        disabled={button.disabled}
      />
    )}

    {linkButton && (
      <StyledLink
        to={linkButton.to}
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        <StyledButton
          size="small"
          label={linkButton.label}
          icon={linkButton.icon}
          appearance={linkButton.appearance}
        />
      </StyledLink>
    )}
    {onClose && (
      <StyledCloseButton onClick={onClose} dataTestId={TEST_ID.CLOSE_BUTTON} />
    )}
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    position: relative;
    color: ${theme.color('white')};
  `,
);

const StyledLink = styled(Link)`
  width: 100%;
`;

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: 1.5em;
    margin-bottom: ${theme.space('base')};

    & > svg {
      width: 0.8em;
      height: 0.8em;
    }

    align-self: flex-start;
  `,
);

const StyledButton = styled(Button)<{}>(
  ({ theme }) => css`
    justify-content: flex-start;
    min-width: max-content;
    width: 100%;

    &:hover {
      background-color: ${theme.color('white', 'dark')};
      color: ${theme.color('primary')};
    }
  `,
);

const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: absolute;
    font-size: ${theme.fs('base')};
    right: ${theme.space('base')};
  `,
);

export default Small;
