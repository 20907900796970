import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SystemSize } from '~/theme';
import { isString } from 'lodash';
import { SystemFontWeight, SystemLineHeight } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';

export enum Variant {
  primary = 'primary',
  secondary = 'secondary',
}

type WordBreak = 'normal' | 'break-all' | 'keep-all' | 'break-word';
type WhiteSpace = 'normal' | 'nowrap' | 'break-spaces';
type TextAlign = 'start' | 'center' | 'end';

export type Props = {
  className?: string;
  as?: string | React.ComponentType<any>;
  variant?: Variant;
  size?: SystemSize;
  highlight?: string;

  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  textDecoration?: 'underline' | 'none';
  backgroundColor?: ThemeColor;
  fontWeight?: SystemFontWeight;
  color?: ThemeColor;
  inline?: boolean;
  style?: any;
  wordBreak?: WordBreak;
  whiteSpace?: WhiteSpace;
  align?: TextAlign;
  lineHeight?: SystemLineHeight;
  skewed?: boolean;
  /**
   * When there is not enough space for the Typography component, this will truncate the text.
   * To be able to use this prop, make sure that the parent has a width set
   */
  truncate?: boolean;
  italic?: boolean;
};

const Typography: React.FCC<Props> = ({
  className = '',
  variant = Variant.primary,
  size = 'm',
  dataTestId = '',
  backgroundColor,
  truncate = false,
  inline = false,
  children = null,
  lineHeight = 'base',
  skewed = false,
  textDecoration = 'none',
  italic = false,
  highlight,
  whiteSpace,
  color,
  fontWeight,
  padding,
  margin,
  wordBreak,
  align,
  as,
}) => {
  const chunks =
    highlight && typeof children === 'string'
      ? // Typescript thinks this can be an number but we check if its a string so we can safely cast it.
        [...(children as string).split(highlight)]
      : [children];

  return (
    <Container
      className={className}
      data-testid={dataTestId}
      as={as}
      $textDecoration={textDecoration}
      $italic={italic}
      $whiteSpace={whiteSpace}
      $size={size}
      $variant={variant}
      $inline={inline}
      $color={color}
      $backgroundColor={backgroundColor}
      $fontWeight={fontWeight}
      $padding={padding}
      $margin={margin}
      $wordBreak={wordBreak}
      $align={align}
      $truncate={truncate}
      $lineHeight={lineHeight}
      $skewed={skewed}
    >
      {chunks.map((chunk, index) => (
        <React.Fragment key={index}>
          <span>{chunk}</span>
          {index !== chunks.length - 1 && <Highlight>{highlight}</Highlight>}
        </React.Fragment>
      ))}
    </Container>
  );
};

const defaultProps = {
  h1: {
    size: 'xxl' as SystemSize,
    as: 'h1',
    variant: Variant.primary,
    skewed: true,
    fontWeight: 'bold' as SystemFontWeight,
  },
  h2: {
    size: 'xl' as SystemSize,
    as: 'h2',
    variant: Variant.primary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  h3: {
    size: 'l' as SystemSize,
    as: 'h3',
    variant: Variant.primary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  h4: {
    size: 'm' as SystemSize,
    as: 'h4',
    variant: Variant.secondary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  h5: {
    size: 'base' as SystemSize,
    as: 'h5',
    variant: Variant.secondary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  h6: {
    size: 's' as SystemSize,
    as: 'h6',
    variant: Variant.secondary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  subheading: {
    size: 'xs' as SystemSize,
    as: 'h6',
    variant: Variant.secondary,
    fontWeight: 'regular' as SystemFontWeight,
  },
  label: {
    size: 's' as SystemSize,
    as: 'span',
    variant: Variant.secondary,
    fontWeight: 'semiBold' as SystemFontWeight,
  },
  body: {
    size: 'm' as SystemSize,
    as: 'p',
    variant: Variant.primary,
    fontWeight: 'regular' as SystemFontWeight,
  },
  code: {
    size: 'm' as SystemSize,
    as: 'code',
    variant: Variant.primary,
    backgroundColor: { group: 'white', variant: 'dark' } as ThemeColor,
  },
  strong: {
    size: 'base' as SystemSize,
    as: 'strong',
    variant: Variant.primary,
    fontWeight: 'bold' as SystemFontWeight,
    inline: true,
  },
  small: {
    size: 'base' as SystemSize,
    as: 'small',
    variant: Variant.primary,
  },
};

const weightMap = Object.freeze({
  h1: 'semiBold',
  h2: 'semiBold',
  h3: 'semiBold',
  h4: 'regular',
  h5: 'regular',
  h6: 'regular',
  p: 'regular',
  code: 'regular',
});

type StyledProps = {
  $whiteSpace: Props['whiteSpace'];
  $textDecoration: Props['textDecoration'];
  $italic: Props['italic'];
  $size: Props['size'];
  $variant: Props['variant'];
  $inline: Props['inline'];
  $color: Props['color'];
  $backgroundColor: Props['backgroundColor'];
  $fontWeight: Props['fontWeight'];
  $padding: Props['padding'];
  $margin: Props['margin'];
  $wordBreak: Props['wordBreak'];
  $align: Props['align'];
  $truncate: Props['truncate'];
  $lineHeight: Props['lineHeight'];
  $skewed: Props['skewed'];
};

const Container = styled.p<StyledProps>(
  ({
    theme,
    as: as_,
    $size,
    $variant,
    $inline = false,
    $color,
    $backgroundColor,
    $fontWeight,
    $padding: $padding,
    $margin: $margin,
    $wordBreak,
    $whiteSpace,
    $align,
    $truncate = false,
    $lineHeight = 'base',
    $skewed = false,
    $textDecoration,
    $italic,
  }) => {
    const as = isString(as_) ? as_ : 'p';

    return css`
      font-size: ${theme.fs($size || 'm')};
      font-weight: ${theme.fw(
        $fontWeight ? $fontWeight : weightMap[as] || 'normal',
      )};
      color: ${$color
        ? theme.color($color.group, $color.variant || 'base')
        : theme.color('text', $variant === 'primary' ? 'base' : 'light')};
      margin: ${$margin ? arrayToCss($margin, theme) : '0 0 0.5em 0'};
      background-color: ${$backgroundColor
        ? theme.color($backgroundColor.group, $backgroundColor.variant)
        : 'transparent'};
      padding: ${as === 'code'
        ? theme.space('xxxs')
        : $padding
          ? arrayToCss($padding, theme)
          : 0};
      display: ${$inline ? 'inline' : 'block'};
      line-height: ${theme.lineHeight($lineHeight)};
      text-align: ${$align ?? 'left'};

      text-decoration: ${$textDecoration ?? 'none'};

      ${$wordBreak &&
      css`
        word-break: ${$wordBreak};
      `};

      ${$whiteSpace &&
      css`
        white-space: ${$whiteSpace};
      `};

      ${$skewed &&
      css`
        transform: skewY(-5deg);
        max-width: max-content;
      `}

      ${$truncate &&
      css`
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `};

      ${$italic &&
      css`
        font-style: italic;
      `}
    `;
  },
);

const highlightAnimation = keyframes`
    from { width: 0; opacity: 0 }
    50% { opacity: 1; }
    to { width: 100%; }
  `;

export const Highlight = styled.span<{}>`
  display: inline-block;
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    content: ' ';
    display: block;
    height: 0.2em;
    width: 0;
    bottom: 0.25em;
    z-index: -1;
    background-color: ${({ theme }) => theme.color('accent')};
    animation: ${highlightAnimation} 0.35s ease-out forwards;
  }
`;

// Shorthands
export const Heading1: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.h1, ...props }} />
);
export const Heading2: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.h2, ...props }} />
);
export const Heading3: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.h3, ...props }} />
);
export const Heading4: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.h4, ...props }} />
);
export const Heading5: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.h5, ...props }} />
);
export const Heading6: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.h6, ...props }} />
);
export const SubHeading: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.subheading, ...props }} />
);
export const Label: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.label, ...props }} />
);
export const Body: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.body, ...props }} />
);
export const Code: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.code, ...props }} />
);
export const Strong: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.strong, ...props }} />
);
export const Small: React.FCC<Props> = props => (
  <Typography {...{ ...defaultProps.small, ...props }} />
);

export default Typography;
