import { IssueOptions } from '../..';
import {
  ClientFlowActionContactAssign,
  ClientFlowActionTaskCreate,
} from '~/graphql/types.client';
import { UserStatus } from '~/graphql/types';
import { isNil } from 'ramda';
import type { Issue } from '~/components/page/Automation/v2/state/flowIssues';

type Params = {
  action: ClientFlowActionContactAssign | ClientFlowActionTaskCreate;
  opts: IssueOptions;
  officeId: string | null | undefined;
  userId: string | null | undefined;
};

/**
 * Get issues for entities in ContactAssign and TaskCreate actions
 *
 * keywords: entityValidation
 */
const collectIssuesForEntities = ({
  action,
  officeId,
  userId,
  opts,
}: Params) => {
  const issues: Array<Issue> = [];

  if (!opts) return issues;

  const selectedUser = opts.users?.find(({ id }) => id === userId);
  const selectedOffice = opts.offices?.find(({ id }) => id === officeId);
  const officeOfSelectedUser = selectedUser?.officeIds?.find(
    o => o.officeId === officeId,
  );

  if (officeId !== '' && !selectedOffice) {
    issues.push({
      key: 'officeNotInAccount',
      message: `Vestiging niet in dit account, wijs een andere vestiging toe.`,
      level: 'error',
      type: 'ValidationIssue',
      actionId: action.id,
      actionType: action.actionType,
    });

    return issues;
  }

  const selectedOfficeDeleted = selectedOffice?.deleted;

  if (selectedOfficeDeleted) {
    issues.push({
      key: 'officeDeleted',
      message: `Vestiging "${selectedOffice.name}" is verwijderd. Ververs de pagina en selecteer een andere vestiging.`,
      level: 'error',
      type: 'ValidationIssue',
      actionId: action.id,
      actionType: action.actionType,
    });

    return issues;
  }

  if (!isNil(userId) && !selectedUser) {
    issues.push({
      key: 'userNotInAccount',
      message: `Gebruiker niet in dit account, wijs een andere gebruiker toe.`,
      level: 'error',
      type: 'ValidationIssue',
      actionId: action.id,
      actionType: action.actionType,
    });

    return issues;
  }

  if (!selectedUser) return issues;

  const selectedUserDeleted =
    selectedUser?.status === null ||
    selectedUser?.status === UserStatus.Deleting;

  if (selectedUserDeleted) {
    issues.push({
      key: 'userDeletedFromAccount',
      message: `Gebruiker "${selectedUser.name}" is verwijderd van het account. Ververs de pagina en selecteer een andere gebruiker.`,
      level: 'error',
      type: 'ValidationIssue',
      actionId: action.id,
      actionType: action.actionType,
    });

    return issues;
  }

  if (!officeOfSelectedUser) {
    issues.push({
      key: 'userDeletedFromOffice',
      message: `Gebruiker "${selectedUser.name}" is verwijderd van de vestiging "${selectedOffice?.name ?? 'Onbekend'}". Ververs de pagina en selecteer een andere gebruiker.`,
      level: 'error',
      type: 'ValidationIssue',
      actionId: action.id,
      actionType: action.actionType,
    });
  }

  return issues;
};

export default collectIssuesForEntities;
