import { isNil } from 'ramda';
import type { OptionOf } from '~/components/molecule/Dropdown';
import { ZapierTriggerFieldsFragment } from '~/graphql/types';

/**
 * returns an array of trigger options in appropriate form for dropdown options
 * @param {Array<ZapierTriggerFieldsFragment> | undefined} triggers
 * keywords: getZapierTriggerOptions, triggers, options, triggerOptions, zapierOptions, zapier
 */

type ZapierTriggerOptions = Array<OptionOf<ZapierTriggerFieldsFragment>>;
type Props = Array<ZapierTriggerFieldsFragment> | null;

const getZapierTriggerOptions = (triggers: Props): ZapierTriggerOptions => {
  if (isNil(triggers)) return [];

  return triggers.map(trigger => ({
    key: trigger.id,
    label: trigger.name,
    payload: trigger,
  }));
};

export default getZapierTriggerOptions;
