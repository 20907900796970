import AddTags from '../../Actions/AddTags';
import Delete from '../../Actions/Delete';
import SendCSV from '../../Actions/SendCSV';
import SendToContaqt from '../../Actions/SendToContaqt';
import UnblockEmail from '../../Actions/UnblockEmail';
import UnsubscribeEmail from '../../Actions/UnsubscribeEmail';
import AssignToWorkingAreaOfficeUser from '../../Actions/AssignToWorkingAreaOfficeUser';
import type { ContactAction, ActionModalProps } from '../../constants';

export const modalMap: Record<ContactAction, React.FC<ActionModalProps>> = {
  AddTags,
  Delete,
  SendCSV,
  SendToContaqt,
  UnsubscribeEmail,
  UnblockEmail,
  AssignToWorkingAreaOfficeUser,
};
