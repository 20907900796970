import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  COMPLETE_BUTTON: `${cleanedFilename(__filename)}-complete-button`,
  OFFICE_USER_CONTAINER: `${cleanedFilename(__filename)}-office-user-container`,
  RELATIE_API_BLOCK: `${cleanedFilename(__filename)}-relatie-api-block`,
  WONEN_API_BLOCK: `${cleanedFilename(__filename)}-wonen-api-block`,
  AGENDA_API_BLOCK: `${cleanedFilename(__filename)}-agenda-api-block`,
};
