import React, { useState } from 'react';
import Icon from '~/components/atom/Icon';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Modal from '~/components/organism/ModalsV2/Modal';
import type { RealworksTokenWithStatus } from '~/components/page/Apps/Realworks/utils/getRealworksAPIConfigs';
import type { AppStatus_Realworks_RealworksTokenAgenda_AgendaSync } from '~/graphql/types';
import AgendaSync from './components/AgendaSync';

export type Props = {
  dataTestId?: string;
  agenda: RealworksTokenWithStatus;
  agendaSync: AppStatus_Realworks_RealworksTokenAgenda_AgendaSync;
  tokenContainerId: string;
};

const AgendaSettings: React.FCC<Props> = ({
  dataTestId,
  agenda,
  agendaSync,
  tokenContainerId,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Icon
        name="gear"
        onClick={() => setShowModal(true)}
        data-testid={dataTestId}
        style={{ cursor: 'pointer' }}
      />
      {showModal && (
        <Overlay onClose={() => setShowModal(false)}>
          <Modal maxWidth="600px">
            <AgendaSync
              agenda={agenda}
              agendaSync={agendaSync}
              tokenContainerId={tokenContainerId}
              onClose={() => setShowModal(false)}
            />
          </Modal>
        </Overlay>
      )}
    </>
  );
};

export default AgendaSettings;
