import React from 'react';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import { Handle, Position } from 'reactflow';
import { Heading5, Variant } from '~/components/atom/Typography';
import { Props as HidableListProps } from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { FlowAction } from '~/graphql/types';
import getCardHeading from '../../../../utils/getCardHeading';
import TemplateString from '../TemplateString';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { type ClientFlowActionSendNotification } from '~/graphql/types.client';
import IssuesForAction from '../IssuesForAction';

export type Props = Omit<
  CardProps<ClientFlowActionSendNotification>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
} & Omit<HidableListProps, 'dropdownListOpen' | 'onClickOutside'>;

const text = {
  subject: 'Onderwerp',
};

const NotificationCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={handleStyle}
        isConnectable={false}
      />
      <CardTemplate
        {...props}
        actionType={FlowAction.SendNotification}
        heading={getCardHeading(FlowAction.SendNotification)}
        selected={selected}
        disabled={disabled}
        data={data}
        dataTestId="NotificationCard"
      >
        <div>
          <JustificationContainer justification="start" align="center">
            <Heading5 inline variant={Variant.primary} margin={[null]}>
              {text.subject}:&nbsp;
            </Heading5>
            <TemplateString
              templateString={data.action.subject}
              actionId={data.action.id}
            />
          </JustificationContainer>
          <IssuesForAction actionId={data.action.id} />
        </div>
      </CardTemplate>
      <Handle
        type="source"
        position={Position.Bottom}
        style={handleStyle}
        isConnectable={false}
      />
    </>
  ),
);

export default NotificationCard;
