import React, { useEffect, useRef, useCallback } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import styled, { css, useTheme } from 'styled-components';
import Portal from '~/components/molecule/Portal';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import VariableToolbar from '../VariableToolbar';
import { getStartPoint } from '~/components/organism/PluginsEditor/commands';
import TEST_ID from './index.testid';
import withErrorBoundary from '~/ErrorBoundary';
import usePrevious from '~/hooks/usePrevious';
import { equals } from 'ramda';
import useViewportSize from '~/hooks/useViewportSize';
import CloseButton from '~/components/atom/CloseButton';
import ContentFacade from '../ContentFacade';
import useOutsideClick from '~/hooks/useClickOutside';
import {
  hoveringToolbarState,
  type ToolbarState,
} from '~/components/organism/PluginsEditor/state/HoveringToolbarState';
import useErrorReporter from '~/hooks/useErrorReporter';
import { useResetRecoilState } from 'recoil';

export type Props = {
  toolbarElement: ToolbarState;
  setToolbarElement: (toolbarElement: ToolbarState | null) => void;
};

const text = {
  errorMessage:
    'We hebben momenteel problemen met het focussen op de teksteditor. Ververs de pagina en probeer het opnieuw. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

const TRIANGLE_HEIGHT = 20;
const VARIABLE_TOOLBAR_WIDTH = 60;
const MAX_VARIABLE_TOOLBAR_WIDTH = 700;
const VARIABLE_TOOLBAR_OFFSET = 10;

const ToolbarMainContent: React.FCC<Props> = ({
  toolbarElement,
  setToolbarElement,
}) => {
  const errorReporter = useErrorReporter();
  const editor = useSlate();
  const theme = useTheme();
  const { height } = useViewportSize();
  const element = toolbarElement.element;
  const elementRef = toolbarElement.elementRef;
  const elementPosition = elementRef.getBoundingClientRect();

  const resetToolbarElement = useResetRecoilState(hoveringToolbarState);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback(
    event => {
      const dropdownRoot = document.getElementById('dropdown-portal-root');
      const isClickOnDropdown = dropdownRoot?.contains(event.target);

      if (isClickOnDropdown) {
        return;
      }

      const timeoutId = setTimeout(() => {
        try {
          const toolbarContainerNode = containerRef.current;
          // If clicking inside the toolbarElement OR toolbarContainer, ignore
          if (
            elementRef.contains(event.target) ||
            toolbarContainerNode?.contains(event.target)
          ) {
            return;
          }

          setToolbarElement(null);
          ReactEditor.focus(editor);
        } catch (error) {
          errorReporter.captureException(
            new Error(
              `Something went wrong in the 'handleOutsideClick' of the ToolbarMainContent. This is likely connected to the 'ReactEditor.toDOMNode': ${error} `,
            ),
            'error',
          );
        }
      }, 0);

      return () => clearTimeout(timeoutId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, setToolbarElement, toolbarElement],
  );

  useOutsideClick(containerRef, handleOutsideClick);

  const path = ReactEditor.findPath(editor, element);

  const distanceToBottom = height - elementPosition.top;
  const distanceToTop = height - distanceToBottom;
  const openingDirection =
    distanceToTop - distanceToBottom > 0 ? 'top' : 'bottom';
  const firstChildHeight =
    containerRef?.current?.children?.[0]?.clientHeight || 0;

  const top =
    openingDirection === 'top'
      ? elementPosition.top - firstChildHeight
      : elementPosition.top + 10;

  useEffect(
    () => () => resetToolbarElement(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (element.type === ELEMENTS.VARIABLE) {
    const pointerDirection = openingDirection === 'top' ? 'bottom' : 'top';
    const elementCenter = elementPosition.left + elementPosition.width / 2;

    // Calculate toolbar dimensions
    // Toolbar width is 60% of viewport with max 700px
    const viewportWidth = window.innerWidth;
    const toolbarWidth = Math.min(
      (viewportWidth * VARIABLE_TOOLBAR_WIDTH) / 100,
      MAX_VARIABLE_TOOLBAR_WIDTH,
    );
    const toolbarLeft = (viewportWidth - toolbarWidth) / 2;
    const pointerOffset = elementCenter - toolbarLeft;

    return (
      <Portal root="hovering-toolbar">
        <div
          style={{
            [pointerDirection]: `${
              openingDirection === 'top'
                ? distanceToBottom + TRIANGLE_HEIGHT
                : distanceToTop + TRIANGLE_HEIGHT + VARIABLE_TOOLBAR_OFFSET
            }px`,
            position: 'absolute',
            zIndex: theme.z('top') + 100,
            left: '0',
            right: '0',
            margin: 'auto',
            width: `${VARIABLE_TOOLBAR_WIDTH}vw`,
            maxWidth: `${MAX_VARIABLE_TOOLBAR_WIDTH}px`,
          }}
          data-testid={TEST_ID.CONTAINER}
          ref={containerRef}
          data-objectid="variable-toolbar"
        >
          <VariableToolbar
            editor={editor}
            path={path}
            element={element}
            pointerOffset={pointerOffset}
            pointerLocation={pointerDirection}
          />
        </div>
      </Portal>
    );
  }

  const containerStyle = {
    top: `${top}px`,
    left: `${elementPosition.left}px`,
  };

  return (
    <Portal root="hovering-toolbar">
      <Container
        style={containerStyle}
        data-testid={TEST_ID.CONTAINER}
        ref={containerRef}
      >
        <CloseButton
          onClick={() => {
            ReactEditor.focus(editor);
            Transforms.move(editor, {
              distance: 1,
              unit: 'offset',
            });
            setToolbarElement(null);
          }}
        />
        <ContentFacade selectedEl={{ element, path }} editor={editor} />
      </Container>
    </Portal>
  );
};

const Container = styled.div<{}>`
  display: flex;
  position: absolute;
  align-items: center;
  z-index: 1;
  top: 0px;
  left: 0px;
  margin-top: -6px;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('around')};
    padding: ${theme.space('base')};
    border-radius: ${theme.getTokens().border.radius.base};
    /** TODO: Will be improved in https://app.shortcut.com/dathuis/story/13262/ensure-consistent-use-of-z-index-across-the-application */
    z-index: ${theme.z('top') + 1}; /* Make sure it is above the modal */
  `}
`;

const SlateErrorContainer = styled.div<{}>`
  ${({ theme }) => css`
    background-color: ${theme.color('danger', 'translucent')};
    color: ${theme.color('danger')};
    padding: ${theme.space('xxs')};
    margin: ${theme.space('xxs')} 0;
    border-radius: ${theme.getTokens().border.radius.base};
  `}
`;

const ErrorHandlingComp = ({ resetError }) => {
  const editor = useSlate();

  /**
   * The reason for error throwing in this component is usually 'Cannot resolve DOM point from Slate point'
   * So we move the selection to the beginning of the editor which is hopefully a focusable point
   */
  useEffect(() => {
    const firstElementPath = getStartPoint(editor);
    const point = { path: firstElementPath, offset: 0 };
    Transforms.setSelection(editor, { anchor: point, focus: point });
  }, [editor]);

  const currentChildren = editor.children;
  const prevChildren = usePrevious(currentChildren);

  useEffect(() => {
    const hasChanged = !equals(currentChildren, prevChildren);
    if (hasChanged) {
      resetError();
    }
  }, [currentChildren, prevChildren, resetError]);

  return <SlateErrorContainer>{text.errorMessage}</SlateErrorContainer>;
};

export default withErrorBoundary(ToolbarMainContent, props => (
  <ErrorHandlingComp resetError={props.resetError} />
));
