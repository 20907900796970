import React, { useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css, useTheme } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AsideHeader from '../../AsideHeader';
import Issue from './components/Issue';
import TEST_ID from './index.testid';
import useMeasure from '~/hooks/useMeasure';
import useIssuesInFlow from '../../../hooks/useIssuesInFlow';
import useDeepLinkConfigs from '~/hooks/useDeepLinkConfigs';

const text = {
  issuesHeader: 'Foutherstel',
  noIssues: 'Geen fouten',
};

export type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const Issues: React.FCC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
}) => {
  const { loading } = useDeepLinkConfigs();
  const { bounds, ref } = useMeasure();
  const theme = useTheme();
  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const { allIssues } = useIssuesInFlow();

  const hasExpanded = useRef(expanded);
  useEffect(() => {
    // If the deep link configs are still loading, don't show the issues
    if (loading) return;
    if (!hasExpanded.current && allIssues.length !== 0) {
      onToggleExpand();
      hasExpanded.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIssues, loading]);

  return (
    <Container data-testid={dataTestId}>
      <HeadingContainer align="center" onClick={() => onToggleExpand()}>
        <AsideHeader
          expanded={expanded}
          dataTestId={TEST_ID.ASIDE_HEADER}
          heading={
            <JustificationContainer align="center" gap="xs">
              {text.issuesHeader} {allIssues.length !== 0 && <IssueIndicator />}
            </JustificationContainer>
          }
          icon="exclamation"
        />
      </HeadingContainer>
      <OverflowContainer style={spring}>
        <Inner data-testid={TEST_ID.INNER_CONTAINER} ref={ref}>
          {allIssues.length === 0 && <p>{text.noIssues}</p>}
          {allIssues.map((issue, index) => (
            <Issue
              dataTestId={TEST_ID.ISSUE}
              key={`${issue.type}-${issue.message}-${index}`}
              {...issue}
            />
          ))}
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const IssueIndicator = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.full};
    background: ${theme.color('danger')};
    height: ${theme.space('m')};
    width: ${theme.space('m')};
  `,
);

const HeadingContainer = styled(JustificationContainer)`
  position: relative;
  cursor: pointer;
`;

const Container = styled.aside`
  user-select: none;
  width: 100%;
`;

const OverflowContainer = styled(animated.div)<{}>`
  overflow: hidden;
`;

const Inner = styled(animated.div)(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')} ${theme.space('m')} ${theme.space('m')};
    display: grid;
    row-gap: ${theme.spacing('xxs')};
  `,
);

export default Issues;
