import { uniq } from 'ramda';
import { ClientFlowAction } from '~/graphql/types.client';

/**
 * Deletes the action and returns the updated actions
 * @param {Array<ClientFlowAction>} actions - Actions
 * @param {ClientFlowAction} subjectAction - Action to delete
 * keywords: delete action
 */
const deleteAction = ({
  actions,
  subjectAction,
}: {
  actions: Array<ClientFlowAction>;
  subjectAction: ClientFlowAction;
}): { actions: Array<ClientFlowAction>; nextActionId: string | null } => {
  const mutableActions = [...actions];
  const parentIds = 'parentIds' in subjectAction ? subjectAction.parentIds : [];

  let nextActionIndex: number | null = null;
  let nextActionId: string | null = null;
  // actionId: parentId
  const assignedParentIdsForSibling: Record<string, string> = {};

  for (const parentId of parentIds) {
    const parentIdx = mutableActions.findIndex(a => a.id === parentId);

    const parentAction = mutableActions[parentIdx];

    const ifElseKey =
      parentAction.__typename === 'FlowV2_Action_IfElse' &&
      parentAction.trueChildId === subjectAction.id
        ? 'trueChildId'
        : 'falseChildId';

    const siblingIfElseKey =
      ifElseKey === 'trueChildId' ? 'falseChildId' : 'trueChildId';
    const siblingId = parentAction[siblingIfElseKey];

    // Prevent having the same child id on both paths of the IfElse action
    // Slice the tree and connect edges
    nextActionIndex = mutableActions.findIndex(
      action =>
        'parentIds' in action &&
        action.parentIds.includes(subjectAction.id) &&
        action.id !== siblingId,
    );
    const nextAction = mutableActions[nextActionIndex];

    const updatedParentAction: ClientFlowAction =
      parentAction.__typename === 'FlowV2_Action_IfElse'
        ? {
            ...parentAction,
            [ifElseKey]: !nextAction?.id ? null : nextAction.id,
          }
        : parentAction;

    /**
     * As we are iterating over each parent, we might assign an action as a true/false child of this ifElse action.
     * We need to keep track of the parentIds that was assigned to the nextAction so we can use it to update
     * the parentIds of the sibling action.
     */
    if (nextAction?.id) {
      assignedParentIdsForSibling[nextAction.id] = updatedParentAction.id;
    }
    mutableActions[parentIdx] = updatedParentAction;

    if (siblingId) {
      const siblingIndex = mutableActions.findIndex(
        action => action.id === siblingId,
      );

      const siblingAction = mutableActions[siblingIndex];

      const updatedSibling =
        siblingAction && siblingAction.__typename !== 'FlowV2_Action_Start'
          ? {
              ...siblingAction,
              parentIds: uniq([
                ...siblingAction.parentIds.filter(
                  id => id !== subjectAction.id,
                ),
                ...(assignedParentIdsForSibling[siblingAction.id]
                  ? [assignedParentIdsForSibling[siblingAction.id]]
                  : []),
              ]),
            }
          : siblingAction;

      mutableActions[siblingIndex] = updatedSibling;
    }
  }

  if (nextActionIndex !== null && nextActionIndex !== -1) {
    const nextAction = mutableActions[nextActionIndex];

    const nextActionHasMultipleParents =
      nextAction &&
      'parentIds' in nextAction &&
      (nextAction.parentIds.length > 1 ||
        assignedParentIdsForSibling[nextAction.id]);

    nextActionId = nextAction?.id;

    const currentParents =
      'parentIds' in nextAction ? [...nextAction.parentIds] : [];
    const subjectActionIdx = currentParents.findIndex(
      id => id === subjectAction.id,
    );

    currentParents.splice(subjectActionIdx, 1, ...parentIds);

    const nextParentIds = nextActionHasMultipleParents
      ? currentParents
      : 'parentIds' in subjectAction
        ? subjectAction.parentIds
        : [];

    const updatedNextAction: ClientFlowAction =
      'parentIds' in nextAction
        ? {
            ...nextAction,
            parentIds: nextParentIds,
          }
        : nextAction;

    mutableActions[nextActionIndex] = updatedNextAction;
  }

  return {
    actions: mutableActions.filter(({ id }) => id !== subjectAction.id),
    nextActionId,
  };
};
export default deleteAction;
