import { useRecoilValue } from 'recoil';
import flowIssues from '../../../../state/flowIssues';
import useBuilderContext from '../useBuilderContext';

const useIssuesByActionId = (actionId: string) => {
  const builderContext = useBuilderContext();
  const issues = useRecoilValue(flowIssues(builderContext));
  return issues.get(actionId) ?? [];
};

export default useIssuesByActionId;
