import { navigate } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import AssignBlock from '~/components/organism/AssignBlock';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Body } from '~/components/atom/Typography';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import { LINK_PREFIX, StepProps } from '../..';
import RealworksHelpBlock from '../RealworksHelpBlock';
import TEST_ID from './index.testid';
import { DEFAULT_TOKEN_NAME } from '~/components/page/Apps/Realworks/constants';

const text = {
  header: 'Instellingen',
  description:
    'Maak een koppeling aan en koppel de vestiging in Realworks aan een vestiging en gebruiker in DatHuis.',
  entryHeader: 'Wijs contacten toe aan een vestiging in DatHuis',
  bodyText:
    'Wijs relaties die vanuit Realworks worden geimporteerd toe aan een vestiging en gebruiker in DatHuis.',
  nextButton: 'Volgende',
};

const SetupStep: React.FCC<
  StepProps & { usedOffices: Record<string, string> }
> = ({ onCancel, usedOffices }) => {
  const [values, setValues] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input>({
      name: DEFAULT_TOKEN_NAME,
      officeId: null,
      userId: null,
    });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    void navigate(`${LINK_PREFIX}/import-contacts`, {
      replace: true,
      state: {
        values: { ...values, relatie: null, wonen: null, agenda: null },
      },
    });
  };

  return (
    <AppDetailsContainer
      header={text.header}
      icon="link"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>{text.description}</Body>

      <form onSubmit={onSubmit} data-testid={TEST_ID.FORM}>
        <RealworksHelpBlock tipId="setup-step-id" />
        <AppEntryItemHeader>{text.entryHeader}</AppEntryItemHeader>
        <Body>{text.bodyText}</Body>
        <AssignBlock
          userId={values.userId}
          officeId={values.officeId}
          onChange={({ userId, officeId }) =>
            setValues(prev => ({ ...prev, userId, officeId }))
          }
          disabledOfficeIds={Object.values(usedOffices)}
        />
        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label="Afbreken"
            onClick={onCancel}
            padding={[null]}
            appearance="danger"
            type="button"
            dataTestId="exit-wizard-button"
          />
          <Button
            icon="check"
            label={text.nextButton}
            type="submit"
            size="medium"
            loading={false}
            dataTestId={TEST_ID.NEXT_BUTTON}
            disabled={!values.officeId}
          />
        </JustificationContainer>
      </form>
    </AppDetailsContainer>
  );
};

export default SetupStep;
