import type { OptionOf } from '~/components/molecule/Dropdown';
import type {
  DoContactAction__Input,
  LockContactSearchResultsByFilterV2MutationVariables,
} from '~/graphql/types';

const text = {
  addTag: 'Tag toewijzen',
  delete: 'Verwijderen',
  sendCSV: 'Exporteren',
  sendToContaqt: 'Stuur naar Contaqt',
  unsubscribeEmail: 'Uitschrijven van e-mails',
  unblockEmail: 'E-mailadressen vrijgeven',
  assignToWorkingAreaOfficeUser: 'Toewijzen via werkgebied',
};

export type ContactAction = keyof Omit<DoContactAction__Input, 'StartFlow'>;

type ActionOption = OptionOf<ContactAction>;

export const actionOptions: Array<ActionOption> = [
  {
    label: text.addTag,
    payload: 'AddTags',
    key: 'add-tags',
    icon: { name: 'tag' },
  },
  {
    label: text.delete,
    payload: 'Delete',
    key: 'delete',
    icon: { name: 'trashcan' },
  },
  {
    label: text.sendCSV,
    payload: 'SendCSV',
    key: 'send-csv',
    icon: { name: 'file' },
  },
  {
    label: text.sendToContaqt,
    payload: 'SendToContaqt',
    key: 'send-to-contaqt',
    icon: { name: 'send' },
  },
  {
    label: text.unsubscribeEmail,
    payload: 'UnsubscribeEmail',
    key: 'unsubscribe-email',
    icon: { name: 'userX' },
  },
  {
    label: text.unblockEmail,
    payload: 'UnblockEmail',
    key: 'unblock-email',
    icon: { name: 'unlock' },
  },
  {
    label: text.assignToWorkingAreaOfficeUser,
    payload: 'AssignToWorkingAreaOfficeUser',
    key: 'assign-to-working-area-office-user',
    icon: { name: 'map-pin' },
  },
];

export type ActionModalProps = {
  lockContactsVariables: LockContactSearchResultsByFilterV2MutationVariables;
  selectedContactAmount: number;
  onComplete: () => void;
  onCancel: () => void;
};

type ActionCopy = {
  header: string;
  doActionSuccessMsg: string;
  doActionErrorMsg: string;
  lockContactsErrorMsg: string;
  lockedContacts: (count: number) => string;
};

const contactPluralOrSingle = (count: number) =>
  count === 1 ? 'contact' : 'contacten';

const baseCopy: ActionCopy = {
  header: 'Actie bevestigen',
  doActionErrorMsg:
    'Er is een fout opgetreden bij het uitvoeren van deze actie. Ververs de pagina en probeer het nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
  doActionSuccessMsg:
    'Actie bevestigd, het kan even duren voordat het op de pagina wordt weergegeven.',
  lockedContacts: (count: number) =>
    `Deze actie wordt toegepast op ${count} ${contactPluralOrSingle(
      count,
    )}. Weet je het zeker?`,
  lockContactsErrorMsg:
    'Er is een fout opgetreden bij het de selecteren van je contacten. Ververs de pagina en probeer het nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

const sendCSVCopy: ActionCopy = {
  ...baseCopy,
  header: 'Actie bevestigen',
  doActionSuccessMsg: 'De export wordt per e-mail verstuurd.',
};

const unsubscribeEmailCopy: ActionCopy = {
  ...baseCopy,
  header: 'Actie bevestigen',
  doActionSuccessMsg:
    'Contacten uitgeschreven. De uitgeschreven status is zichtbaar in het contact.',
  lockedContacts: (count: number) =>
    `Er ${count === 1 ? 'wordt' : 'worden'} ${count} ${contactPluralOrSingle(
      count,
    )} uitgeschreven. Deze ${contactPluralOrSingle(
      count,
    )} ${count === 1 ? 'ontvangt' : 'ontvangen'} geen marketing e-mails meer uit flows.`,
};

const unblockEmailCopy: ActionCopy = {
  ...baseCopy,
  header: 'Actie bevestigen',
};

export const actionsCopy: {
  [key in ContactAction]: ActionCopy;
} = {
  AddTags: baseCopy,
  SendToContaqt: baseCopy,
  Delete: baseCopy,
  SendCSV: sendCSVCopy,
  UnsubscribeEmail: unsubscribeEmailCopy,
  UnblockEmail: unblockEmailCopy,
  AssignToWorkingAreaOfficeUser: baseCopy,
};
