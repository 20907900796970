import { v4 as uuid } from 'uuid';

import {
  FormBuilder_Event_MetaField,
  FormBuilder_PrimitiveType,
  type FormBuilder_EventFragment,
} from '~/graphql/types';
import generateIdForEntity from '~/util/generateIdForEntity';

const text = {
  newEvent: 'Nieuwe event template',
};

/**
 * Create a new event template
 */
const getNewEventTemplate = (): FormBuilder_EventFragment => ({
  __typename: 'FormBuilder_Event',
  fields: [
    {
      __typename: 'FormBuilder_Event_Field',
      key: uuid(),
      name: 'Email',
      type: {
        __typename: 'FormBuilder_Event_Field_Type_Primitive',
        type: FormBuilder_PrimitiveType.Email,
      },
      metaField: FormBuilder_Event_MetaField.ContactEmail,
    },
  ],
  id: generateIdForEntity('FORMBUILDER_EVENT'),
  name: text.newEvent,
});

export default getNewEventTemplate;
