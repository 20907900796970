import serializeStringInput from '~/components/organism/TemplateStringInput/utils/serializeStringInput';
import {
  FlowV2_Action__Input,
  type FlowV2_Action_SendNotification__Input,
} from '~/graphql/types';
import { type ClientFlowActionSendNotification } from '~/graphql/types.client';
import removeTypenames from '~/util/removeTypenames';

/**
 *
 * @param {ClientFlowActionSendNotification} action - SendNotification client action
 *
 */
const serializeSendNotificationAction = (
  action: ClientFlowActionSendNotification,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    subject,
    body,
    attachments,
    to,
  } = action;

  const attachmentInput = attachments.map(({ file }) => {
    if (file.__typename === 'Flow___Argument_Pointer') {
      return {
        file: {
          pointer: {
            path: file.pointer.path,
          },
        },
        inlineId: null,
      };
    }

    const { contentLength, filename, s3key } = file.value_file;

    return {
      file: {
        value_file: {
          contentLength,
          filename,
          s3key,
        },
      },
      inlineId: null,
    };
  });

  const inputValue = removeTypenames<FlowV2_Action_SendNotification__Input>({
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    subject: serializeStringInput(subject),
    body,
    attachments: attachmentInput,
    to,
  });

  return {
    SendNotification: inputValue,
  };
};
export default serializeSendNotificationAction;
