import React, { useState } from 'react';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import BasePopover from '../../components/BasePopover';
import type { ActionModalProps } from '../../constants';

const text = {
  title: 'Contacten toewijzen aan vestiging en gebruiker via werkgebied',
  confirm: 'Bevestigen',
  popoverDescription:
    'Contacten binnen een werkgebied worden automatisch toegewezen aan de bijbehorende vestiging en gebruiker.',
};

export type Props = {} & ActionModalProps;

const AssignToWorkingAreaOfficeUser: React.FCC<Props> = ({
  onCancel,
  onComplete,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  return (
    <>
      {showModal ? (
        <ConfirmActionModal
          actionType="AssignToWorkingAreaOfficeUser"
          actionInput={{
            AssignToWorkingAreaOfficeUser: {
              noOperation: null,
            },
          }}
          onComplete={() => {
            hideModal();
            onComplete();
          }}
          onCancel={hideModal}
          {...rest}
        />
      ) : (
        <BasePopover
          title={text.title}
          handleAction={confirm => {
            if (confirm) {
              setShowModal(true);
            } else {
              onCancel();
            }
          }}
          confirmText={text.confirm}
        >
          {text.popoverDescription}
        </BasePopover>
      )}
    </>
  );
};

export default AssignToWorkingAreaOfficeUser;
