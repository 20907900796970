import { atom, selector } from 'recoil';
import { ClientFlowAction } from '~/graphql/types.client';

export type InteractionUpdateAction = {
  type: 'updateAction';
  action: ClientFlowAction;
};

export type InteractionDragging = {
  type: 'dragging';
  action?: ClientFlowAction;
};

export type InteractionUpdateName = {
  type: 'updateName';
};

export type InteractionCopyAction = {
  type: 'copyAction';
  copiedAction: ClientFlowAction;
  // Used to reset actions to the state before copying started
  actionsBeforeCopy: Array<ClientFlowAction>;
};

export type InteractionConnectAction = {
  type: 'connectAction';
  action: ClientFlowAction;
  validTargets: Array<ClientFlowAction>;
  childIdKey?: 'trueChildId' | 'falseChildId';
};

export type InteractionHighlightActions = {
  type: 'highlightActions';
  highlightedActions: Array<string>;
};

export type Interaction =
  | InteractionDragging
  | InteractionUpdateAction
  | InteractionCopyAction
  | InteractionUpdateName
  | InteractionConnectAction
  | InteractionHighlightActions;

/** Disables some edges */
export const shouldDisable = selector({
  key: 'interactionShouldDisable',
  get: ({ get }) => {
    const currentInteraction = get(interactions);

    return (
      currentInteraction?.type === 'highlightActions' ||
      currentInteraction?.type === 'connectAction' ||
      currentInteraction?.type === 'copyAction'
    );
  },
});

const interactions = atom<Interaction | null>({
  key: 'flowbuilderActiveInteraction',
  default: null,
});

export default interactions;
