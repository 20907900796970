import {
  type DeepLink_ConfigFragment,
  type DeepLinkFragment,
} from '~/graphql/types';
import {
  MISSING_DEEP_LINK_CONFIG_ID,
  TEMPLATE_STRING_DEEP_LINK_REGEX,
} from '~/components/organism/PluginsEditor/constants';
import isConfigEqual from '../../deepLinks/isConfigEqual';
import extractStyleAttributes from '../../flows/extractStyleAttributes';

export const BAD_DEEP_LINK_NAME = 'bad-deep-link';

/**
 * Converts deepLinks with _%uuid%_ into <deeplink> element in the editor
 * which later gets deserialized and inserted as a DeepLinkElement in the DHEditor
 *
 * keywords: deepLink
 */
type Params = {
  str: string;
  deepLinks: Array<DeepLinkFragment>;
  deepLinkConfigs: Array<DeepLink_ConfigFragment>;
};

const convertDeepLinksInHtml = ({
  str,
  deepLinks,
  deepLinkConfigs,
}: Params): string => {
  if (!str) return '';

  return str.replace(
    TEMPLATE_STRING_DEEP_LINK_REGEX,
    (match, allAttributes, mappingId) => {
      const styleAttributes = allAttributes
        ? extractStyleAttributes(allAttributes)
        : '';

      const matchingDeepLink = deepLinks?.find(
        deeplink => deeplink.id === mappingId,
      );

      const matchingConfig = deepLinkConfigs?.find(deepLinkConfig =>
        isConfigEqual({
          configItem: deepLinkConfig,
          item: {
            type: matchingDeepLink?.link.type,
            typeId: matchingDeepLink?.link.typeId,
          },
        }),
      );

      if (matchingDeepLink == null) {
        return match; // If it is not found return it as text
      }

      const { id, link, text } = matchingDeepLink;

      const mappingString = JSON.stringify(link.mapping).replace(/"/g, "'");

      return `<deeplink ${styleAttributes} data-deeplink="true" mappingId="${id}" deeplinkText="${text.template}" deeplinkType="${link.type}" deeplinkConfigName="${matchingConfig?.name || MISSING_DEEP_LINK_CONFIG_ID}" deeplinkTypeId="${link.typeId}" mapping="${mappingString}"></deeplink>`;
    },
  );
};

export default convertDeepLinksInHtml;
