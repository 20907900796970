import React, { HTMLAttributes, useState } from 'react';
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import copyTextToClipboard from '~/util/copyTextToClipboard';

import Icon from '../Icon';
import JustificationContainer from '../JustificationContainer';

import TEST_ID from './index.testid';
import CodeBlock from '~/components/molecule/CodeBlock';
import TextButton from '../TextButton';
import Div, { Props as DivProps } from '../Div';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = DivProps & {
  code: string;
  label?: React.ReactNode;
  copyLabel?: string;
  backgroundColor?: ThemeColor;
} & HTMLAttributes<HTMLDivElement>;

const CopyCode: React.FCC<Props> = ({
  dataTestId,
  label,
  code,
  copyLabel = 'Code kopiëren',
  backgroundColor,
  ...rest
}) => {
  const copyTarget = useRef<HTMLInputElement | null>(null);
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    void copyTextToClipboard(code)
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        setCopied(false);
      });

    const timer = setTimeout(() => {
      clearTimeout(timer);
      setCopied(false);
    }, 1200);
  };

  return (
    <Div data-testid={dataTestId} {...rest}>
      <JustificationContainer
        margin={[null, null, 'xxxs', null]}
        justification="space-between"
        align="center"
      >
        {label}
        {copied ? (
          <StatusUpdate data-testid={TEST_ID.COPIED_LABEL}>
            <Icon name="check" />
            &nbsp;
            <span>Gekopieerd</span>
          </StatusUpdate>
        ) : (
          <TextButton
            padding={[null]}
            onClick={onCopy}
            size="medium"
            dataTestId={TEST_ID.COPY_LABEL}
            label={copyLabel}
          />
        )}
      </JustificationContainer>
      <CodeBlock backgroundColor={backgroundColor}>
        <span ref={copyTarget}>{code}</span>
      </CodeBlock>
    </Div>
  );
};

const StatusUpdate = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('success')};
    display: flex;
    align-items: flex-start;
    line-height: ${theme.lineHeight('s')};
  `,
);

export default CopyCode;
