import React from 'react';
import Issue from '../../../Issues/InCard';
import useBuilderContext from '../../../../hooks/useBuilderContext';
import flowIssues from '~/components/page/Automation/v2/state/flowIssues';
import { useRecoilValue } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { SystemSize } from '~/theme';

export type Props = {
  dataTestId?: string;
  className?: string;
  actionId: string;
  margin?: Array<SystemSize | null>;
};

const IssuesForAction: React.FCC<Props> = ({
  dataTestId,
  className,
  actionId,
  margin = [],
}) => {
  const builderContext = useBuilderContext();
  const issues = useRecoilValue(flowIssues(builderContext));
  const issuesForAction = issues.get(actionId) ?? [];

  if (!issuesForAction.length) {
    return null;
  }

  return (
    <JustificationContainer
      direction="column"
      gap="xxs"
      data-testid={dataTestId}
      className={className}
      margin={margin}
    >
      {issuesForAction.map(issue => (
        <Issue key={issue.message} {...issue} />
      ))}
    </JustificationContainer>
  );
};

export default IssuesForAction;
