import React from 'react';

import Profile from './Profile';
import Account from './Account';
import Invoices from './Invoices';
import Offices from './Offices';
import Users from './Users';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

import Wallet from './Wallet';
import useUserRights from '~/hooks/useUserRights';
import SubscriptionSettings from './Subscription';
import Brand from './Brand';
import Widget from '../Widget';
import DHRouter from '~/components/atom/DHRouter';
import NotFound from '../404';

const SettingsPage: React.FCC<RouteComponentProps> = () => {
  const { view: mayViewSubscription } = useUserRights({
    category: 'Subscription',
  });

  return (
    <DHRouter>
      <Profile path="/profile" />
      <Offices path="/offices/*" />
      <Users path="/users/*" />
      <Account path="/account/*" />
      <Brand path="/brand/*" />
      {mayViewSubscription && <SubscriptionSettings path="/subscription/*" />}
      <Invoices path="/invoices/*" />
      <Wallet path="/wallet/*" />
      <Widget path="/widget/*" />
      <NotFound default />
    </DHRouter>
  );
};

export default SettingsPage;
