import {
  FlowV2_Action__Input,
  type FlowV2_Action_Trickle__Input,
} from '~/graphql/types';
import { type ClientFlowActionTrickle } from '~/graphql/types.client';
import removeTypenames from '~/util/removeTypenames';

/**
 *
 * @param {ClientFlowActionTrickle} action - Trickle client action
 *
 */
const serializeTrickleAction = (
  action: ClientFlowActionTrickle,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, parentIds, rules, limits } = action;

  const inputValue: FlowV2_Action_Trickle__Input = removeTypenames({
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    // We only support one rule for now
    rules: rules.length > 0 ? [rules[0]] : [],
    limits: limits.map(limit => {
      const amount = Number(limit.amount);
      const value = Number(limit.period.value);

      if (isNaN(amount)) {
        throw new Error(`Invalid amount value: ${limit.amount}`);
      }
      if (isNaN(value)) {
        throw new Error(`Invalid period value: ${limit.period.value}`);
      }

      return {
        amount,
        period: {
          type: limit.period.type,
          value,
        },
      };
    }),
  });

  return {
    Trickle: inputValue,
  };
};
export default serializeTrickleAction;
