import React from 'react';
import { animated, useTransition, type UseTransitionProps } from 'react-spring';
import styled from 'styled-components';
import Button from '~/components/atom/Button';

import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Body } from '~/components/atom/Typography';
import Dropdown from '~/components/molecule/Dropdown';
import Input from '~/components/molecule/Input';
import { Trickle_PeriodType } from '~/graphql/types';
import { type ClientFlowActionTrickle } from '~/graphql/types.client';
import { ANIMATION_CONFIG } from '~/styles/constants';
import { VALIDATION_FOR_LIMIT_FIELDS, PERIOD_OPTIONS } from '../../constants';

export type Props = {
  dataTestId?: string;

  /** The limits to display */
  limits: Array<ClientFlowActionTrickle['limits'][number]>;

  /** Updates the limit at the given index */
  onChange: (
    index: number,
    updatedFields: Partial<ClientFlowActionTrickle['limits'][number]>,
  ) => void;

  /** Deletes the limit at the given index */
  onDelete: (index: number) => void;

  /** Inserts a new limit */
  onInsertLimit: () => void;
};

const text = {
  description:
    'Stel in hoeveel contacten er per tijdsperiode verwerkt mogen worden.',
  addLimit: 'Limiet toevoegen',
  mustBePositive: 'Waarde moet positief zijn',
  process: 'Verwerk',
  itemsPer: 'contacten per',
};

const LimitsContainer: React.FC<Props> = ({
  limits,
  onChange,
  onDelete,
  onInsertLimit,
}) => {
  const limitsWithIndex = limits.map((limit, index) => ({
    ...limit,
    index,
  }));

  const transitions = useTransition(limitsWithIndex, transitionProps);

  return (
    <JustificationContainer width="100%" direction="column" gap="base">
      <Body>{text.description}</Body>

      {transitions((style, limit) => (
        <AnimatedDiv style={style}>
          <JustificationContainer
            width="100%"
            gap="m"
            align="center"
            justification="center"
            key={limit.index}
          >
            <Body margin={[null]}>{text.process}</Body>
            <Input
              type="number"
              value={limit?.amount}
              onChange={e => {
                onChange(limit.index, {
                  amount: e.target.value || '',
                });
              }}
              width="125px"
              externalErrors={validateNumberField({
                amount: limit?.amount || '',
                fieldType: 'contacts',
                min: 0,
              })}
              min={0}
            />

            <Body margin={[null]} whiteSpace="nowrap">
              {text.itemsPer}
            </Body>

            <Input
              type="number"
              value={limit?.period?.value}
              onChange={e => {
                onChange(limit.index, {
                  period: {
                    ...limit.period,
                    value: e.target.value,
                  },
                });
              }}
              width="75px"
              min={1}
              externalErrors={validateNumberField({
                amount: limit?.period?.value || '',
                fieldType:
                  limit.period.type === Trickle_PeriodType.Days
                    ? 'days'
                    : 'hours',
                min: 1,
              })}
            />

            <Dropdown
              options={PERIOD_OPTIONS}
              selectedOptionIdx={
                limit.period.type === Trickle_PeriodType.Days ? 0 : 1
              }
              onChange={({ option }) => {
                onChange(limit.index, {
                  period: {
                    ...limit.period,
                    type: option.payload.value,
                  },
                });
              }}
              width="100px"
            />
            <Button
              appearance="danger"
              icon="trashcan"
              ghost
              size="medium"
              onClick={() => onDelete(limit.index)}
            />
          </JustificationContainer>
        </AnimatedDiv>
      ))}

      <StyledTextButton
        icon="plus"
        label={text.addLimit}
        size="large"
        onClick={onInsertLimit}
      />
    </JustificationContainer>
  );
};

const validateNumberField = ({
  amount,
  fieldType,
  min,
}: {
  amount: string;
  fieldType: keyof typeof VALIDATION_FOR_LIMIT_FIELDS;
  min: number;
}): Array<string> | undefined => {
  if (parseInt(amount) < min) {
    return [`Minimum ${min}`];
  }

  if (parseInt(amount) > VALIDATION_FOR_LIMIT_FIELDS[fieldType].maxAmount) {
    return [VALIDATION_FOR_LIMIT_FIELDS[fieldType].message];
  }

  return undefined;
};

const transitionProps: UseTransitionProps = {
  from: { transform: 'translate(0, -40px)', opacity: 0 },
  enter: { transform: 'translate(0, 0px)', opacity: 1 },
  leave: { transform: 'translate(0, -40px)', opacity: 0 },
  config: ANIMATION_CONFIG.config,
  trail: 125,
  keys: item => item.index,
};

const StyledTextButton = styled(TextButton)`
  align-self: center;
`;

const AnimatedDiv = styled(animated.div)`
  width: 100%;
`;

export default LimitsContainer;
