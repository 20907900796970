import React from 'react';

import TEST_ID from './index.testid';
import { isNil } from 'ramda';
import type { Size } from '~/styles/constants';
import DebouncedInput from '../DebouncedInput';

export type Props = {
  className?: string;

  /** Input's disabled state */
  disabled?: boolean;

  /** Initial value of the input */
  initialValue?: string;

  /** Input placeholder */
  placeholder?: string;

  /** Size of the input */
  size?: Size;

  /** get access to input value */
  onFilterChange: (text: string) => void;
};

const text = {
  placeholder: 'Zoek...',
  search: 'Zoek',
};

const SearchInput: React.FCC<Props> = ({
  disabled,
  placeholder,
  size,
  onFilterChange,
}) => {
  const onSearch = (value: string) => {
    if (!isNil(value)) {
      onFilterChange(value);
    }
  };

  return (
    <DebouncedInput
      onDebouncedChange={onSearch}
      name="search"
      type="search"
      placeholder={placeholder ?? text.placeholder}
      disabled={disabled}
      dataTestId={TEST_ID.INPUT}
      size={size}
      icon={{ name: 'search' }}
    />
  );
};

export default SearchInput;
