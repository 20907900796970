import generateIdForEntity from '~/util/generateIdForEntity';
import { createNewOption } from '../../components/InputWithOptions/components/OptionsEditor';
import type { FormBuilder_OptionListFragment } from '~/graphql/types';

const createEmptyList = ({
  name,
  isAVGBlock,
}: {
  name: string;
  isAVGBlock?: boolean;
}): FormBuilder_OptionListFragment => {
  const id = generateIdForEntity('FORMBUILDER_OPTION');
  if (isAVGBlock) {
    return {
      id,
      name,
      __typename: 'FormBuilder_OptionList',
      options: [
        createNewOption({
          __typename: 'FormBuilder_Label_Intl',
          en: `I agree to the processing of my data from this form. Based on the results, the advisor may contact me to discuss my personal situation free of charge and without obligation.`,
          nl: `Ik ga akkoord met het verwerken van mijn gegevens uit dit formulier. De vastgoedprofessional mag op basis van de uitkomsten contact met mij opnemen, om gratis en vrijblijvend mijn persoonlijke situatie te bespreken.`,
        }),
      ],
    };
  }

  return {
    id,
    name,
    __typename: 'FormBuilder_OptionList',
    options: [
      createNewOption({
        __typename: 'FormBuilder_Label_Intl',
        en: `Option 1`,
        nl: `Optie 1`,
      }),
    ],
  };
};

export default createEmptyList;
