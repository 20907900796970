import { omit } from 'ramda';
import { OutputMap } from '~/components/organism/WizardSteps';
import {
  AppStatus_Contaqt_Metadata__Input,
  AppStatus_Hypotheekbond_Metadata__Input,
  AppStatus_Realworks_Metadata__Input,
  AppStatus_Simple_Metadata__Input,
  AppStatus_Trustoo_Metadata__Input,
  AppStatus_Zapier_Metadata__Input,
} from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';
import { reporter } from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';

type AppTypeMetadataInput =
  | AppStatus_Zapier_Metadata__Input
  | AppStatus_Hypotheekbond_Metadata__Input
  | AppStatus_Trustoo_Metadata__Input
  | AppStatus_Contaqt_Metadata__Input
  | AppStatus_Simple_Metadata__Input
  | undefined;

const getMetadataForAppStatus = (
  appStatus: AppStatus,
  outputMap: OutputMap,
): AppTypeMetadataInput => {
  const addedFlows =
    'selectedTemplates' in outputMap.EnableFlowsForAppStatus
      ? outputMap.EnableFlowsForAppStatus.selectedTemplates.value
      : [];

  if (appStatus.__typename === 'AppStatus_Realworks') {
    const addedTokens: Array<string> = [];
    if (
      'relatieToken' in outputMap.RealworksImportContacts &&
      outputMap.RealworksImportContacts.relatieToken?.value
    ) {
      addedTokens.push(outputMap.RealworksImportContacts.relatieToken.value);
    }
    // if (
    //   'wonenToken' in outputMap.RealworksExportContacts &&
    //   outputMap.RealworksExportContacts.wonenToken?.value
    // ) {
    //   addedTokens.push(outputMap.RealworksExportContacts.wonenToken.value);
    // }

    if (
      'agendaToken' in outputMap.RealworksAgenda &&
      outputMap.RealworksAgenda.agendaToken?.value
    ) {
      addedTokens.push(outputMap.RealworksAgenda.agendaToken.value);
    }

    const metadata: AppStatus_Realworks_Metadata__Input = {
      setup: {
        ...omit<any, '__typename' | 'locked' | 'permission'>(
          ['__typename', 'locked', 'permission'],
          appStatus.metadata?.setup ?? { completed: false },
        ),
        addedFlows,
        addedTokens,
      },
    } as AppStatus_Realworks_Metadata__Input;

    return metadata;
  }

  if (appStatus.__typename === 'AppStatus_Contaqt') {
    const addedToken: string | null =
      'contaqtToken' in outputMap.AddContaqtKoppeling
        ? outputMap.AddContaqtKoppeling.contaqtToken.value
        : null;

    if (addedToken === null) {
      reporter.captureException(
        new Error(
          `Could not find token for ${
            appStatus.__typename
          } in ${cleanedFilename(__filename)}`,
        ),
        'error',
      );
      // still return the metadata so we can set the other fields
    }

    const metadata: AppStatus_Contaqt_Metadata__Input = {
      setup: {
        ...omit<any, '__typename' | 'locked' | 'permission'>(
          ['__typename', 'locked', 'permission'],
          appStatus.metadata?.setup ?? { completed: false },
        ),
        addedFlows,
        addedToken,
      },
    } as AppStatus_Contaqt_Metadata__Input;

    return metadata;
  }

  const metadata:
    | AppStatus_Zapier_Metadata__Input
    | AppStatus_Hypotheekbond_Metadata__Input
    | AppStatus_Simple_Metadata__Input = {
    setup: {
      completed: false,
      ...omit<any, '__typename' | 'locked' | 'permission'>(
        ['__typename', 'locked', 'permission'],
        ('metadata' in appStatus && appStatus.metadata?.setup) ?? {
          completed: false,
        },
      ),
      addedFlows,
    },
  };

  return metadata;
};

export default getMetadataForAppStatus;
