import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import BaseButton from '../components/Base';
import EditUrlBlock from './components/EditUrlBlock';
import { tooltipText } from '../text';
import { insertLink } from '~/components/organism/PluginsEditor/commands/modify/link';
import ResponsivePopup, {
  BoxShadowContainer,
} from '~/components/molecule/ResponsivePopup';
import { Editor } from 'slate';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import TEST_ID from './index.testid';

const text = {
  heading: 'Link invoegen',
};

export type Props = {
  editor: DHEditor;
};

const InsertLinkButton: React.FCC<Props> = ({
  dataTestId,
  editor,
  ...rest
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const openerRef = useRef<HTMLButtonElement | null>(null);

  const urlText = editor.selection
    ? Editor.string(editor, editor.selection)
    : '';

  return (
    <Container {...rest}>
      <BaseButton
        icon="link"
        onClick={() => setShowPopup(true)}
        tooltipMessage={showPopup ? '' : tooltipText.insertLink}
        dataTestId={dataTestId}
        active={showPopup}
        ref={openerRef}
      />

      <ResponsivePopup
        onClickOutside={() => setShowPopup(false)}
        isVisible={showPopup}
        openerRef={openerRef.current}
        dataTestId={TEST_ID.INSERT_LINK_POPUP}
        rootId="hovering-toolbar"
      >
        <BoxShadowContainer>
          <EditUrlBlock
            onSave={({ url, text }) => {
              insertLink(editor, url, text);
              setShowPopup(false);
            }}
            textValue={urlText}
            autoFocus
            headingText={text.heading}
          />
        </BoxShadowContainer>
      </ResponsivePopup>
    </Container>
  );
};

const Container = styled.div<{}>`
  position: relative;
`;

export default InsertLinkButton;
