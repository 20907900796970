import React from 'react';
import styled, { css } from 'styled-components';
import { formattedDateWithMonth, isToday } from '~/util/date';

type Props = {
  value: Date;
};

const DateCell: React.FCC<Props> = ({ value }) => (
  <Container $isToday={isToday(value)}>
    {formattedDateWithMonth(value, {
      showTodayAsLabel: true,
      showYesterdayAsLabel: true,
    })}
  </Container>
);

type LabelProps = {
  $isToday: boolean;
};
const Container = styled.span<LabelProps>(({ theme, $isToday }) => {
  if ($isToday) {
    return css`
      color: ${theme.color('success')};
    `;
  }
  return null;
});

export default DateCell;
