import React from 'react';
import styled, { css } from 'styled-components';
import Label from '~/components/template/EventTimelineV2/components/Block/components/Data/components/Label';
import { Body } from '~/components/atom/Typography';
import { type DateBlock } from '~/graphql/types';
import { BLUR_STYLES } from '../styles';
import { format } from 'date-fns';

export type Props = Omit<DateBlock, '__typename'> & {
  dataTestId?: string;

  // AWSDateTime
  value: string;

  // date-fns format
  dateFormat: string;
};

const DateBlock: React.FC<Props> = ({
  label,
  value,
  css,
  blurred,
  dateFormat = 'd MMM yyyy H:mm',
}) => (
  <Container css={css} $blurred={blurred || false}>
    {label && <Label>{label}</Label>}
    <Body as="div" size="s">
      {format(new Date(value), dateFormat)}
    </Body>
  </Container>
);

const Container = styled.div<{ $blurred: boolean }>(
  ({ theme, $blurred }) => css`
    margin: ${theme.space('xxxs')} 0;

    ${$blurred && BLUR_STYLES};
  `,
);

export default DateBlock;
