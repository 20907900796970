import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { FlowV2_Update_Metadata } from '~/graphql/types';
import { Body } from '~/components/atom/Typography';
import RadioButton from '~/components/molecule/RadioButton';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import metadata from '~/components/page/Automation/v2/state/metadata';

import { FlowV2_Update_Action } from '~/graphql/types';
import dialogs from '~/components/page/Automation/v2/state/dialogs';
import getDirectChildActions from '../../../../utils/getDirectChildActions';
import { flowActions } from '~/components/page/Automation/v2/state/actions';

export type Props = {
  dataTestId?: string;
  className?: string;
};

const text = {
  confirmModalHeader: 'Wat moeten we doen met de contacten in deze stap?',
  confirmModalDescription:
    'Er lijken contacten in deze stap van de flow te zitten. Wil je deze contacten doorsturen naar de volgende stap in de flow of de flow stopzetten voor deze contacten?',
  continue: 'Doorsturen naar de volgende stap in de flow',
  drop: 'De flow stoppen voor deze contacten',
  confirm: 'Bevestigen',
};

const MetadataDialog: React.FCC<Props> = ({}) => {
  const [dialog, setDialog] = useRecoilState(dialogs);
  const actions = useRecoilValue(flowActions);

  const setFlowMetadata = useSetRecoilState(metadata);
  const [updateAction, setUpdateAction] = useState<FlowV2_Update_Action>(
    FlowV2_Update_Action.Continue,
  );

  if (!dialog) return null;
  if (dialog.type !== 'dialogMetadataForDeletedAction') return null;

  return (
    <Overlay onClose={() => setDialog(null)}>
      <Dialog
        header={text.confirmModalHeader}
        body={
          <JustificationContainer direction="column" gap="m" width="100%">
            <Body>{text.confirmModalDescription}</Body>
            <RadioButton
              checked={updateAction === FlowV2_Update_Action.Continue}
              onChange={() => setUpdateAction(FlowV2_Update_Action.Continue)}
              label={text.continue}
              dataTestId={FlowV2_Update_Action.Continue}
            />
            <RadioButton
              checked={updateAction === FlowV2_Update_Action.Drop}
              onChange={() => setUpdateAction(FlowV2_Update_Action.Drop)}
              label={text.drop}
              dataTestId={FlowV2_Update_Action.Drop}
            />
          </JustificationContainer>
        }
        buttons={[
          {
            dataTestId: 'modal-confirm-button',
            label: text.confirm,
            onClick: () => {
              setFlowMetadata(prev => {
                const filteredPrevious = prev.filter(
                  act => act.flowBlueprintActionId !== dialog.action.id,
                );
                const directChildren = getDirectChildActions({
                  actions,
                  action: dialog.action,
                });

                return [
                  ...filteredPrevious,
                  {
                    flowBlueprintActionId: dialog.action.id,
                    action: updateAction,
                    ...(updateAction === FlowV2_Update_Action.Continue
                      ? {
                          nextFlowBlueprintActionId:
                            directChildren.length > 0
                              ? directChildren[0].id
                              : null,
                        }
                      : {}),
                  } as FlowV2_Update_Metadata,
                ];
              });

              dialog.onConfirm();
              setDialog(null);
            },
          },
        ]}
      />
    </Overlay>
  );
};

export default MetadataDialog;
