import { atom, selector } from 'recoil';
import { initialFlowState } from '.';
import type { SettingsIssue } from './flowIssues';

const text = {
  flowNameRequired: 'De naam van de flow is vereist',
};

const DEFAULT_FLOW_SETTINGS = {
  enabled: false,
  maximumFlowRun: null,
  flowName: '',
  flowDescription: null,
};

export type FlowSettings = {
  enabled: boolean;
  maximumFlowRun: number | null;
  flowName: string;
  flowDescription: string | null;
};
const flowSettings = atom<FlowSettings>({
  key: 'flowSettings',
  default: selector({
    key: 'flowSettings/default',
    get: ({ get }) => {
      const initialFlow = get(initialFlowState);
      if (!initialFlow) return DEFAULT_FLOW_SETTINGS;

      return {
        enabled: initialFlow.enabled,
        maximumFlowRun: initialFlow.maximumFlowRun || null,
        flowName: initialFlow.flowName,
        flowDescription: initialFlow.flowDescription || null,
      };
    },
  }),
});

export default flowSettings;

export const flowNameSelector = selector({
  key: 'flowNameSelector',
  get: ({ get }) => {
    const { flowName } = get(flowSettings);
    return flowName;
  },
});

export type FlowSettingsChanges = {
  flowName: boolean;
  flowDescription: boolean;
  maximumFlowRun: boolean;
  enabled: boolean;
};

export const flowSettingsChanges = selector<FlowSettingsChanges>({
  key: 'flowSettingsChanges',
  get: ({ get }) => {
    const current = get(flowSettings);
    const initial = get(initialFlowState);

    if (!initial)
      return {
        enabled: false,
        maximumFlowRun: false,
        flowName: false,
        flowDescription: false,
      };

    return {
      enabled: current.enabled !== initial.enabled,
      maximumFlowRun: current.maximumFlowRun !== initial.maximumFlowRun,
      flowName: current.flowName !== initial.flowName,
      flowDescription: current.flowDescription !== initial.flowDescription,
    };
  },
});

export const flowSettingsIssues = selector<Map<string, Array<SettingsIssue>>>({
  key: 'flowSettingsIssues',
  get: ({ get }) => {
    const current = get(flowSettings);

    const issues = new Map<string, Array<SettingsIssue>>();

    if (current.flowName == null || current.flowName === '') {
      issues.set('flowName', [
        {
          type: 'SettingsIssue',
          level: 'error',
          message: text.flowNameRequired,
        },
      ]);
    }

    return issues;
  },
});
