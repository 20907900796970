import { ClientFlowActionRealworksSendContact } from '~/graphql/types.client';
import { Issue } from '~/components/page/Automation/v2/state/flowIssues';
import hasValue from '~/util/hasValue';

const text = {
  officeRequired: 'Vestiging is verplicht',
};

const collectIssuesForRealworks = (
  action: ClientFlowActionRealworksSendContact,
): Array<Issue> => {
  const issues: Array<Issue> = [];

  if (!hasValue(action.tokenContainerId)) {
    issues.push({
      type: 'ValidationIssue',
      level: 'error',
      key: 'tokenContainerId',
      message: text.officeRequired,
      actionId: action.id,
      actionType: action.actionType,
    });
  }

  return issues;
};

export default collectIssuesForRealworks;
