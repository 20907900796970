import React from 'react';
import type { TipId } from '~/components/organism/TipBanner';
import RealworksHelpBlock from '~/components/organism/WizardSteps/Apps/Realworks/components/RealworksHelpBlock';
import GoToPath from '~/components/organism/GoToPath';
import JustificationContainer from '~/components/atom/JustificationContainer';
import CopyCode from '~/components/atom/CopyCode';
import { Label, Variant } from '~/components/atom/Typography';
import { bdnlRealworksDeveloperId } from '~/components/page/Apps/Realworks/constants';
import InputWithIndicator from '~/components/molecule/InputWithIndicator';
import Screenshot from '~/components/molecule/Screenshot';
import RealworksCallToActionBlock from '../RealworksCallToActionBlock';
import SkipStepButtonBlock from '~/components/organism/WizardSteps/components/SkipStepButtonBlock';

const SCREENSHOT_MAX_WIDTH = 720;

const text = {
  goToCRMLabel: 'Ga naar marketplace',
  goToRealworksCRM: '1. Ga in Realworks naar',
  pasteDeveloperID: '2. Plak ons Developer ID in Realworks',
  copyToken: '3. Kopieer de “Token” en plak deze in dit veld',
  skipStepDefaultDescription: 'Je kunt deze stap later invullen',
};

export type SkipStepConfig = {
  usage: 'app' | 'wizard';
  onSkip?: () => void;
  description?: string;
};

type Props = {
  description?: React.ReactNode;
  formConfig: {
    header: React.ReactNode;
    pathToCRM: {
      path: Array<string>;
      link: string;
    };
    screenshotLink: string;
    footer?: React.ReactNode;
    value?: string | null;
    validate: (value: string) => string | true;
    onChange: (value: string) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  };
  skipStepConfig?: SkipStepConfig;
  helpBlock?: { tipId: TipId };
};

const RealworksTokenBodyTemplate: React.FC<Props> = ({
  description,
  skipStepConfig,
  helpBlock,
  formConfig,
}) => (
  <>
    {description}
    {skipStepConfig &&
      (skipStepConfig?.usage === 'app' && skipStepConfig.onSkip ? (
        <RealworksCallToActionBlock
          skipCurrentStep={skipStepConfig.onSkip}
          description={
            skipStepConfig.description ?? text.skipStepDefaultDescription
          }
        />
      ) : (
        <SkipStepButtonBlock
          margin={['base', null]}
          description={
            skipStepConfig.description ?? text.skipStepDefaultDescription
          }
        />
      ))}
    {helpBlock && <RealworksHelpBlock tipId={helpBlock.tipId} />}
    <form onSubmit={formConfig.onSubmit} data-testid="realworks-token-form">
      <Label size="m" variant={Variant.primary}>
        {text.goToRealworksCRM}
      </Label>
      <GoToPath
        path={formConfig.pathToCRM.path}
        label={text.goToCRMLabel}
        link={formConfig.pathToCRM.link}
      />

      <JustificationContainer
        justification="start"
        margin={['base', null, null, null]}
      >
        <CopyCode
          label={
            <Label
              margin={[null]}
              fontWeight="semiBold"
              variant={Variant.primary}
              size="m"
            >
              {text.pasteDeveloperID}
            </Label>
          }
          code={bdnlRealworksDeveloperId}
          style={{ width: '100%' }}
        />
      </JustificationContainer>

      <JustificationContainer justification="center" margin={['base', null]}>
        <Screenshot
          maxWidth={SCREENSHOT_MAX_WIDTH}
          src={formConfig.screenshotLink}
          alt="screenshot"
          withoutShadow
        />
      </JustificationContainer>
      <Label size="m" variant={Variant.primary}>
        {text.copyToken}
      </Label>
      <InputWithIndicator
        appearance="primary"
        validation={[value => formConfig.validate(value as string)]}
        value={formConfig.value ?? ''}
        onChange={e => formConfig.onChange(e.target.value)}
        dataTestId="realworks-token-input"
      />
      {formConfig.footer}
    </form>
  </>
);

export default RealworksTokenBodyTemplate;
