import React from 'react';
import { v4 as uuid } from 'uuid';
import { Editor, Location, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';
import {
  DHEditor,
  VariableElement,
} from '~/components/organism/PluginsEditor/types';
import useRelativeMaps from '~/components/page/Automation/v2/components/Builder/hooks/useRelativeMaps';
import useBuilderContext from '~/components/page/Automation/v2/components/Builder/hooks/useBuilderContext';
import Selector from '~/components/page/Automation/v2/components/UpdateAction/components/Selector';
import { FlowPath } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getFieldsByPath';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import isPrimitiveArgument from '~/components/page/Automation/v2/util/isPrimitiveArgument';
import isPointerArgument from '~/components/page/Automation/v2/util/isPointerArgument';
import getPathForPointer from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getPathForPointer';
import { Flow___ArgumentFragment } from '~/graphql/types';
import useActionContext from '~/components/page/Automation/v2/components/Builder/hooks/useActionContext';
import { getPathRepresentation } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/getLabelForRep';
import { hoveringToolbarState } from '~/components/organism/PluginsEditor/state/HoveringToolbarState';
import { useSetRecoilState } from 'recoil';
import { Element } from 'slate';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Props = {
  editor: DHEditor;
  path: Location | undefined;
  element: VariableElement;
  pointerOffset: number;
  pointerLocation: 'top' | 'bottom';
};

const VariableToolbar: React.FCC<Props> = ({
  dataTestId,
  editor,
  element,
  pointerOffset,
  pointerLocation,
  ...rest
}) => {
  const errorReporter = useErrorReporter();
  const builderContext = useBuilderContext();
  const setHoveringToolbar = useSetRecoilState(hoveringToolbarState);

  const { superSubjects } = builderContext;
  const { actionId, actionType } = useActionContext();
  const maps = useRelativeMaps({ actionId });
  const currentMaps = {
    ...maps,
  };

  const handleChange = (value: Flow___ArgumentFragment) => {
    try {
      if (isPointerArgument(value)) {
        const varPath = getPathForPointer(value.pointer, maps);

        if (varPath.error === undefined) {
          const variableName = getPathRepresentation(varPath.fullResult);
          const [nodeEntry] = Editor.nodes(editor, {
            at: [],
            match: n =>
              Element.isElement(n) &&
              n.type === ELEMENTS.VARIABLE &&
              n.mappingId === element.mappingId,
          });

          if (!nodeEntry) {
            errorReporter.captureException(
              new Error(
                `Could not find variable node with mappingId: ${element.mappingId}`,
              ),
              'error',
            );
          } else {
            Transforms.setNodes(
              editor,
              {
                mappingId: uuid().replace(/-/g, ''),
                variableType: 'Flow___Argument_Pointer',
                variableName,
                variableInfo: value,
                pending: false,
              },
              { at: nodeEntry[1] },
            );

            ReactEditor.focus(editor);
            Transforms.move(editor, {
              distance: 1,
              unit: 'offset',
            });
          }
        }
      }

      setHoveringToolbar(null);
    } catch (error) {
      errorReporter.captureException(
        new Error(`Failed to update variable: ${error}`),
        'error',
      );
      setHoveringToolbar(null);
    }
  };

  const mappingValue = element.variableInfo;
  const argument = isPrimitiveArgument(mappingValue) ? mappingValue : undefined;

  let selectorPath: FlowPath = [];
  if (mappingValue && isPointerArgument(mappingValue)) {
    const pathRes = getPathForPointer(mappingValue.pointer, currentMaps);

    // When error isn't undefined, we could not get the path for the pointer
    // most likely because the pointer is missing from the instances
    // Therefore we cannot open the selector at a non existing path,
    // so go from root to correct
    if (pathRes.error === undefined) {
      selectorPath = pathRes.result;
    }
  }

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Selector
        onClose={() => {
          setHoveringToolbar(null);
          ReactEditor.focus(editor);
          Transforms.move(editor, {
            distance: 1,
            unit: 'offset',
          });
        }}
        onSelect={value => {
          if (
            value.__typename === 'Flow___InstanceCondition' ||
            value.__typename === 'Flow___SubjectFieldCondition'
          )
            return;

          handleChange(value);
        }}
        argument={argument}
        initialPath={selectorPath}
        opts={{
          ...currentMaps,
          action: actionType,
          conditionType: 'condition',
          limitToInstanceSubjects: superSubjects.TemplateString.subjectIds,
        }}
        pointerOffset={pointerOffset}
        pointerLocation={pointerLocation}
      />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.card};
    width: 100%;
  `,
);

export default VariableToolbar;
