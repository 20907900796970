import React, { useCallback, useRef } from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import { GenericHtmlElement as GenericHtmlElementType } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import { useTheme } from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { hoveringToolbarState } from '../../../state/HoveringToolbarState';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Props = RenderElementProps & {
  element: GenericHtmlElementType;
};

const selfClosingTags = [
  'area',
  'hr',
  'col',
  'embed',
  'hr',
  'input',
  'param',
  'source',
  'track',
];

const GenericHtmlElement: React.FCC<Props> = ({
  dataTestId,
  attributes,
  children,
  element,
}) => {
  const isVoidElement = selfClosingTags.includes(element.name);
  const theme = useTheme();
  const errorReporter = useErrorReporter();
  const setHoveringToolbar = useSetRecoilState(hoveringToolbarState);
  const ref = useRef<HTMLSpanElement>(null);
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  const onClick = useCallback(() => {
    if (!ref.current) {
      errorReporter.captureException(
        new Error('GenericHtmlElement ref.current is not defined'),
        'error',
      );
      return;
    }
    setHoveringToolbar({ element, elementRef: ref.current });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element]);

  if (isVoidElement) {
    return (
      <GenericVoidElement
        attributes={attributes}
        element={element}
        onClick={onClick}
      >
        <span ref={ref}>{children}</span>
      </GenericVoidElement>
    );
  }

  const prevStyle = element.attributes?.style;

  const style =
    active && element.name === 'table'
      ? {
          ...prevStyle,
          boxShadow: `0 0 0 3px ${theme.color('tertiary', 'light')}`,
        }
      : prevStyle;

  return (
    <span ref={ref}>
      {React.createElement(
        element.name,
        {
          ['data-testid']: dataTestId,
          ...attributes,
          ...element.attributes,
          style,
          onClick,
        },
        children,
      )}
    </span>
  );
};

export default {
  renderComponent: props => <GenericHtmlElement {...props} />,
  serialize,
};

const GenericVoidElement = ({ attributes, element, children, onClick }) => {
  const Element = element.name;

  return (
    <span {...attributes} onClick={onClick}>
      <Element {...element.attributes} />
      {children}
    </span>
  );
};
