import React from 'react';
import { animated, useTransition, type UseTransitionProps } from 'react-spring';
import { ANIMATION_CONFIG, type Direction } from '~/styles/constants';

export type Props = {
  dataTestId?: string;
  isVisible: boolean;
  direction?: Direction;
  distance?: number;
  config?: typeof ANIMATION_CONFIG.config;
  opts?: UseTransitionProps;
  width?: string;
};

/**
 * @description This component is used to slide a component in and out of the screen.
 * @param {boolean} isVisible - Whether the component is visible.
 * @param {Direction} direction - The direction of the slide.
 * @param {number} distance - The distance of the slide.
 * @param {typeof ANIMATION_CONFIG.config} config - The config of the animation.
 * @param {UseTransitionProps} opts - The opts of the animation.
 * @param {React.ReactNode} children - The component to slide in and out.
 */
const SlideComponent: React.FCC<Props> = ({
  isVisible,
  direction = 'left',
  distance = 40,
  config = ANIMATION_CONFIG.config,
  opts,
  children,
  width,
}) => {
  const transform = getTransform(direction, distance);

  const transitions = useTransition(isVisible, {
    from: { opacity: 0, transform },
    enter: { opacity: 1, transform: 'translate3d(0px, 0px, 0px)' },
    leave: { opacity: 0, transform },
    config,
    ...opts,
  });

  return (
    <>
      {transitions((style, item) =>
        item ? (
          <animated.div style={{ ...style, width: width ?? 'unset' }}>
            {children}
          </animated.div>
        ) : null,
      )}
    </>
  );
};

const getTransform = (direction: Direction, distance: number): string => {
  switch (direction) {
    case 'left':
      return `translate3d(-${distance}px, 0, 0)`;
    case 'right':
      return `translate3d(${distance}px, 0, 0)`;
    case 'top':
      return `translate3d(0, -${distance}px, 0)`;
    case 'bottom':
      return `translate3d(0, ${distance}px, 0)`;
  }
};

export default SlideComponent;
