import React, { useEffect, useState } from 'react';
import { Props as FormProps } from '../ActionForm';
import { ClientFlowActionTrickle } from '~/graphql/types.client';
import { Body } from '~/components/atom/Typography';
import { type FlowV2_Action_Trickle_ScheduleRule } from '~/graphql/types';
import CollapsibleBlockWithCheckbox from '~/components/organism/CollapsibleBlockWithCheckbox';
import RulesContainer from './components/RulesContainer';
import LimitsContainer from './components/LimitsContainer';
import { DEFAULT_TRICKLE_LIMITS, DEFAULT_TRICKLE_RULES } from './constants';

export type Props = FormProps & {
  dataTestId?: string;
  action: ClientFlowActionTrickle;
};

const text = {
  description:
    'Deze actie bepaalt op welke dagen en tijden de verwerking naar de volgende actie plaatsvindt. Ook kan er een limiet op het aantal contacten dat door deze actie kan gaan worden ingesteld.',
  rulesHeading: 'Alleen verwerken op',
  limitsHeading: 'Limiet toepassen',
};

const Trickle: React.FCC<Props> = ({ action, onChange }) => {
  const [rules, setRules] = useState<ClientFlowActionTrickle['rules']>(
    action.rules,
  );
  const [limits, setLimits] = useState<ClientFlowActionTrickle['limits']>(
    action.limits,
  );

  const onToggleRules = () => {
    if (rules.length === 0) {
      setRules(DEFAULT_TRICKLE_RULES);
    } else {
      setRules([]);
    }
  };

  const onToggleLimits = () => {
    if (limits.length === 0) {
      setLimits(DEFAULT_TRICKLE_LIMITS);
    } else {
      setLimits([]);
    }
  };

  useEffect(() => {
    onChange({ ...action, limits, rules });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, limits]);

  const onUpdateLimits = (
    index: number,
    updatedFields: Partial<ClientFlowActionTrickle['limits'][number]>,
  ) => {
    setLimits(
      limits.map((limit, i) =>
        i === index ? { ...limit, ...updatedFields } : limit,
      ),
    );
  };

  return (
    <>
      <Body>{text.description}</Body>
      <CollapsibleBlockWithCheckbox
        checkboxValue={rules.length > 0}
        label={text.rulesHeading}
        isOpen={rules.length > 0}
        onToggle={onToggleRules}
        onChange={onToggleRules}
      >
        {rules[0] && (
          <RulesContainer
            rule={rules[0]}
            onUpdateRule={(updatedRule: FlowV2_Action_Trickle_ScheduleRule) =>
              setRules([updatedRule])
            }
          />
        )}
      </CollapsibleBlockWithCheckbox>
      <CollapsibleBlockWithCheckbox
        checkboxValue={limits.length > 0}
        label={text.limitsHeading}
        onChange={onToggleLimits}
        isOpen={limits.length > 0}
        onToggle={onToggleLimits}
      >
        <LimitsContainer
          limits={limits}
          onChange={onUpdateLimits}
          onDelete={index => {
            setLimits(limits.filter((_, i) => i !== index));
          }}
          onInsertLimit={() => {
            setLimits([...limits, DEFAULT_TRICKLE_LIMITS[0]]);
          }}
        />
      </CollapsibleBlockWithCheckbox>
    </>
  );
};

export default Trickle;
