import React, { HTMLAttributes, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styled, { css } from 'styled-components';
import { Label, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import JustificationContainer, {
  Props as JustificationContainerProps,
} from '~/components/atom/JustificationContainer';
import { animated } from 'react-spring';
import getBackgroundColor from './utils/getBackgroundColor';
import useMeasure from '~/hooks/useMeasure';
import Circle from './components/Circle';
import { sizes, type Size } from './constants';

export type Props = HTMLAttributes<HTMLInputElement> & {
  label?: React.ReactNode;
  value: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: Size;
  containerProps?: JustificationContainerProps;
};

const ToggleCheckbox: React.FCC<Props> = ({
  dataTestId,
  label,
  value,
  size = 'base',
  disabled,
  loading,
  containerProps = { direction: 'column', gap: 'xxxs' },
  ...rest
}) => {
  const id = useRef(uuid());
  const isDisabled = disabled || loading;

  const { ref: containerRef, bounds } = useMeasure<HTMLLabelElement>();
  const containerWidth = bounds.width;

  const currentState = loading
    ? 'loading'
    : value === true
      ? 'checked'
      : 'unchecked';

  return (
    <Container
      data-testid={TEST_ID.CONTAINER}
      {...containerProps}
      $size={size}
      $disabled={isDisabled}
    >
      {label && (
        <Label size="base" margin={[null]} variant={Variant.primary}>
          {label}
        </Label>
      )}
      <Input
        {...rest}
        data-testid={TEST_ID.INPUT}
        type="checkbox"
        role="checkbox"
        id={id.current}
        disabled={isDisabled}
        checked={value}
        name="checkbox"
      />
      <FakeLabel
        htmlFor={id.current}
        $size={size}
        $disabled={isDisabled}
        data-testid={dataTestId || TEST_ID.LABEL}
        data-checked={value}
        ref={containerRef}
      >
        {containerWidth > 0 && (
          <Circle
            size={size}
            loading={loading}
            currentState={currentState}
            containerWidth={containerWidth}
          />
        )}
        Toggle
      </FakeLabel>
    </Container>
  );
};

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const FakeLabel = styled(animated.label)<{
  $size: string;
  $disabled?: boolean;
}>(
  ({ theme, $size, $disabled }) => css`
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    text-indent: -9999px;
    width: 100%;
    max-width: ${theme.space(sizes[$size].container.maxWidth)};
    height: ${theme.space(sizes[$size].container.height)};
    background: ${$disabled
      ? getBackgroundColor(theme, 'disabled')
      : getBackgroundColor(theme, 'unchecked')};
    display: block;
    border-radius: ${theme.space(sizes[$size].container.borderRadius)};
    position: relative;
    transition: 0.3s;
  `,
);

const Container = styled(JustificationContainer)<{
  $size: string;
  $disabled?: boolean;
}>(
  ({ theme, $size, $disabled }) => css`
    min-width: ${theme.space(sizes[$size].container.maxWidth)};

    ${Input}:checked + ${FakeLabel} {
      background: ${$disabled
        ? getBackgroundColor(theme, 'disabled')
        : getBackgroundColor(theme, 'checked')};
    }
  `,
);

export default ToggleCheckbox;
