import React from 'react';
import RealworksTokenBodyTemplate from '../../../RealworksTokenBodyTemplate'; // type SkipStepConfig,
import { agendaHeader } from '~/components/organism/WizardSteps/Apps/Realworks/RealworksAgenda';
import {
  realworksCRMLink,
  type TokenStepBodyProps,
} from '~/components/page/Apps/Realworks/constants';
import StepNavigationFooter from '../../../StepNavigationFooter';
import { isNonEmptyString } from '~/util/Validation/String';
import RelatieTokenWarning from '../RelatieTokenWarning';
import validateRealworksToken from '~/components/page/Apps/Realworks/utils/validateRealworksToken';

export const agendaScreenshotLink =
  'https://dathuis-stash.imgix.net/Realworks_Agenda.png';

const text = {
  description: 'Met deze koppeling haal je agenda-items op uit Realworks.',
};

const realworksAgendaAPIPath = [
  'Servicedesk',
  'Marketplace',
  "API's",
  'Agenda API',
  'Bestellen',
];

const ImportAgendaStepBody: React.FC<TokenStepBodyProps> = ({
  value,
  onChange,
  skipStepBlock,
  onSubmit,
  tokens,
  navigationFooter,
}) => (
  <RealworksTokenBodyTemplate
    helpBlock={{ tipId: 'realworks-agenda-tip' }}
    description={
      <>
        <>{text.description}</>
        <RelatieTokenWarning />
      </>
    }
    formConfig={{
      header: agendaHeader,
      pathToCRM: {
        path: realworksAgendaAPIPath,
        link: realworksCRMLink,
      },
      screenshotLink: agendaScreenshotLink,
      value,
      validate: value =>
        validateRealworksToken({
          mainToken: value,
          tokensToCompare: tokens,
        }),
      onChange,
      onSubmit,
      footer: navigationFooter ? (
        <StepNavigationFooter
          {...navigationFooter}
          disabled={
            !isNonEmptyString(value) ||
            validateRealworksToken({
              mainToken: value,
              tokensToCompare: tokens,
            }) !== true
          }
          onSkip={skipStepBlock?.onSkip}
        />
      ) : null,
    }}
  />
);

export default ImportAgendaStepBody;
