import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

const use100vh = (): string => {
  const [height, setHeight] = useState<number | null>(measureHeight());

  useEffect(() => {
    // Throttle to limit the number of times the resize event is handled
    const setMeasuredHeight = throttle(() => {
      setHeight(measureHeight());
    }, 200);

    // Initial measurement
    setMeasuredHeight();

    // Listen to both resize and scroll events for iOS Safari
    window.addEventListener('resize', setMeasuredHeight);
    window.addEventListener('scroll', setMeasuredHeight);

    return () => {
      window.removeEventListener('resize', setMeasuredHeight);
      window.removeEventListener('scroll', setMeasuredHeight);
    };
  }, []);

  return height ? `${height}px` : '100vh';
};

const measureHeight = (): number | null => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return null;

  // Use visualViewport API when available (better mobile support)
  if ('visualViewport' in window) {
    return window.visualViewport?.height ?? null;
  }

  return (
    (window as Window).innerHeight || document.documentElement.clientHeight
  );
};

export default use100vh;
